/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Address, AddressOrphan, ErrorAPI, ErrorValidation, ObjectFieldValidation, uuid } from "@bookie/glossary";
import { useCoreAPI } from "../use-core-api";
import { useIdentity } from "@bookie/module-identity";
import { useValidator } from "@bookie/utils";
import { validateAddress } from "../fns/validate-address";
import { useNotification } from "@bookie/components";

export const useAddressEditor = (
  addressId?: uuid | "create",
  address?: Address,
  addressEditor?: IAddressEditorAPI
): IAddressEditorAPI => {

  if (addressEditor) {
    return addressEditor;
  }

  const n = useNotification();
  const api = useCoreAPI();
  const { ownership } = useIdentity();

  // Address
  // these properties are required
  // to create a new Address

  const [ line1, setLine1 ] = React.useState<string>("");
  const [ line2, setLine2 ] = React.useState<string>("");
  const [ city, setCity ] = React.useState<string>("");
  const [ town, setTown ] = React.useState<string>("");
  const [ postalCode, setPostalCode ] = React.useState<string>("");
  const [ country, setCountry ] = React.useState<string>("");

  const [ label, setLabel ] = React.useState<string>(""); 
  const [ id, setId ] = React.useState<uuid>();
  const [ ownedBy, setOwnedBy ] = React.useState<uuid>();

  const [ isFetchingAddress, setIsFetchingAddress ] = React.useState<boolean>(false);
  const [ isCreatingAddress, setIsCreatingAddress ] = React.useState<boolean>(false);
  const [ isDeletingAddress, setIsDeletingAddress ] = React.useState<boolean>(false);
  const [ isUpdatingAddress, setIsUpdatingAddress ] = React.useState<boolean>(false);

  const [ isReady, setIsReady ] = React.useState<boolean>(false);

  React.useEffect(() => {

    if (addressId === "create") {
      setCountry(ownership.current?.entity?.countryCode || "");
      setIsReady(true);
    }

    else if (typeof addressId === "string") {
      (async () => {

        let _address = address;

        if (!_address) {
          setIsFetchingAddress(true);
          const { address } = await api.getAddress(addressId);
          _address = address;
          setIsFetchingAddress(false);
        } 

        setLine1(_address?.line1);
        setLine2(_address?.line2 || "");
        setCity(_address?.city);
        setTown(_address?.town || "");
        setPostalCode(_address?.postalCode);
        setCountry(_address?.country);
        setLabel(_address?.label || "");
        setId(_address?.id);
        setOwnedBy(_address?.ownedBy);

        setIsReady(true);

      })();
    }

  }, [address, addressId, api, ownership, addressEditor]);


  const _address = {
    line1,
    line2,
    city,
    town,
    postalCode,
    country,
    label,
    id,
    ownedBy
  }

  const {
    validate,
    isVirgin,
    setDirty,
    validationErrors,
    setValidationErrors
  } = useValidator(
    (_a) => {
      const _validation = validateAddress(_a as Address);
      setValidationErrors(_validation.errors);
      return _validation.isValid;
    },
    _address,
    undefined,
    isReady
  );

  const reset = () => {
    setLine1("");
    setLine2("");
    setCity("");
    setPostalCode("");
    setLabel("");
    setTown("");
  };

  return {

    data: _address,

    reset,

    edit: {
      line1: setLine1,
      line2: setLine2,
      city: setCity,
      town: setTown,
      postalCode: setPostalCode,
      country: setCountry,
      label: setLabel
    },

    state: {
      isWorking: (
        isCreatingAddress ||
        isUpdatingAddress ||
        isDeletingAddress ||
        isFetchingAddress
      ),
      isFetchingAddress,
      isCreatingAddress,
      isUpdatingAddress,
      isDeletingAddress,
      isVirgin
    },

    validate,

    delete: async () => {
      if (addressId) {
        try {
          setIsDeletingAddress(true);
          const { address }  = await api.deleteAddress(addressId);
          n.notify({ type: "success", message: `Deleted ${ address.label || 'your address' }` });
          setIsDeletingAddress(false);
          return true;
        } catch (e) {
          setIsDeletingAddress(false);
          if (e instanceof ErrorAPI) {
            n.notify({ type: "error", message: e.error });
          }
        }
        
      }
      return false;
    },

    commit: async () => {

      setDirty();

      const canCommit = validate();
      if (!canCommit) throw new ErrorValidation({ name: "address" });

      try {

        if (addressId === "create" && !isVirgin) {
          setIsCreatingAddress(true);
          const { address } = await api.createAddress(_address as AddressOrphan);
          n.notify({ type: "success", message: `Created ${ address.label || 'your address' }` });
          setIsCreatingAddress(false);
          return address;
        }
  
        else if (addressId && !isVirgin) {
          setIsUpdatingAddress(true);
          const { address } = await api.updateAddress(_address as Address);
          n.notify({ type: "success", message: `Updated ${ address.label || 'your address' }` })
          setIsUpdatingAddress(false);
          return address;
        }

      } catch (e) {
        setIsCreatingAddress(false);
        if (e instanceof ErrorAPI) {
          n.notify({ type: "error", message: e.error });
        }
      }

      return false;
    },

    errors: {
      setDirty,
      validation: validationErrors,
      setValidation: setValidationErrors
    }
  }
}


export interface IAddressEditorAPI {
  data: AddressOrphan,
  reset: () => void,
  edit: {
    line1: (v: string) => void,
    line2: (v: string) => void,
    city: (v: string) => void,
    town: (v: string) => void,
    postalCode: (v: string) => void,
    country: (v: string) => void,
    label: (v: string) => void
  },
  state: IAddressEditorState,
  validate: () => boolean,
  delete: () => Promise<boolean>,
  commit: () => Promise<Address | false>,
  errors: {
    setDirty: () => void,
    validation?: ObjectFieldValidation,
    setValidation: (v: ObjectFieldValidation) => void 
  }
}

export interface IAddressEditorState {  
  isVirgin?: boolean,
  isWorking: boolean,
  isFetchingAddress: boolean,
  isCreatingAddress: boolean,
  isUpdatingAddress: boolean,
  isDeletingAddress: boolean
}