import { TransactionDocumentType } from "@bookie/glossary";

export const getDocumentAddressLabel = (
  type: TransactionDocumentType
): string => {
  switch (type) {

    case ("estimate"): {
      return "For";
    }

    case ("invoice"): {
      return "Billed To"
    }

    case ("receipt"): {
      return "Paid By"
    }

  }
}