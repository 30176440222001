import React from "react";
import { EntityEditor } from "../entity-editor";
import { useCore } from "../../use-core";
import { EntityType, uuid } from "@bookie/glossary";
import { Button, Empty, IconAdd, List, Panel } from "@bookie/components";
import { getCountry } from "../../fns/get-country";

export const EntitiesEditor: React.FC<IEntitiesEditor> = (props) => {

  const { entities } = useCore();

  const [ selectedEntityId, setSelectedEntityId ] = React.useState<uuid | "create">();
  const [ entityType, setEntityType ] = React.useState<EntityType>();

  React.useEffect(() => {
    if (!selectedEntityId && entities.all?.[0]) {
      setSelectedEntityId(entities.all[0].id);
    } 
  }, [ entities.all, selectedEntityId ]);

  const isEmpty = (
    (!entities.all || entities.all.length === 0) && 
    typeof selectedEntityId === "undefined"
  );

  return (
    <div
      className={ "x fill-x surface-aside" }>
      
      <div 
        style={{ minWidth:"350px" }}
        className={ "y surface-aside border-r" }>
        
        <Panel 
          label={ "Clients" }
          defaultOpen={ true }
          isEmpty={ !entities.clients || entities.clients.length === 0 }
          emptyMessage={ "Your Clients will appear here." }
          actions={
            () => (
              <>
                {
                  (selectedEntityId === "create" && entityType === "client") &&
                    <Button
                      size={ "slim" }
                      variant={ "inline" }
                      label={ "Cancel" }
                      onClick={ () => {
                        setSelectedEntityId(undefined)
                        setEntityType(undefined)
                      } } />
                }
                
                {
                  (
                    (selectedEntityId !== "create" && typeof selectedEntityId === "string") ||
                    (!selectedEntityId)
                  ) && 
                    <Button
                      size={ "slim" }
                      variant={ "inline" }
                      icon={ <IconAdd /> }
                      onClick={ () => {
                        setSelectedEntityId("create")
                        setEntityType("client")
                      } } />
                }
              </>
            )
          }>
          <div 
            className={ "space-lines-frame fill-x" }>
            <List 
              data={ entities.clients }
              renderLabel={ m => (
                <div 
                  className={ "x space-inlinez-children align-y-center" }>
                  <span>{ getCountry(m.countryCode)?.emoji }</span>
                  <span>{ m.name }</span>
                </div>
              ) }
              isSelected={ m => m.id === selectedEntityId }
              onClick={ m => setSelectedEntityId(m.id) } />
            </div>
        </Panel>
        
        <Panel
          label={ "Merchants" }
          defaultOpen={ true }
          isEmpty={ !entities.merchants || entities.merchants.length === 0 }
          emptyMessage={ "Your Merchants will appear here." }
          actions={
            () => (
              <>
                  {
                    (selectedEntityId === "create" && entityType === "merchant") &&
                      <Button
                        size={ "slim" }
                        variant={ "inline" }
                        label={ "Cancel" }
                        onClick={ () => {
                          setSelectedEntityId(undefined)
                          setEntityType(undefined)
                        } } />
                  }
                  
                  {
                    (
                      (selectedEntityId !== "create" && typeof selectedEntityId === "string") ||
                      (!selectedEntityId)
                    ) && 
                      <Button
                        size={ "slim" }
                        variant={ "inline" }
                        icon={ <IconAdd /> }
                        onClick={ () => {
                          setSelectedEntityId("create")
                          setEntityType("merchant")
                        } } />
                  }
              </>
            )
          }>
          <div 
            className={ "space-lines-frame fill-x" }>
            <List 
              data={ entities.merchants }
              renderLabel={ m => (
                <div 
                  className={ "x space-inlinez-children align-y-center" }>
                  <span>{ getCountry(m.countryCode)?.emoji }</span>
                  <span>{ m.name }</span>
                </div>
              ) }
              isSelected={ m => m.id === selectedEntityId }
              onClick={ m => setSelectedEntityId(m.id) } />
          </div>

        </Panel>

        <Panel
          label={ "Uncategorised" }
          defaultOpen={ false }
          isEmpty={ !entities.uncategorised || entities.uncategorised.length === 0 }
          emptyMessage={ "Any uncategorised ones will appear here." }>
          <div 
            className={ "space-lines-frame fill-x" }>
            <List 
              data={ entities.uncategorised }
              renderLabel={ m => (
                <div 
                  className={ "x space-inlinez-children align-y-center" }>
                  <span>{ getCountry(m.countryCode)?.emoji }</span>
                  <span>{ m.name }</span>
                </div>
              ) }
              isSelected={ m => m.id === selectedEntityId }
              onClick={ m => setSelectedEntityId(m.id) } />
          </div>
        </Panel>

      </div>

       {
         selectedEntityId && selectedEntityId !== "create" &&
           <EntityEditor
             entityId={ selectedEntityId }
             onFinish={ () => { 
               setSelectedEntityId(undefined) 
               setEntityType(undefined)
             } }  />
      }

      {
         selectedEntityId && selectedEntityId === "create" &&
           <EntityEditor
             entityId={ selectedEntityId }
             entityType={ entityType }
             onFinish={ () => {
               setSelectedEntityId(undefined) 
               setEntityType(undefined)
             } } />
       }

      {
        isEmpty && 
          <div
            className={ "y space-lines-frame" }>
            <Empty
              label={ "This is where you can find your Clients and Merchants" }
              description={[
                "Here, you can manage everyone you do business with — whether they’re clients buying from you or merchants you’re purchasing from.", 
                "Clients are the ones buying your products or services, while merchants are the ones you buy from.",
                "Easily add or update details for both, so when it’s time to create an estimate, invoice, or expense, you’ve got all the info ready to go in one place.",
              ]}
              callToActions={
                <div 
                  className={ "x space-lines-children space-lines-frame-t" }>
                  
                  <Button
                    variant={ "inline" }
                    label={ "Create your first Client" }
                    icon={ <IconAdd /> }
                    onClick={ () => {
                      setSelectedEntityId("create")
                      setEntityType("client")
                    } } />
                  
                  <Button
                    variant={ "inline" }
                    label={ "Create your first Merchant" }
                    icon={ <IconAdd /> }
                    onClick={ () => {
                      setSelectedEntityId("create")
                      setEntityType("merchant")
                    } } />
                
                </div>
              } />
          </div>
      }

    </div>
  );
}

export interface IEntitiesEditor {

}