import { Button, IconAdd, IconAddData, IconDescription, IconOfferings, InputBase, InputSelect, InputText, Panel } from "@bookie/components";
import React from "react";
import { useCore } from "@bookie/module-core";
import { useCurrency } from "@hny_/currency";
import { TransactionOfferingEditor } from "../transaction-offering-editor";
import { TransactionType } from "@bookie/glossary";
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";
import cx from "classnames";

export const TransactionOfferingsEditor: React.FC<ITransactionOfferingsEditor> = (props) => {

  const { offerings } = useCore();
  const curr = useCurrency();

  const [selectedOffering, setSelectedOffering] = React.useState<number>(-1);

  return (
    <Panel 
      label={ "Products & Services" }
      icon={ <IconOfferings /> }
      isGhost={ props.renderBare }
      defaultOpen={ true }
      summary={
        (props.editor?.related.transactionOfferingsEditor?.total || 0) > 0 
          ? `${ props.editor?.related.transactionOfferingsEditor?.total } product and/or service added`
          : ""
      }>

      <div className={ "y fill-x" }>

        <div 
          className={ cx(
            "x border-b fill-inputs",
            { "space-lines-frame": !props.renderBare },
            { "space-lines-frame-tb": props.renderBare }
          ) }>
          <InputText
            label={ "Description" }
            type={ "text" }
            icon={ <IconDescription /> }
            placeholder={
              props.editor?.type === "income"
                ? "What are you selling?"
                : "What are you buying?"
            }
            value={ props.editor?.data.description }
            onChange={ v => props.editor?.edit.description(v as string) }
            error={ props.editor?.errors.validation?.description } />
        </div>
        
        <div 
          className={ cx(
            "y align-x-between space-lines-children",
            { "space-lines-frame": !props.renderBare },
            { "space-lines-frame-tb": props.renderBare }
          ) }>
          
            <div
              className={ "x align-x-between" }>
            <InputBase
              icon={ <IconOfferings /> }
              label={ "Products & Services" }
              description={ `List out all of the products and/or services that you are selling as part of this ${ props.transactionStage || "invoice" }` } />

            <div className={ "x align-y-center" }>

              {
                props.transactionType !== "expense" && 
                  <Button
                    variant={ "text" }
                    icon={ <IconAddData /> }
                    size={ "slim" }
                    className={ "hvr-pulse" }
                    optionsIconStyle={ "chevron" }
                    options={
                      <InputSelect
                        formatLabel={ opt => (
                          <div
                            style={{ fontWeight: "normal" }}
                            className={ "x align-x-between fill-x" }>
                            <p>{ opt.name }</p>
                            <p>{ curr.toHumanReadable(opt.grossSale, undefined, opt.currencyCode) }</p>
                          </div>
                        ) }
                          
                        matchKey={ "id" }
                        onSelect={ o => props.editor?.related.transactionOfferingsEditor?.new.add(o) }
                        options={ offerings.all }
                        noOptionsCopy={ "You haven't created any products or services in your inventory yet. To create one, start adding a new one here and select 'Save to Inventory'." }
                        forceOpen={ true }
                        searchKey={ "name" }
                        optionsAlign={ "left" }
                        onlyOptions={ true } />
                    } />
              }

              <Button
                variant={ "text" }
                className={ "hvr-pulse" }
                onClick={ (e) => {
                  e?.stopPropagation();
                  props.editor?.related.transactionOfferingsEditor?.new.add();
                } } 
                icon={ <IconAdd /> } />

            </div>
          </div>
          
          <div>
            {
              props.editor?.related.transactionOfferingsEditor?.existing.data.map((offering, i) => (
                <TransactionOfferingEditor
                  key={ offering.id }
                  offering={ offering }
                  surfaceDisplay={ props.surfaceDisplay }
                  transactionType={ props.transactionType }
                  currencyCode={ props.currencyCode }
                  isSelected={ selectedOffering === i }
                  onSelect={ () => setSelectedOffering(i) }
                  onEdit={ (f, v) => props.editor?.related.transactionOfferingsEditor?.existing.edit(i, f, v) }
                  onDelete={ () => props.editor?.related.transactionOfferingsEditor?.existing.remove(offering.id) }
                  errors={ props.editor?.related.transactionOfferingsEditor?.existing.errors.validation?.[i] }
                  onMinimise={ () => setSelectedOffering(-1) } />
              ))
            }

            {
              props.editor?.related.transactionOfferingsEditor?.new.data.map((offering, i) => (
                <TransactionOfferingEditor
                  key={ offering.id || `new-${i}` } // Ensure unique keys
                  offering={ offering }
                  surfaceDisplay={ props.surfaceDisplay }
                  inventory={ typeof props.editor?.related.transactionOfferingsEditor?.inventory.inventory[i] !== "undefined" ? true : false }
                  addToInventory={ () => props.editor?.related.transactionOfferingsEditor?.inventory.addToInventory(i) }
                  removeFromInventory={ () => props.editor?.related.transactionOfferingsEditor?.inventory.removeFromInventory(i) }
                  transactionType={ props.transactionType }
                  currencyCode={ props.currencyCode }
                  isSelected={ selectedOffering === (i + (props.editor?.related.transactionOfferingsEditor?.existing.data.length || 0)) }
                  onSelect={ () => setSelectedOffering(i + (props.editor?.related.transactionOfferingsEditor?.existing.data.length || 0)) }
                  onEdit={ (f, v) => props.editor?.related.transactionOfferingsEditor?.new.edit(i, f, v) }
                  onDelete={ () => props.editor?.related.transactionOfferingsEditor?.new.remove(i) }
                  errors={ props.editor?.related.transactionOfferingsEditor?.new.errors.validation?.[i] }
                  onMinimise={ () => {
                    setSelectedOffering(-1);
                  } } />
              ))
            }
          </div>

        </div>
        
      </div>

    </Panel>
  );
}

export interface ITransactionOfferingsEditor {
  currencyCode?: string
  editor?: ITransactionEditorAPI
  transactionType?: TransactionType
  transactionStage?: "estimate" | "invoice"
  renderBare?: boolean
  isUsingAssist?: boolean
  surfaceDisplay?: string
}
