import { SupabaseClient } from "@supabase/supabase-js";
import { ErrorAPI, Transaction, TransactionOrphan } from "@bookie/glossary";
import { objectToCamel, objectToSnake, stripNullAndUndefinedFromObject } from "@bookie/utils";

export const _createTransactions = (sb: SupabaseClient): CreateTransactions => 
  async (txs) => {

    const _sanitised = objectToSnake(txs.map(tx => stripNullAndUndefinedFromObject(tx)));

    const { data, error } = (
      await sb
        .from('transaction')
        .insert(_sanitised)
        .select()
    );

    const _transactions = data;

    if (error?.code) {
      throw new ErrorAPI({});
    }

    if (_transactions === null) {
      throw new ErrorAPI({});
    }

    return {
      transactions: objectToCamel<Transaction[]>(_transactions)
    };

  }

export type CreateTransactions = (
  txs: TransactionOrphan[]
) => Promise<CreateTransactionsResponse>

export interface CreateTransactionsResponse {
  transactions: Transaction[]
}