import React from "react";
import { useSupabase } from "@bookie/supabase";
import { Button, InputText, useNotification } from "@bookie/components";
import { AppAppleMail, AppGmail, AppOutlook, AppSupabase, LogoFaceWhiteRight, LogoFaceWhiteShocked, LogoFaceWhiteSmile } from "@bookie/images";
import css from "./index.module.css";
import cx from "classnames";
import { ErrorAPI } from "@bookie/glossary";

export const Login: React.FC = () => {

  const n = useNotification();
  const { supabase } = useSupabase();

  const [isDone, setIsDone] = React.useState<boolean>(false);
  const [isWorking, setIsWorking] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");

  const [ validation, setValidation ] = React.useState<string>();

  return (
    <div 
      className={cx(
        css.login, 
        "y fill surface-primary apply-bg apply-fg"
      )}>

      <div
        className={cx(
          css.login_form,
          "y fill space-cubes-children align-y-center align-x-center"
        )}
      >

        <form
          style={{ width: "500px" }}
          className={"y space-inline-children"}
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              
              if (!email) {
                setValidation("Make sure to tell me your email address");
                return;
              }

              setIsWorking(true);
              const { error } = await supabase.auth.signInWithOtp({ email });

              if (error) {
                if (error.status === 400) {
                  throw new ErrorAPI({ name: error.name, error: "I don't think that's a valid email address." });
                }
                throw new ErrorAPI({ name: error.name  });
              }
              
              setIsDone(true);
            
            } catch (e) {
              if (e instanceof ErrorAPI) {
                n.notify({ type: "error", message: e.error });
              }
            } finally {
              setIsWorking(false);
            }
          }}
        >
          
          <div 
            style={{ position: "relative", height: "100px" }}
            className={ "x space-cubes-children fill-x" }>
            
            {
              !isDone && !validation && 
                <img 
                  className={ css.face }
                  src={ LogoFaceWhiteRight }
                  alt={ "Bookie" } />
            }

            {
              !isDone && validation && 
                <img 
                  className={ css.face }
                  src={ LogoFaceWhiteShocked }
                  alt={ "Bookie" } />
            }

            {
              isDone && !validation && 
                <img 
                  className={ css.face }
                  src={ LogoFaceWhiteSmile }
                  alt={ "Bookie" } />
            }

            <div
              style={{ position: "relative", marginLeft: "30px" }}
              className={ "y space-inline-children fill-x surface-primary" }>
              
              {
                !isDone && 
                  <>
                    <InputText
                      value={email}
                      type={"text"}
                      placeholder={"Your email address"}
                      onChange={v => {
                        validation && setValidation(undefined);
                        setEmail(v as string);
                      }}
                      backgroundType={"fill"}
                      borderType={ "outline" }
                      error={ validation }
                    />
                    <Button
                      type={"submit"}
                      size={ "slim" }
                      className={ cx(
                        css.login_button
                      ) }
                      label={"Sign In"}
                      isWorking={isWorking}
                    />
                    {
                      !validation && 
                        <p
                          className={ css.login_description }>
                          {
                            "Enter your email, and I’ll send you a magic link to log in with. If you don't yet have an account I'll create one for you."
                          }
                        </p>
                    }
                  </>
              }

              {
                isDone && (
                  <>
                    {/* <p
                      className={ css.login_description }>
                      { "Check your emails, I sent you the magic link."}
                    </p> */}
                    <h2>

                    { "Check your emails, I sent you the magic link."}
                    </h2>
                    <p
                      className={ css.login_description }>
                      { "I also prepared these quick links for you, in case you use any of these email apps." }
                    </p>

                    <div 
                      className={ cx(
                        css.login_appLinks,
                        "x"
                      ) }>
                        
                        {
                          window.location.hostname === "localhost" && 
                            <img
                              src={ AppSupabase }
                              onClick={ () => window.location.href = "http://127.0.0.1:54324/monitor" }
                              alt={ "Supabase" } />
                        }

                        {
                          window.location.hostname !== "localhost" && 
                            <>
                              <img
                                src={ AppGmail }
                                onClick={ () => window.location.href = "https://mail.google.com" }
                                alt={ "Gmail" } />
                              <img
                                src={ AppAppleMail }
                                onClick={ () => window.location.href = "https://www.icloud.com/mail" }
                                alt={ "Apple Mail" } />
                              <img
                                src={ AppOutlook }
                                onClick={ () => window.location.href = "https://login.live.com/" }
                                alt={ "Outlook" } />
                            </>
                        }

                    </div>
                  </>
                )
              }
              
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
