import { SupabaseClient } from "@supabase/supabase-js";
import { ErrorAPI, Offering } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";

export const _getOfferings = (sb: SupabaseClient): GetOfferings => 
  async () => {

    const { data, error } = (
      await sb
        .from("offering")
        .select(`*`)
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      offerings: objectToCamel<Offering[]>(data)
    };

  };

export type GetOfferings = () => Promise<GetOfferingsResponse>

export interface GetOfferingsResponse {
  offerings: Offering[];
}