import { TransactionExpanded } from "@bookie/glossary";

export const sortTransactions = (
  transactions: TransactionExpanded[]
): TransactionExpanded[] => {
  return (
    transactions.sort((a, b) => (
      a.invoiceDate && b.invoiceDate
        ? new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime()
        : 1
    ))
  );
}