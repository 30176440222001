import { ErrorAPI, Offering, OfferingOrphan } from "@bookie/glossary"
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel, objectToSnake, stripNullAndUndefinedFromObject } from "@bookie/utils";

export const _createOffering = (sb: SupabaseClient): CreateOffering => 
  async (offering) => {

    const { data, error } = (
      await sb
        .from("offering")
        .insert([
          objectToSnake(stripNullAndUndefinedFromObject(offering))
        ])
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      offering: objectToCamel<Offering>(data[0])
    };

  }

export type CreateOffering = (
  offering: OfferingOrphan
) => Promise<CreateOfferingResponse>

export interface CreateOfferingResponse {
  offering: Offering
}