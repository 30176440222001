import { Entity, TransactionExpanded, TransactionExpandedSegment, TransactionFilterPeriods, TransactionType } from "@bookie/glossary";
import { format, parseISO } from "date-fns";
import { summariseTransactions } from "./summarise-transactions";
import { inferSegments } from "./segment-gross";
import { auditTransactions } from "./audit-transactions";

export const segmentByMonth = (
  txs: TransactionExpanded[],
  type: TransactionType,
  orgEntity?: Entity,
  period?: TransactionFilterPeriods
): TransactionExpandedSegment[] => {

  const segments: { [key: string]: TransactionExpanded[] } = inferSegments(period || "this_year");

  // We will go through each transaction
  // so that we can identify the segments
  // required.

  // Segments will be tracked against 
  // an internal ID. 

  txs.forEach((transaction) => {

    // We can use either the invoice or estimate 
    // dates. Both will work for us here.     

    const date = (
      transaction.invoiceState
        ? transaction.invoiceDate
        : transaction.estimateDate
    );

    let label = "Undated";

    // If we have a date specified
    // we need to format it for our label. 
    // If we don't have a date, we'll
    // just the default "Undated"

    if (date) {
      const dateObj = parseISO(date);
      label = format(dateObj, "MMMM");
    }

    // If it's a new segment 
    // we can create a new instance for it. 

    if (!segments[label]) {
      segments[label] = [];
    }

    // Update with out transaction.

    segments[label].push(transaction);
  
  });

  // Once we have all the segments 
  // and their associated transactions,
  // we can summarise and finalise
  // all the segments.

  return Object.entries(segments).map(([label, transactions]) => {
    
    const summary = summariseTransactions(transactions, orgEntity, period);
    const audit = auditTransactions(transactions, type, orgEntity?.currencyCode || "");

    return ({
      label,
      transactions,
      summary,
      issues: audit,
      type: "transaction"
    });

  });

}