import { useBookkeeping, useGroupSegments } from "@bookie/module-bookkeeping"
import { IUseTransactionCurrencyProfitAndLoss, useTransactionCurrencyProfitAndLoss } from "./use-transaction-currency-profit-and-loss";
import { TransactionSegmentGroup } from "@bookie/glossary";
import { useCreditBadDebt } from "./use-credit-bad-debt";

export const useProfitAndLoss = (

): IUseProfitAndLoss => {

  const { transactions } = useBookkeeping();

  const transactionsFlat = transactions.segmented?.segments.flatMap(x => x.transactions) || [];

  // TODO 
  // Explain.
  // --------

  // TODO 
  // Rename to just CX Diff or something
  // if we are going to combine profit and loss.

  const cx = useTransactionCurrencyProfitAndLoss(transactionsFlat);
  const { creditedTransactions } = useCreditBadDebt(transactionsFlat);

  // Income
  // ------

  const { group: incomeGroup } = useGroupSegments([
    ...(transactions.income?.segments || []),
    cx.profit
  ]);

  // Expenses 
  // --------

  const { group: expensesGroup } = useGroupSegments([
    ...(transactions.expenses?.segments || []),
    cx.loss,
    creditedTransactions
  ]);

  // All
  // ---

  const { group } = useGroupSegments([
    ...(transactions.segmented?.segments || []),
    cx.profit,
    cx.loss,
    creditedTransactions
  ]);

  return {
    all: group,
    cx,
    income: incomeGroup,
    expenses: expensesGroup
  };

}

export interface IUseProfitAndLoss {
  all: TransactionSegmentGroup
  cx: IUseTransactionCurrencyProfitAndLoss
  income: TransactionSegmentGroup
  expenses: TransactionSegmentGroup
}