import { COUNTRIES, Country, CURRENCIES, Currency, uuid } from "@bookie/glossary";
import { InputText, InputSelect } from "@bookie/components";
import React from "react";
import cx from "classnames";
import { IEntityEditorAPI, useEntityEditor } from "../../use/use-entity-editor";
import { getCountry } from "../../fns/get-country";
import { getCurrency } from "../../fns/get-currency";

export const EntityEditorQuick: React.FC<IEntityEditorQuick> = (props) => {

  const editor = useEntityEditor(
    props.entityId,
    props.editor
  );

  return (
    <div
      className={ cx(
        "y fill-x space-inlinez-frame"
      ) }>

        <InputText
          type={ "text" }
          size={ "small" }
          labelPosition={ "left" }
          className={ "fill-inputs" }
          align={ "right" }
          label={ "Name" }
          placeholder={ "Company or an Individual's name" }
          value={ editor.data.name }
          onChange={ v => editor.edit.name(v as string) }
          error={ editor.errors.validation?.["name"] } />
        
        <InputSelect
          type={ "text" }
          size={ "small" }
          labelPosition={ "left" }
          align={ "right" }
          optionsAlign={ "left" }
          label={ "Country" }
          name={ "entity-country" }
          mode={ "edit" }
          value={ getCountry(editor.data.countryCode) }
          options={ COUNTRIES }
          matchKey={ "alpha3" }
          searchKey={ "name" }
          formatLabel={ (c: Country) => {
            return (
              <div
                className={ "x space-inline-children align-y-baseline" }>
                <div>{ c.emoji }</div>
                <div>{ c.name }</div>
              </div>
            ); 
          } }
          onSelect={ (c: Country) => editor.edit.countryCode(c.alpha3) }
          error={ editor.errors.validation?.["countryCode"] } />
        
        <InputSelect
          type={ "text" }
          size={ "small" }
          label={ "Currency" }
          labelPosition={ "left" }
          optionsAlign={ "left" }
          align={ "right" }
          name={ "entity-currency" }
          mode={ "edit" }
          value={ getCurrency(editor.data.currencyCode) }
          options={ CURRENCIES }
          matchKey={ "code" }
          searchKey={ "name" }
          formatLabel={ (c: Currency) => {
            return (
              <div
                className={ "x space-inline-children align-y-baseline" }>
                <div>{ c.emoji }</div>
                <div>{ c.name }</div>
              </div>
            ); 
          } }
          onSelect={ (c: Currency) => editor.edit.currencyCode(c.code) }
          error={ editor.errors.validation?.["currencyCode"] } />
        
        {/* <InputText
          type={ "text" }
          labelPosition={ "left" }
          align={ "right" }
          label={ "TIN" }
          placeholder={ "Tax Identification number" }
          value={ editor.data.tin }
          onChange={ v => editor.edit.tin(v as string) }
          className={ "fill-x" } /> */}

    </div>
  );
}

export interface IEntityEditorQuick {
  entityId?: uuid | "create"
  editor?: IEntityEditorAPI
}