import { IconPayment, PillStatus } from "@bookie/components";
import { PaymentState } from "@bookie/glossary";
import React from "react";

const LABELS = {
  "due": "Due",
  "overdue": "Overdue",
  "paid_in_full": "Paid",
  "paid_in_part": "Paid in Part",
  "bad_debt": "Bad Debt"
};

export const PaymentStatus: React.FC<IPaymentStatus> = (props) => {

  return (
    <PillStatus
      className={ props.className }
      status={ LABELS[props.status || "due"] }
      label={ props.label }
      icon={ <IconPayment /> }
      isInactive={ !props.status }
      isActive={ props.status === "due" || props.status === "overdue" || props.status === "paid_in_part" }
      isSuccessful={ props.status === "paid_in_full" }
      isUnsuccessful={ props.status === "bad_debt" }
      isMinimised={ props.isMinimised } />
  );
}

export interface IPaymentStatus {
  status?: PaymentState
  label?: string
  isMinimised?: boolean
  className?: string
}