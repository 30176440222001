import { Entity, SUMMARIES, TransactionExpanded, TransactionFilterPeriods, TransactionsSummary } from "@bookie/glossary";
import { isInPeriod } from "./check-deferment-period";
import { inferTransactionType } from "./infer-transaction-type";

export const summariseTransactions = (
  transactions: TransactionExpanded[],
  orgEntity?: Entity,
  period?: TransactionFilterPeriods
): TransactionsSummary => (
  (transactions || []).reduce(
    (summary: TransactionsSummary, tx: TransactionExpanded) => {

      const type = inferTransactionType(tx, orgEntity?.id);
      
      // If the transaction is Localised we will need 
      // to use that summary, otherwise the default 
      // can be assumed to be localised already. 

      const txSummary = tx.summary?.localised;

      // Expenses are either paid or due. 
      // We can just save them directly 
      // into either or. 

      if (type === "expense") {

        const _summary: TransactionsSummary = {}

        if (tx.paymentState === "paid_in_full") {
          _summary.expensesPaid = {
            gross: (summary.expensesPaid?.gross || 0) + (txSummary?.gross || 0),
            net: (summary.expensesPaid?.net || 0) + (txSummary?.net || 0),
            cost: (summary.expensesPaid?.cost || 0) + (txSummary?.cost || 0)
          }
        } else {
          _summary.expensesDue = {
            gross: (summary.expensesDue?.gross || 0) + (txSummary?.gross || 0),
            net: (summary.expensesDue?.net || 0) + (txSummary?.net || 0),
            cost: (summary.expensesDue?.cost || 0) + (txSummary?.cost || 0)
          } 
        }

        return {
          ...summary,
          ..._summary
        };

      }

      // The rest of the logic 
      // is designed to handle income 
      // transactions only. 

      const _summary: TransactionsSummary = {
        all: {
          gross: (summary.all?.gross || 0) + (txSummary?.gross || 0),
          net: (summary.all?.net || 0) + (txSummary?.net || 0),
          cost: (summary.all?.cost || 0) + (txSummary?.cost || 0)
        }
      }; 
      
      // We will first check to see if the transaction 
      // has been deferred, and if it has, 
      // if it should be included or excluded from 
      // our tally. 

      const isInActivePeriod = isInPeriod(tx, period);

      // We can consider a Transaction to be 
      // in Accrual if the invoice has been created 
      // and confirmed => in our case submitted. 

      if (tx.invoiceState === "submitted" && isInActivePeriod) {
        _summary.accrued = {
          gross: (summary.accrued?.gross || 0) + (txSummary?.gross || 0),
          net: (summary.accrued?.net || 0) + (txSummary?.net || 0),
          cost: (summary.accrued?.cost || 0) + (txSummary?.cost || 0)
        };
      }

      if (tx.invoiceState === "rejected") {
        _summary.badDebt = {
          gross: (summary.badDebt?.gross || 0) + (txSummary?.gross || 0),
          net: (summary.badDebt?.net || 0) + (txSummary?.net || 0),
          cost: (summary.badDebt?.cost || 0) + (txSummary?.cost || 0)
        }
      }

      // We can consider a Transaction to be 
      // paid, obviously, if we have at least 
      // one payment. 
      
      if (tx.payments && tx.payments.length > 0) {
        // TODO
      }

      // An invoice is pending if it is in 
      // "draft" state. 

      if (tx.invoiceState === "draft") {
        _summary.invoicePending = {
          gross: (summary.invoicePending?.gross || 0) + (txSummary?.gross || 0),
          net: (summary.invoicePending?.net || 0) + (txSummary?.net || 0),
          cost: (summary.invoicePending?.cost || 0) + (txSummary?.cost || 0)
        }
      }

      // An estimate is pending if it is in 
      // "draft" state or "submitted". 
      // We don't yet need to get granular with 
      // tracking estimates. 

      if (tx.estimateState === "draft" || tx.estimateState === "submitted") {
        _summary.estimatePending = {
          gross: (summary.estimatePending?.gross || 0) + (txSummary?.gross || 0),
          net: (summary.estimatePending?.net || 0) + (txSummary?.net || 0),
          cost: (summary.estimatePending?.cost || 0) + (txSummary?.cost || 0)
        }
      }

      return {
        ...summary,
        ..._summary
      };

    },
    SUMMARIES
  )
);

