import { ErrorAPI } from "@bookie/glossary";
import { SupabaseClient } from "@supabase/supabase-js";

export const _getDocumentURL = (sb: SupabaseClient): GetDocumentURL => 
  async (filename) => {

    const { data, error } = (
      await sb
        .storage 
        .from("org_documents")
        .createSignedUrl(filename, 3600)
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      url: data.signedUrl
    };

  }


export type GetDocumentURL = (
  filename: string
) => Promise<GetDocumentURLResponse>

export interface GetDocumentURLResponse {
  url: string
}