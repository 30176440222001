import { ErrorAPI, Payment, TransactionExpanded, uuid } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js";

export const _getTransactionExpanded = (sb: SupabaseClient): GetTransactionExpanded => 
  async (transactionId) => {

    // TODO 
    // we might need to remove the entity fetching 
    // here for efficiency. 
    // We already have this information because 
    // we pre-fetch all entities.

    const { data, error } = (
      await sb  
        .from("transaction")
        .select(`
          *,
          source_entity:entity!transaction_source_id_fkey(*),
          destination_entity:entity!transaction_destination_id_fkey(*),
          offerings:transaction_offerings (*),
          shipping_address:address!transaction_invoice_shipping_address_id_fkey(*),
          payments:transaction_payments (
            payment:payment_id(*)
          )
        `)
        .eq("id", transactionId)
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    const transaction = data[0];
    transaction.payments = transaction.payments.map((tp: { payment: Payment }) => tp.payment);

    return {
      transactionExpanded: objectToCamel<TransactionExpanded>(transaction)
    }

  }

export type GetTransactionExpanded = (transactionId: uuid) => Promise<GetTransactionExpandedResponse>

export interface GetTransactionExpandedResponse {
  transactionExpanded: TransactionExpanded
}