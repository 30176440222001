import { addDays, addMonths, addYears, isAfter, isBefore } from "date-fns";
import { Transaction, TransactionRepeatConfig } from "@bookie/glossary";

export const repeatTransaction = (
  tx: Transaction,
  config: TransactionRepeatConfig
): Transaction[] => {

  const _transactions: Transaction[] = [];

  let currentDate = new Date(config.startDate);
  const endDate = new Date(config.endDate);
  const today = new Date();

  while (isBefore(currentDate, endDate)) {
    
    let _tx: Transaction = {
      ...tx,
      invoiceDate: config.toDataReadable(currentDate),
      invoiceDue: config.toDataReadable(currentDate)
    };

    if (isAfter(currentDate, today)) {
      _tx = {
        ..._tx,
        invoiceState: "submitted"
      }
    }

    _transactions.push(_tx);

    switch (config.period) {
      case "repeat-weekly": currentDate = addDays(currentDate, 7); break;
      case "repeat-monthly": currentDate = addMonths(currentDate, 1); break;
      case "repeat-yearly": currentDate = addYears(currentDate, 1); break;
      default: break;
    }

  }

  return _transactions;

}