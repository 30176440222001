import React from "react";

export interface IWorkbenchCTX {
  registerWorkbench: () => number;
  unregisterWorkbench: (id: number) => void;
  getZIndex: (id: number) => number;
}

const WorkbenchCTX = React.createContext<IWorkbenchCTX | undefined>(undefined);

export const WorkbenchProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const [ stack, setStack] = React.useState<number[]>([]);

  const registerWorkbench = React.useCallback(() => {
    const id = Date.now();
    setStack((prev) => [...prev, id]);
    return id;
  }, []);

  const unregisterWorkbench = React.useCallback((id: number) => {
    setStack((prev) => prev.filter((workbenchId) => workbenchId !== id));
  }, []);

  const getZIndex = React.useCallback(
    (id: number) => {
      const index = stack.findIndex((workbenchId) => workbenchId === id);
      return 1000 + index;
    },
    [ stack]
  );

  const contextValue = React.useMemo(
    () => ({ registerWorkbench, unregisterWorkbench, getZIndex }),
    [registerWorkbench, unregisterWorkbench, getZIndex],
  );

  return (
    <WorkbenchCTX.Provider 
      value={ contextValue }>
      { children }
    </WorkbenchCTX.Provider>
  );
};

export const useWorkbench = () => {
  const context = React.useContext(WorkbenchCTX);
  if (!context) {
    throw new Error('useWorkbenchStack must be used within a WorkbenchStackProvider');
  }
  return context;
};
