import { EstimateState, InvoiceState, PaymentState, TransactionOrphan, TransactionStage } from "@bookie/glossary";
import { getTransactionStage } from "./get-transaction-stage";

export const inferTransactionStage = (
  transaction?: TransactionOrphan,
  paymentState?: PaymentState
): InferredTransactionStage | undefined => {

  const stage = getTransactionStage(
    transaction?.estimateState,
    transaction?.invoiceState,
    paymentState
  );

  if (!stage) {
    console.warn("Could not determine transaction stage.");
    return;
  }

  let status: EstimateState | InvoiceState | PaymentState | undefined = undefined;

  switch (stage) {
    case "estimate":
      if (transaction?.estimateState) {
        status = transaction.estimateState;
      }
      break;
    case "invoice":
      if (transaction?.invoiceState) {
        status = transaction.invoiceState;
      }
      break;
    case "payment":
      if (paymentState) {
        status = paymentState;
      }
      break;
    default:
      console.warn(`Unknown TransactionStage: ${stage}`);
      return undefined;
  }


  return {
    stage,
    status
  };

}

export interface InferredTransactionStage {
  stage: TransactionStage
  status?: EstimateState | InvoiceState | PaymentState
}
