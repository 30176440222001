import React from "react";
import { Button, InputTextArea } from "@bookie/components";
import cx from "classnames";
import { useOrgTemplateEditor } from "../../use/use-org-template-editor";

export const TemplateEditor: React.FC<ITemplateEditor> = (props) => {

  const editor = useOrgTemplateEditor();

  return(
    <div
      style={{ maxWidth: "900px" }}
      className={ "y fill-x border-r surface" }>

      <div 
        // style={{ "--hny-input-w": "100%" } as React.CSSProperties}
        className={ cx(
          "y fill-x"
        ) }>
        
        <div 
          className={ "x surface border-b space-blocks-frame-lr space-lines-frame-tb align-x-between" }>
          <Button
            label={ "Save" }
            size={ "slim" }
            onClick={ async () => {
              await editor.commit();
            } } />
        </div>
      
      </div>

      <div 
        className={ "x fill" }>
          
        <div className={ "y fill" }>
          <div 
            style={{ height: "0px" }}
            className={ "y fill surface scrollable fill-inputs" }>
              
            <div 
              id={ "legal-registration" }
              className={ cx(
                "surface border-b space-blocks-frame space-blocks-children y"
              ) }>
              <span className={ "surface-base surface-heading" }>{ "Estimate" }</span>
              <InputTextArea
                backgroundType={ "fill" }
                label={ "Footer Template" }
                description={ "This copy will be used as the footer for your Estimate PDFs." }
                value={ editor.data.estimateFooter }
                onChange={ v => editor.edit.estimateFooter(v as string) } />
            </div>

            <div 
              id={ "legal-registration" }
              className={ cx(
                "surface border-b space-blocks-frame space-blocks-children y"
              ) }>
              <span className={ "surface-base surface-heading" }>{ "Invoice" }</span>
              <InputTextArea
                backgroundType={ "fill" }
                label={ "Footer Template" }
                description={ "This copy will be used as the footer for your Estimate PDFs." }
                value={ editor.data.invoiceFooter }
                onChange={ v => editor.edit.invoiceFooter(v as string) } />
            </div>

            <div 
              id={ "legal-registration" }
              className={ cx(
                "surface border-b space-blocks-frame space-blocks-children y"
              ) }>
              <span className={ "surface-base surface-heading" }>{ "Receipt" }</span>
              <InputTextArea
                backgroundType={ "fill" }
                label={ "Footer Template" }
                description={ "This copy will be used as the footer for your Estimate PDFs." }
                value={ editor.data.receiptFooter }
                onChange={ v => editor.edit.receiptFooter(v as string) } />
            </div>

          </div>
        </div>
      
      </div>

    </div>
  );

}

export interface ITemplateEditor {

}