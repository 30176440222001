import React from "react"
import css from "./index.module.css"
import cx from "classnames"
import { IconDownAccordian, IconLock, IconUpAccordian } from "../icon";
import { Tooltip } from "../tooltip";
import { Button } from "../button";

export const Panel: React.FC<IPanel> = (props) => {

  const [ isOpen, setIsOpen ] = React.useState<boolean>(props.defaultOpen || false);
  const [ isDirty, setIsDirty ] = React.useState<boolean>(false);

  return (
    <div
      className={ cx(
        css.panel,
        "y fill-x",
        props.className,
        { "border-b": !props.isGhost },
        { [css.panel_isOpen]: isOpen },
        { [css.panel_isClosed]: !isOpen },
        { [css.panel_isDisabled]: props.isDisabled }
      ) }>

        <div
          style={{ display: props.isGhost ? "none" : "flex" }} 
          onClick={ () => {
            !isDirty && setIsDirty(true);
            setIsOpen(!isOpen)
          } }
          className={ cx(
            css.panel_header,
            { "surface-alt apply-bg apply-fg border-b": isOpen },
            { "surface-alt apply-bg border-b": !isOpen },
            "x space-lines-frame space-lines-children hvr-icon-down"
          ) }>
          
          <Button
            variant={ "inline" }
            iconSize={ 16 }
            className={ cx(
              css.panel_header_upDown,
              "x align-x-center align-y-center"
            ) }
            icon={
              <div 
                className={ "hvr-icon" }>
                { isOpen ? <IconUpAccordian /> : <IconDownAccordian />  }
              </div>
            } />
          
          <div 
            className={ cx(
              css.panel_header_meta,
              "x fill-x align-x-between align-y-center space-lines-children"
            ) }>
            
            <div 
              className={ "x space-inlinez-children align-y-center" }>
              
              { 
                props.icon && React.cloneElement(
                  props.icon,
                  { size: 24 }
                )
              }

              <h2>{ props.label }</h2>
            

            </div>
            
            <div 
              className={ "x space-inline-children align-y-center" }>
              
              {
                !isOpen && props.summary 
                  ? <p className={ css.panel_header_meta_summary }>{ props.summary }</p>
                  : ""
              }
              
              {
                props.isDisabled && props.disabledMessage && 
                  <div
                    className={ cx(
                      css.panel_header_meta_disabledMessage,
                      "x align-x-center surface-info"
                    ) }>
                    <Tooltip
                      position={ "left" }
                      message={ props.disabledMessage }
                      base={ <IconLock /> } />
                  </div>
              }

              <div
                className={ css.panel_header_meta_actions } 
                onClick={ (e) => e.stopPropagation() }>
                { 
                  props.actions?.(
                    () => {
                      !isDirty && setIsDirty(true);
                      !isOpen && setIsOpen(true);
                    }
                  ) 
                }
              </div>

            </div>

          </div>
          

        </div>

        <div 
          className={ cx(
            css.panel_content,
            "x fill-x"
            // { "space-lines-frame-b space-lines-frame-lr": props.isEmpty },
            // { "space-lines-frame": !props.isEmpty }
          ) }>
          
          { !props.isEmpty && props.children }

          {
            props.isEmpty && 
              <div className={ "space-lines-frame surface-info" }>
                { props.emptyMessage || "There is no content to display yet." }
              </div>
          }

        </div>
      
    </div>
  );
}

export interface IPanel {
  className?: string
  defaultOpen?: boolean
  label: string
  icon?: React.ReactElement
  actions?: (open: () => void) => React.ReactNode
  children?: React.ReactNode
  isEmpty?: boolean
  emptyMessage?: string
  isDisabled?: boolean 
  disabledMessage?: string
  summary?: string
  isGhost?: boolean
}