import { ErrorAPI, TransactionOffering, TransactionOfferingOrphan } from "@bookie/glossary"
import { objectToCamel, objectToSnake, stripNullAndUndefinedFromObject } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js"

export const _createTransactionsOfferings = (sb: SupabaseClient): CreateTransactionsOfferings => 
  async (
    transactionOfferings
  ) => {

    const sanitisedOfferings = objectToSnake(transactionOfferings.map(to => stripNullAndUndefinedFromObject(to)));

    const { data, error } = (
      await sb
        .from("transaction_offerings")
        .insert(sanitisedOfferings)
        .select()
    );

    if (error?.code) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      transactionOfferings: objectToCamel<TransactionOffering[]>(data)
    };

  }

export type CreateTransactionsOfferings = (
  transactionOfferings: TransactionOfferingOrphan[]
) => Promise<CreateTransactionsOfferingsResponse>

export interface CreateTransactionsOfferingsResponse {
  transactionOfferings: TransactionOffering[]
}