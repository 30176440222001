import { ErrorAPI } from "@bookie/glossary";
import { SupabaseClient } from "@supabase/supabase-js";

export const _uploadFile = (sb: SupabaseClient): UploadFile => 
  async (file, filename) => {

    const { data, error } = (
      await sb
        .storage 
        .from("org")
        .upload(
          filename,
          file,
          { upsert: true }
        )
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      filepath: data.path
    };

  }


export type UploadFile = (
  file: File,
  filename: string
) => Promise<UploadFileResponse>

export interface UploadFileResponse {
  filepath: string
}