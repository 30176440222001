import React from "react";
import { InputSelect, IconCurrency, IInput } from "@bookie/components";
import { CURRENCIES, Currency, uuid } from "@bookie/glossary";
import { getCurrency } from "../../fns/get-currency";

export const SelectCurrency: React.FC<ISelectCurrency> = (props) => {
  return (
    <InputSelect
      { ...props }
      style={ props.style }
      icon={ props.icon || <IconCurrency /> }
      name={ "currency" }
      optionsAlign={ props.optionsAlign }
      label={ props.label }
      type={ "text" }
      size={ props.size }
      options={ CURRENCIES }
      value={ getCurrency(props.selectedCurrencyCode) }
      actions={ props.actions }
      searchKey={ "name" }
      // formatSelectedLabel={ (c: Currency) => {
      //   console.log(c);
      //   return (
      //     <div
      //       className={ "x space-inline-children align-y-center" }>
      //       <div>{ c?.emoji }</div>
      //     </div>
      //   );
      // }}
      formatLabel={ (c: Currency) => {
        return (
          <div
            className={ "x space-inline-children align-y-center" }>
            
            <div>{ c.emoji }</div>

            {
              props.showSymbol &&
                <div>{ c.symbol }</div> 
            }

            {
              props.showName && 
                <div>{ c.name }</div>
            }
            
          </div>
        )
      } }
      matchKey={ "code" }
      onSelect={ (c: Currency) => props.onSelect?.(c) }
      
      labelPosition={ props.labelPosition }
      backgroundType={ props.backgroundType } />
  );
}

export interface ISelectCurrency extends IInput {
  showName?: boolean
  showSymbol?: boolean
  selectedCurrencyCode?: uuid
  onSelect?: (c: Currency) => void
  actions?: { label: string, onClick: () => void }[]
  optionsAlign?: "left" | "right"
}