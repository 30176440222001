import { Address, ErrorAPI, uuid } from "@bookie/glossary"
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel } from "@bookie/utils";

export const _getAddress = (sb: SupabaseClient): GetAddress => 
  async (addressId) => {

    const { data, error } = (
      await sb
        .from("address")
        .select()
        .eq("id", addressId)
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      address: objectToCamel<Address>(data[0])
    };

  }

export type GetAddress = (
  addressId: uuid
) => Promise<GetAddressResponse>

export interface GetAddressResponse {
  address: Address;
}