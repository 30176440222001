import React from "react";
import { ErrorAPI, InvoiceState, ObjectFieldValidation, Payment, PaymentOrphan, PaymentState, TransactionOfferingOrphan, uuid } from "@bookie/glossary";
import { InterfaceAsUndefined } from "@bookie/glossary";
import { useIdentity } from "@bookie/module-identity";
import { useValidator } from "@bookie/utils";
import { useBookkeepingAPI } from "../use-bookkeeping-api";
import { validateTransactionPayments } from "../fns/validate-transaction-payments";
import { useNotification } from "@bookie/components";
import { inferPaymentState } from "../fns/infer-payment-state";

export const useTransactionPaymentsEditor = (
  payments?: Payment[],
  _newPayments?: PaymentOrphan[],
  config?: { 
    currencyCode?: string,
    gross?: number,
    paymentDueDate?: string,
    invoiceState?: InvoiceState,
    updatePaymentState?: (s?: PaymentState) => void 
  }
): ITransactionPaymentsEditorAPI => {

  const n = useNotification();
  const api = useBookkeepingAPI();
  const { ownership } = useIdentity();

  const [ existing, setExisting ] = React.useState<Payment[]>([]);
  const [ toCreate, setToCreate ] = React.useState<PaymentOrphan[]>([]);
  const [ toDelete, setToDelete ] = React.useState<Payment[]>([]);

  const [ isNewReady, setIsNewReady ] = React.useState<boolean>(false);
  const [ isExistingReady, setIsExistingReady ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (payments && payments.length > 0) {
      setExisting(payments);
    }
    setIsExistingReady(true);
  }, [ payments ]);

  React.useEffect(() => {
    if (_newPayments && _newPayments.length > 0) {
      setToCreate(_newPayments.map(no => ({
        ...no,
        // type: "digital_product"
      })));
    }
    setIsNewReady(true);
  }, [ _newPayments, ownership ]);

  const editNew = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (index: number, field: keyof Payment, value: any) => {
      setToCreate((prevOfferings) => {
        const newOfferings = [...prevOfferings];
        newOfferings[index] = {
          ...newOfferings[index],
          [field]: value,
        };
        return newOfferings;
      });
    },
    []
  );

  const editExisting = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (index: number, field: keyof Payment, value: any) => {
      setExisting((prevOfferings) => {
        const newOfferings = [...prevOfferings];
        newOfferings[index] = {
          ...newOfferings[index],
          [field]: value,
        };
        return newOfferings;
      });
    },
    []
  );

  const add = React.useCallback(
    () => {
      setToCreate((prevOfferings) => {
        
        // TODO 
        // We need to figure out a way to ensure 
        // that ownership object is always defined
        // from the context provider. 
        // You can't really do anything without one anyway
        // and it will greatly simply the logic 
        // of everything else. 

        if (ownership.current && ownership.current.entity?.id) { 
          return (
            [
              ...prevOfferings,
              {
                currencyCode: config?.currencyCode,
                exchangeRate: 0,
                gross: config?.gross,
                grossLocalised: 0,
                paymentDate: config?.paymentDueDate || new Date().toISOString(),
                exchangeDate: config?.paymentDueDate || new Date().toISOString()
              }
            ]
          )
        } else {
          return prevOfferings
        }
      });
    },
    [config?.currencyCode, config?.gross, config?.paymentDueDate, ownership]
  );

  const removeNew = React.useCallback(
    (index: number) => {
      setToCreate((prevOfferings) => prevOfferings.filter((_, i) => i !== index));
    },
    []
  );

  const removeExisting = React.useCallback(
    (id: uuid) => {
      const _to = existing.find(eo => eo.id === id);
      setExisting((prevOfferings) => prevOfferings.filter(po => po.id !== _to?.id));
      _to && setToDelete(prevOfferings => ([ ...prevOfferings, _to ]));
    },
    [ existing ]
  );

  // Re-validate the forms 
  // for both the new Transaction Offerings
  // as well as the existing ones. 

  const {
    isVirgin: isNewVirgin,
    validate: validateNew,
    setDirty: setDirtyNew,
    validationErrorsArray: validationNew,
    setValidationErrorsArray: setValidationNew
  } = useValidator(
    (_tp) => {
      const _validation = validateTransactionPayments(_tp as TransactionOfferingOrphan[]);
      setValidationNew(_validation.errors);
      return _validation.isValid;
    },
    toCreate,
    undefined,
    isNewReady
  );

  const {
    isVirgin: isExistingVirgin,
    validate: validateExisting,
    setDirty: setDirtyExisting,
    validationErrorsArray: validationExisting,
    setValidationErrorsArray: setValidationExisting
  } = useValidator(
    (_tp) => {
      const _validation = validateTransactionPayments(_tp as TransactionOfferingOrphan[]);
      setValidationExisting(_validation.errors);
      return _validation.isValid;
    },
    existing,
    undefined,
    isExistingReady
  );

  React.useEffect(() => {
    
    if (config?.currencyCode) {
      
      const _payments = [ ...existing, ...toCreate ];
      
      const inferredPaymentState = inferPaymentState(
        _payments as Payment[],
        config.currencyCode,
        config?.gross,
        config?.paymentDueDate,
        config?.invoiceState
      );

      config.updatePaymentState?.(inferredPaymentState);

    }

  }, [config, existing, toCreate]);

  const validate = () => {
    const areNewValid = validateNew();
    const areExistingValid = validateExisting();
    return areNewValid && areExistingValid;
  }

  return {

    all: [
      ...toCreate,
      ...existing
    ],

    new: {
      data: toCreate,
      add,
      edit: editNew,
      remove: removeNew,
      errors: {
        setDirty: setDirtyNew,
        validation: validationNew,
        setValidation: setValidationNew 
      },
      clear: () => setToCreate([])
    },

    existing: {
      data: existing,
      edit: editExisting,
      remove: removeExisting,
      errors: {
        setDirty: setDirtyExisting,
        validation: validationExisting,
        setValidation: setValidationExisting 
      },
      clear: () => setExisting([])
    },

    delete: {
      data: toDelete
    },

    validate,

    commit: async (transactionId) => {

      setDirtyNew();
      setDirtyExisting();

      const canCommit = validate();
      if (!canCommit) return false;

      let _payments: Payment[] | false = false;

      try {
        if (toCreate && toCreate.length > 0 && !isNewVirgin) {
          const { transactionPayments: created } = await api.createTransactionPayments(transactionId, toCreate);
          if (Array.isArray(_payments)) {
            _payments.push(...created);
          } else {
            _payments = created;
          }
          setToCreate([]);
        }
      } catch (e) {
        if (e instanceof ErrorAPI){
          n.notify({ type: "error", message: e.error });
        }
      }

      try {
        if (existing && existing.length > 0 && !isExistingVirgin) {
          const { payments: updated } = await api.updatePayments(existing);
          if (Array.isArray(_payments)) {
            _payments.push(...updated);
          } else {
            _payments = updated;
          }
          setExisting([]);
        }
      } catch (e) {
        if (e instanceof ErrorAPI){
          n.notify({ type: "error", message: e.error });
        }
      }

      try {
        if (toDelete && toDelete.length > 0) {
          await api.deleteTransactionPayments(toDelete);
          if (!_payments) {
            _payments = [];
          }
          setToDelete([]);
        }
      } catch (e) {
        if (e instanceof ErrorAPI){
          n.notify({ type: "error", message: e.error });
        }
      }

      return _payments;

    },

  };
};

export default useTransactionPaymentsEditor;

export interface ITransactionPaymentsEditorAPI {
  all: InterfaceAsUndefined<PaymentOrphan>[],
  new: {
    data: InterfaceAsUndefined<PaymentOrphan>[]
    add: () => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    edit: (index: number, field: keyof Payment, value: any) => void
    remove: (i: number) => void
    errors: {
      setDirty: () => void
      validation?: ObjectFieldValidation[]
      setValidation: (v: ObjectFieldValidation[]) => void
    },
    clear: () => void
  },
  existing: {
    data: Payment[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    edit: (index: number, field: keyof Payment, value: any) => void
    remove: (id: uuid) => void
    errors: {
      setDirty: () => void
      validation?: ObjectFieldValidation[]
      setValidation: (v: ObjectFieldValidation[]) => void
    },
    clear: () => void
  },
  delete: {
    data: Payment[]
  },
  validate: () => boolean,
  commit: (transactionId: uuid) => Promise<Payment[] | false>
}

export interface ITransactionPaymentsEditorState {}