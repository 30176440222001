import React from "react"
import { ITransactionEditorAPI } from "../../../use/use-transaction-editor";
import { Button, IconAdd, IconPayment } from "@bookie/components";
import { ITransactionEditorLifecycle } from "../../transaction-editor";
import { PaymentStatus } from "../../../components/payment-status";
import { TransactionPaymentsEditor } from "../../transaction-payments-editor";

export const TransactionAssistPayment: React.FC<ITransactionAssistPayment> = (props) => {
  return (
    <div
      style={{ 
        maxWidth: "70%",
        width: "70%" 
      }}
      className={ "y space-lines-children align-x-center" }>

        <div
          className={ "" }>
          <IconPayment
            size={ 40 } />
        </div>

        <div
          style={{ textAlign: "center" }}
          className={ "space-lines-frame-b space-inlinez-children y" }>
          <h1>
            { "Payment" }
          </h1>
          <div
            style={{ alignSelf: "center" }}>
            <PaymentStatus
              status={ props.editor?.state.paymentState } />
          </div>
        </div>

        {
          props.editor?.state.paymentState !== "paid_in_full" && 
            <div
              className={ "x align-x-center" }>
              <Button
                variant={ "text" }
                icon={ <IconAdd /> }
                iconSize={ 32 }
                onClick={ () => props.editor?.related.transactionPaymentsEditor.new.add() } />
            </div>
        }

        <TransactionPaymentsEditor
          editor={ props.editor?.related.transactionPaymentsEditor }
          renderBare={ true }
          surfaceDisplay={ "surface-primary" } />

        {
          (
            (props.editor?.related.transactionPaymentsEditor.all.length || 0) > 0 &&
            true //props.editor?.state.paymentState !== "paid_in_full"
          ) &&  
            <div
              className={ "y fill-x align-x-center space-cubes-children" }>
              <Button
                label={ "Save and Finish" }
                onClick={ async () => {
                  const isValid = props.editor?.validate();
                  if (isValid) {
                    const result = await props.editor?.commit();
                    if (typeof result === "object") {
                      props.editor?.data.id && props.onEdit?.(props.editor.data.id, undefined, false);
                    }
                  }
                } } />
            </div>
        }
    
    </div>
  );
}

export interface ITransactionAssistPayment extends ITransactionEditorLifecycle {
  editor?: ITransactionEditorAPI
}