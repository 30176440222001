import { ErrorAPI, Offering, uuid } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js";

export const _deleteOffering = (sb: SupabaseClient): DeleteOffering => 
  async (offeringId) => {

    const { data, error } = (
      await sb
        .from('offering')
        .delete()
        .eq("id", offeringId)
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      offering: objectToCamel<Offering>(data[0])
    };

  }

export type DeleteOffering = (
  offeringId: uuid
) => Promise<DeleteOfferingResponse>

export interface DeleteOfferingResponse {
  offering: Offering
}