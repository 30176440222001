import { TransactionExpanded, TransactionOffering, TransactionSummary } from "@bookie/glossary";
import { summariseTransactionOffering } from "./summarise-transaction-offering";

export const summariseTransaction = (
  transaction: TransactionExpanded
): TransactionSummary => {

  const exchangeRate = transaction.invoiceExchangeRate || transaction.estimateExchangeRate || 1;
  
  return (
    (transaction.offerings || []).reduce(
      (summary: TransactionSummary, o: TransactionOffering) => {

        const _gross = summariseTransactionOffering(
          o.grossSale,
          o.quantity,
          o.tax,
          o.taxType 
        );
  
        const _cost = summariseTransactionOffering(
          o.grossCost,
          o.quantity,
          o.tax,
          o.taxType
        );
  
        const _net = _gross - _cost;
  
        return ({
          
          gross: summary.gross + _gross,
          net: summary.net + _net,
          cost: summary.cost + _cost,

          // If the transaction's currency 
          // is in a different one to the local. 
  
          localised: (
            exchangeRate !== 1
              ? (
                {
                  gross: summary.gross + Math.round(_gross * exchangeRate),
                  net: summary.net + Math.round(_net * exchangeRate),
                  cost: summary.cost + Math.round(_cost * exchangeRate)
                }
              )
              
              // If there's no exchange rate we can assume 
              // that the transaction is already localised
              // so we will copy the values. 
              
              // We will always want to return a value 
              // in localised so it will be accessible
              // from one location.  
              
              : (
                {
                  gross: summary.gross + _gross,
                  net: summary.net + _net,
                  cost: summary.cost + _cost
                }
              )
          )
  
        });
  
      },
      {
        gross: 0,
        net: 0,
        cost: 0
      }
    )
  );

}