import { ErrorAPI, OrgTemplate, OrgTemplateOrphan } from "@bookie/glossary";
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel, objectToSnake } from "@bookie/utils";

export const _updateOrgTemplate = (sb: SupabaseClient): UpdateOrgTemplate => 
  async (orgTemplate) => {

    const { data, error } = (
      await sb 
        .from("org_template")
        .upsert(
          objectToSnake(orgTemplate),
          { onConflict: "id" }
        )
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      orgTemplate: objectToCamel<OrgTemplate>(data[0])
    };

  }

export type UpdateOrgTemplate = (
  orgTemplate: OrgTemplateOrphan
) => Promise<UpdateOrgTemplateResponse>

export interface UpdateOrgTemplateResponse {
  orgTemplate: OrgTemplate
}