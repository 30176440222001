import { SupabaseClient } from "@supabase/supabase-js";
import { ErrorAPI, UserProfile } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";

export const _getUserProfile = (sb: SupabaseClient): GetUserProfile =>
  async () => {

    const { data, error } = (
      await sb
        .from("user_profile")
        .select("*")
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }
  
    return {
      profile: objectToCamel<UserProfile>(data[0])
    };
  
  };

export type GetUserProfile = () => Promise<GetUserProfileResponse>

export interface GetUserProfileResponse {
  profile: UserProfile
}