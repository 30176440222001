import { IconProps } from "@phosphor-icons/react";
import React from "react";

const findClosestColour = (icon: HTMLElement | null): string | null => {
  if (!icon) return null;
  const style = getComputedStyle(icon);
  const _colour = style.getPropertyValue("--color-icon");
  if (_colour) return _colour.trim();
  else return findClosestColour(icon.parentElement);
}

export const IconBase: React.FC<IIconBase> = (props) => {
  
  const ref = React.useRef(null);
  const [ colour, setColour ] = React.useState<string>();

  if (ref.current) {
    const _colour = findClosestColour(ref.current);
    if (_colour && colour !== _colour) setColour(_colour);
  }

  React.useLayoutEffect(() => {
    const _colour = findClosestColour(ref.current);
    if (_colour && colour !== _colour) setColour(_colour);
  }, [ colour, props.children ]);

  return (
    React.cloneElement(
      props.children,
      { 
        ref,
        size: props.size || 14,
        color: props.color || colour || "#000",
        weight: props.weight || "duotone",
        style: { flexShrink: 0 } 
      }
    )
  );

}

export interface IIconBase extends IconProps {
  children: React.ReactElement
}