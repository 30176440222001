/* eslint-disable react-hooks/rules-of-hooks */
import { Transaction, TransactionType, InterfaceAsUndefined, uuid, EstimateState, timestamptz, InvoiceState, PaymentState, TransactionOrphan, TransactionOffering, TransactionOfferingOrphan, EntityOrphan, TransactionSeed, ObjectFieldValidation, ErrorAPI, Payment, PaymentOrphan, Address, TransactionExpanded, TransactionSummary } from "@bookie/glossary";
import React from "react";
import { useBookkeepingAPI } from "../use-bookkeeping-api";
import { useIdentity } from "@bookie/module-identity";
import { useBookkeeping } from "../use-bookkeeping";
import { validateTransaction } from "../fns/validate-transaction";
import { getCountry, useCore, getEntity, useEntityEditor, IEntityEditorAPI, IAddressEditorAPI, useAddressEditor } from "@bookie/module-core";
import { useLocalisation, ILocalisationState } from "./use-localisation";
import { useTransactionOfferingsEditor, ITransactionOfferingsEditorAPI } from "./use-transaction-offerings-editor";
import { useValidator, ValidateFn } from "@bookie/utils";
import { addDays, addWeeks, format } from "date-fns";
import { useNotification } from "@bookie/components";
import useTransactionPaymentsEditor, { ITransactionPaymentsEditorAPI } from "./use-transaction-payments-editor";
import { summariseTransaction } from "../fns/summarise-transaction";
import { inferTransactionType } from "../fns/infer-transaction-type";

export const useTransactionEditor = (
  transactionId?: uuid | "create",
  transactionEditor?: ITransactionEditorAPI,
  seed?: TransactionSeed,
  config?: {
    transactionType?: TransactionType,
    transactionStage?: "estimate" | "invoice"
  }
): ITransactionEditorAPI => {

  if (transactionEditor) {
    return transactionEditor;
  }

  const _stage = config?.transactionStage;
  const [ type, setType ] = React.useState<TransactionType | undefined>(config?.transactionType);

  const { notify } = useNotification();
  const api = useBookkeepingAPI();

  const books = useBookkeeping();
  const { ownership } = useIdentity();
  const { entities } = useCore();

  // Transaction 
  // These properties are properties
  // required to create a new TX. 

  const [ sourceId, setSourceId ] = React.useState<uuid>();
  const [ destinationId, setDestinationId ] = React.useState<uuid>(); 
  const [ description, setDescription ] = React.useState<string>();
  const [ notes, setNotes ] = React.useState<string>();

  const [ currencyCode, setCurrencyCode ] = React.useState<string>();
  const [ isManuallyLocalised, setIsManuallyLocalised ] = React.useState<boolean>();
  const [ isManuallyCalculated, setIsManuallyCalculated ] = React.useState<boolean>();

  const [ estimateState, setEstimateState ] = React.useState<EstimateState>();
  const [ estimateDate, setEstimateDate ] = React.useState<timestamptz>();
  const [ estimateExpiration, setEstimateExpiration ] = React.useState<timestamptz>();
  const [ estimateExchangeRate, setEstimateExchangeRate ] = React.useState<number>();

  const [ invoiceState, setInvoiceState ] = React.useState<InvoiceState>();
  const [ invoiceDate, setInvoiceDate ] = React.useState<timestamptz>();
  const [ invoiceDue, setInvoiceDue ] = React.useState<timestamptz>();
  const [ invoiceUrl, setInvoiceUrl ] = React.useState<string>();
  const [ invoiceShippingAddressId, setInvoiceShippingAddressId ] = React.useState<uuid>();
  const [ invoiceExchangeRate, setInvoiceExchangeRate ] = React.useState<number>();

  const [ paymentState, setPaymentState ] = React.useState<PaymentState>();

  const [ deferredDate, setDeferredDate ] = React.useState<timestamptz>();
  const [ deferredReason, setDeferredReason ] = React.useState<timestamptz>();

  // And these are post-create properties
  
  const [ txId, setTxId ] = React.useState<string>();
  const [ createdAt, setCreatedAt ] = React.useState<timestamptz>();
  const [ createdBy, setCreatedBy ] = React.useState<uuid>();
  const [ ownedBy ] = React.useState<uuid>();

  // The following are meta properties
  // relevant to the transaction,
  // that will help us with any logic.

  const [ offerings, setOfferings ] = React.useState<TransactionOffering[]>([]);
  const [ newOfferings, setNewOfferings ] = React.useState<TransactionOfferingOrphan[]>([]);
  const [ payments, setPayments ] = React.useState<Payment[]>([]);
  const [ newPayments, setNewPayments ] = React.useState<PaymentOrphan[]>([]);
  const [ newEntity, setNewEntity ] = React.useState<EntityOrphan>();
  const [ shippingAddress, setShippingAddress ] = React.useState<Address>();

  const [ isExchangingAmount ] = React.useState<boolean>(false);
  const [ isCreatingNewEntity, setIsCreatingNewEntity ] = React.useState<boolean>(false);
  const [ isCreatingTransaction, setIsCreatingTransaction ] = React.useState<boolean>(false);
  const [ isUpdatingTransaction, setIsUpdatingTransaction ] = React.useState<boolean>(false);

  const [ isReady, setIsReady ] = React.useState<boolean>(false);

  // Related editors 

  const entityEditor = useEntityEditor(
    (type === "income" ? sourceId : destinationId) || "create",
    undefined,
    newEntity,
    {
      entityType: type === "income" ? "client" : "merchant",
      editorType: "expanded"
    }
  );

  const shippingAddressEditor = useAddressEditor(
    invoiceShippingAddressId || "create",
    shippingAddress
  );

  const transactionOfferingsEditor = useTransactionOfferingsEditor(offerings, newOfferings, currencyCode);

  // Handles logic to figure out 
  // if we need to localise and handles
  // the localisation (currency exchange.)

  const {
    state: localisationState
  } = useLocalisation(
    (er) => { 
      // debugger;
      setInvoiceExchangeRate(er)
     },
    currencyCode,
    invoiceDate,
    isManuallyLocalised
  );

  useLocalisation(
    (er) => setEstimateExchangeRate(er),
    currencyCode,
    estimateDate,
    isManuallyLocalised
  );

  const _transaction = {
    sourceId,
    destinationId,
    description,
    notes,
    currencyCode,
    isManuallyCalculated,
    isManuallyLocalised,
    estimateDate,
    estimateExpiration,
    estimateState,
    estimateExchangeRate,
    invoiceDate,
    invoiceDue,
    invoiceState,
    invoiceUrl,
    invoiceShippingAddressId,
    invoiceExchangeRate,
    deferredDate,
    deferredReason,
    id: txId,
    createdAt,
    createdBy
  }
  
  const summary = summariseTransaction(
    {
      ..._transaction,
      offerings: transactionOfferingsEditor.all
    } as unknown as TransactionExpanded
  );

  const transactionPaymentsEditor = useTransactionPaymentsEditor(
    payments, 
    newPayments, 
    { 
      currencyCode, 
      gross: summary.gross, 
      paymentDueDate: invoiceDue,
      invoiceState,
      updatePaymentState: (s) => {
        if (invoiceState === "submitted" || invoiceState === "rejected") {
          setPaymentState(s);
        }
      }
    }
  );

  const {
    validate,
    isVirgin,
    setDirty,
    validationErrors,
    setValidationErrors
  } = useValidator(
    (_tx, opts) => {
      
      const isEntityValid = entityEditor.validate();
      const areOfferingsValid = transactionOfferingsEditor.validate();
      const arePaymentsValid = transactionPaymentsEditor.validate();

      if (!type) return false

      const _validation = validateTransaction(
        _tx as TransactionOrphan,
        type,
        { ignoreEntities: isCreatingNewEntity, ...opts }
      );

      setValidationErrors(_validation.errors);
      
      return isEntityValid && areOfferingsValid && arePaymentsValid && _validation.isValid;

    },
    { 
      ..._transaction, 
      offerings: transactionOfferingsEditor.all,
      payments: transactionPaymentsEditor.all 
    },
    [ isCreatingNewEntity ],
    isReady
  );



  // TODO 
  // I want to clean this up,
  // it is not the best way to handle initialisation. 

  React.useEffect(() => {
    
    // If the ID is set to "create"
    // then it implies we are creating a new 
    // transaction. 

    // Over here all we need to do is initialise
    // with any necessary defaults.

    if (transactionId === "create") {

      // setGross(seed?.transaction?.gross || 0);
      // setGrossLocalised(seed?.transaction?.grossLocalised || 0);
      setCurrencyCode(seed?.transaction?.currencyCode || ownership.current?.entity?.currencyCode);
      setDescription(seed?.transaction?.description);

      if (_stage === "estimate") {
        const today = new Date();
        const _estimateDate = format(today, "yyyy-MM-dd");
        const _estimateDue = format(addWeeks(today, 2), "yyyy-MM-dd");
        const _invoiceDate = format(addDays(addWeeks(today, 2), 1), "yyyy-MM-dd");
        const _invoiceDue = format(addWeeks(addDays(addWeeks(today, 2), 1), 2), "yyyy-MM-dd");

        setEstimateState("draft");
        setEstimateDate(seed?.transaction?.estimateDate || _estimateDate);
        setEstimateExpiration(seed?.transaction?.estimateExpiration || _estimateDue);
        setInvoiceDate(seed?.transaction?.invoiceDate || _invoiceDate);
        setInvoiceDue(seed?.transaction?.invoiceDue || _invoiceDue);
      }
      else if (_stage === "invoice") {
        const today = new Date();
        const _invoiceDate = format(today, "yyyy-MM-dd");
        const _invoiceDue = format(addWeeks(today, 2), "yyyy-MM-dd");

        setEstimateState("n/a");
        setInvoiceState("draft");
        setInvoiceDate(seed?.transaction?.invoiceDate || _invoiceDate);
        setInvoiceDue(seed?.transaction?.invoiceDue || _invoiceDue);
      }
      
      if (type === "income") {
        setDestinationId(ownership.current?.entity?.id);
      }
      else if (type === "expense") {
        setSourceId(ownership.current?.entity?.id);
        setInvoiceState("submitted");
      }

      seed?.transactionOfferings && setNewOfferings(seed?.transactionOfferings);
      seed?.transactionPayments && setNewPayments(seed?.transactionPayments);
      seed?.entity && setNewEntity(seed?.entity);

      setIsReady(true);

    } 

    // If we've got an actual ID
    // then we will need to fetch the
    // full transaction object,
    // including any joins.

    else if (typeof transactionId === "string" && transactionId !== "create") {
      (async () => {
        try {
          
          // if (!isVirgin) {
          //   return;
          // }

          let t: TransactionExpanded | undefined = undefined;

          t = books.transactions.find(transactionId);

          if (!t) {
            const { transactionExpanded: _t } = await api.getTransactionExpanded(transactionId);
            t = _t;
          }

          // We'll save all to context store
          // so that it can be easily used the 
          // rest of the logic
          
          setSourceId(t?.sourceId);
          setDestinationId(t?.destinationId);
          setDescription(t?.description);
          setNotes(t?.notes);
          setCurrencyCode(t?.currencyCode);
          setIsManuallyLocalised(t?.isManuallyLocalised);
          setIsManuallyCalculated(t?.isManuallyCalculated);
          setEstimateState(t?.estimateState);
          setEstimateDate(t?.estimateDate);
          setEstimateExpiration(t?.estimateExpiration);
          setEstimateExchangeRate(t?.estimateExchangeRate);
          setInvoiceDate(t?.invoiceDate);
          setInvoiceState(t?.invoiceState);
          setInvoiceDue(t?.invoiceDue);
          setInvoiceUrl(t?.invoiceUrl);
          setInvoiceExchangeRate(t?.invoiceExchangeRate);
          setDeferredDate(t?.deferredDate);
          setDeferredReason(t?.deferredReason);
          
          setTxId(t?.id);
          setCreatedAt(t?.createdAt);
          setCreatedBy(t?.createdBy);

          (t?.offerings && t.offerings.length > 0) && 
            setOfferings(t.offerings);
          
          (t?.payments && t.payments.length > 0) &&
            setPayments(t.payments);
          
          setShippingAddress(t?.shippingAddress);

          const _type = inferTransactionType(t, ownership.current?.entity?.id);
          setType(_type);
          
          setIsReady(true);

        } catch (e) {
          if (e instanceof ErrorAPI) {
            notify({ message: e.error, type: "error" });
          }
        }
      })();
    }

  }, [
    transactionId, 
    type, 
    _stage, 
    ownership, 
    api, 
    seed,
    books.transactions,
    notify
  ]);

  // If the TX sourceId is changed,
  // then we will need to update our 
  // meta:
  // Using the ID we will fetch the 
  // full entity object, and subsequently
  // the entity's country object. 

  React.useEffect(() => {
    
    // If we're working with income transactions,
    // then only the source ID can be changed. 
    // To be sure we don't have any unnecessary 
    // effects firing we can check to make sure 
    // we are attaching to income transactions.  

    if (sourceId && type === "income") {
      const _sourceEntity = getEntity(sourceId, entities.all);
      if (_sourceEntity && _sourceEntity.countryCode) {
        const _sourceCountry = getCountry(_sourceEntity.countryCode);
        _sourceCountry && setCurrencyCode(_sourceCountry.currency);
      }
    }
  }, [entities.all, sourceId, type]);

  React.useEffect(() => {
    
    // If we're working with expense transactions,
    // then only the destination ID can be changed. 
    // To be sure we don't have any unnecessary 
    // effects firing we can check to make sure 
    // we are attaching to expense transactions.  

    if (destinationId && type === "expense") {
      const _destinationEntity = getEntity(destinationId, entities.all);
      if (_destinationEntity && _destinationEntity.countryCode) {
        const _destinationCountry = getCountry(_destinationEntity.countryCode);
        _destinationCountry && setCurrencyCode(_destinationCountry.currency);
      }
    }
  }, [entities.all, destinationId, type]);

  // TODO 
  // Explain. 

  React.useEffect(() => {
    if (entityEditor.data.currencyCode) {
      setCurrencyCode(entityEditor.data.currencyCode);
    }
  }, [ entityEditor.data.currencyCode, type ]);

  return {

    type,

    data: _transaction,

    validate,

    edit: {
      sourceId: setSourceId,
      destinationId: setDestinationId,
      description: setDescription,
      notes: setNotes,
      currencyCode: setCurrencyCode,
      // gross: setGross,
      // grossLocalised: setGrossLocalised,
      isManuallyLocalised: setIsManuallyLocalised,
      isManuallyCalculated: setIsManuallyCalculated,
      estimateDate: setEstimateDate,
      estimateState: setEstimateState,
      estimateExpiration: setEstimateExpiration,
      estimateExchangeRate: setEstimateExchangeRate,
      invoiceDate: setInvoiceDate,
      invoiceDue: setInvoiceDue,
      invoiceState: setInvoiceState,
      invoiceUrl: setInvoiceUrl,
      invoiceShippingAddressId: setInvoiceShippingAddressId,
      invoiceExchangeRate: setInvoiceExchangeRate,
      paymentState: setPaymentState,
      deferredDate: setDeferredDate,
      deferredReason: setDeferredReason
    },

    delete: async () => {
      if (txId) {
        setIsUpdatingTransaction(true);
        try {
          await api.deleteTransaction(txId);
          books.api.remove(txId);
          setIsUpdatingTransaction(false);
          notify({ type: "success", message: "Deleted your transaction." });
          return true;
        } catch (e) {
          setIsUpdatingTransaction(false);
          if (e instanceof ErrorAPI) {
            notify({ type: "error", message: e.error });
          }
        }
      }
      return false;
    },

    commit: async (overrides = {}) => {

      setDirty();

      const canCommit = validate();
      if (!canCommit) return false;

      let _entity;
      
      try {
        _entity = await entityEditor.commit();
      } catch (e) {
        return false;
      }

      const _transaction = {
        sourceId: typeof _entity === "object" && type === "income" ? _entity.id : sourceId,
        destinationId: typeof _entity === "object" && type === "expense" ? _entity.id : destinationId,
        description,
        notes,
        currencyCode,
        // gross,
        // grossLocalised,
        isManuallyCalculated,
        isManuallyLocalised,
        estimateDate,
        estimateExpiration,
        estimateState,
        invoiceDate,
        invoiceDue,
        invoiceState,
        invoiceUrl,
        invoiceShippingAddressId,
        deferredDate,
        deferredReason,
        id: txId,
        ownedBy,
        invoiceExchangeRate,
        estimateExchangeRate,

        // Doing this because I can't set state 
        // of a single property and then save 
        // sequentially because of setState cycles.

        ...overrides
      };

      try {

        let createdOrUpdatedTransaction: Transaction | undefined = undefined;

        if (!type) return false;

        if (transactionId === "create" && !isVirgin) {
          setIsCreatingTransaction(true);
          const { transaction } = await api.createTransaction(_transaction, type);
          createdOrUpdatedTransaction = transaction;
          setIsCreatingTransaction(false);
          // n.notify({ type: "success", message: "Your transaction was created." });
        }

        else if (transactionId && !isVirgin) {
          setIsUpdatingTransaction(true);
          const { transaction } = await api.updateTransaction(_transaction as unknown as Transaction, type);
          createdOrUpdatedTransaction = transaction;
          setIsUpdatingTransaction(false);
          notify({ type: "success", message: "Your transaction was successfully updated." });
        }

        let _offerings: TransactionOffering[] = offerings;
        let _payments: Payment[] = payments;

        if (createdOrUpdatedTransaction?.id) {
          try {
            
            const __offerings = await transactionOfferingsEditor.commit(createdOrUpdatedTransaction.id);
            if (typeof __offerings === "object") _offerings = __offerings;

            const __payments = await transactionPaymentsEditor.commit(createdOrUpdatedTransaction.id);
            if (typeof __payments === "object") _payments = __payments;

          } catch (e) {
            return false;
          } 
        }

        if (createdOrUpdatedTransaction) {
          books.api.sync({
            ...createdOrUpdatedTransaction,
            offerings: _offerings,
            payments: _payments
          });
        }

        return {
          ...createdOrUpdatedTransaction,
          offerings: _offerings,
          payments: _payments
        } as TransactionExpanded;

      } catch (e) {
        isCreatingTransaction && setIsCreatingTransaction(false);
        isUpdatingTransaction && setIsCreatingTransaction(false);
        if (e instanceof ErrorAPI) {
          notify({ type: "error", message: e.error });
        }
      }

      return false;

    },

    state: {
      isVirgin,
      isWorking: (
        isExchangingAmount ||
        isUpdatingTransaction ||
        isCreatingTransaction
      ),
      isInitialised: (
        typeof estimateState === "string" || 
        typeof invoiceState === "string"
      ),
      paymentState,
      isCreatingNewEntity,
      isCreatingTransaction,
      isUpdatingTransaction,
      ...localisationState
    },

    trigger: {
      createNewEntity: (b) => setIsCreatingNewEntity(b)
    },

    related: {
      entityEditor,
      transactionOfferingsEditor,
      transactionPaymentsEditor,
      shippingAddressEditor
    },

    errors: {
      setDirty,
      validation: validationErrors,
      setValidation: setValidationErrors
    },

    summary

  };

}

export interface ITransactionEditorAPI {
  type?: TransactionType,
  data: InterfaceAsUndefined<Transaction>,
  edit: {
    sourceId: (id: string) => void 
    destinationId: (id: string) => void
    description: (v: string) => void,
    notes: (v: string) => void,
    currencyCode: (code: string) => void,
    // gross: (v: number) => void,
    // grossLocalised: (v: number) => void,
    isManuallyLocalised: (v: boolean) => void,
    isManuallyCalculated: (v: boolean) => void,
    estimateState: (v: EstimateState) => void,
    estimateDate: (v: timestamptz) => void,
    estimateExpiration: (v: timestamptz) => void,
    estimateExchangeRate: (v: number) => void,
    invoiceState: (v?: InvoiceState) => void,
    invoiceDate: (v: timestamptz) => void,
    invoiceDue: (v: timestamptz) => void,
    invoiceUrl: (v: string) => void,
    invoiceShippingAddressId: (v: uuid) => void,
    invoiceExchangeRate: (v: number) => void,
    paymentState: (v?: PaymentState) => void,
    deferredDate: (v?: timestamptz) => void,
    deferredReason: (v?: string) => void    
  },
  delete: () => Promise<boolean>,
  commit: (overrides?: TransactionOrphan) => Promise<TransactionExpanded | false>,
  validate: ValidateFn,
  state: ITransactionEditorState,
  trigger: {
    createNewEntity: (b: boolean) => void
  },
  related: {
    entityEditor: IEntityEditorAPI,
    transactionOfferingsEditor: ITransactionOfferingsEditorAPI,
    transactionPaymentsEditor: ITransactionPaymentsEditorAPI,
    shippingAddressEditor: IAddressEditorAPI
  },
  errors: {
    setDirty: () => void,
    validation?: ObjectFieldValidation,
    setValidation: (v: ObjectFieldValidation) => void
  },
  summary: TransactionSummary
}

export interface ITransactionEditorState extends ILocalisationState {
  isVirgin?: boolean,
  isWorking: boolean,
  paymentState?: PaymentState,
  isInitialised: boolean,
  isCreatingNewEntity: boolean,
  isCreatingTransaction: boolean,
  isUpdatingTransaction: boolean
}