import React from 'react';
import { createClient, SupabaseClient } from '@supabase/supabase-js';

const SupabaseCtx = React.createContext<SupabaseAPI| undefined>(undefined);

const SupabaseCtxProvider: React.FC<ISupabaseCtxProvider> = (props) => {

  const [ supabase, setSupabase ] = React.useState<SupabaseClient>();

  React.useEffect(() => {
    if (props.api && props.anonKey) {
      const _supabase = createClient(props.api, props.anonKey);
      setSupabase(_supabase);
    }
  }, [props.api, props.anonKey]);

  if (supabase) {
    return (
      <SupabaseCtx.Provider 
        value={{ 
          supabase
        }}>
        { props.children }
      </SupabaseCtx.Provider>
    );
  }

  return null;
};

export interface ISupabaseCtxProvider {
  children?: React.ReactNode;
  api: string;
  anonKey: string;
}

const useSupabase = (): SupabaseAPI => {
  const value = React.useContext(SupabaseCtx);
  if (value === undefined) {
    throw new Error('CtxSupabase: `undefined` value was found.');
  }
  return value;
};

export { SupabaseCtxProvider, useSupabase };

export interface SupabaseAPI {
  supabase: SupabaseClient
}