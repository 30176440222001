import React from "react";
import { IInput, InputBase } from "../base";

export const InputCheckbox: React.FC<IInputCheckbox> = (props) => {
  return (
    <InputBase
      { ...props }>
      
      {
        props.mode === "read-only" && 
          <p>{ props.value }</p>
      }

      {
        (props.mode === "edit" || !props.mode) && 
          <input
            type={ "checkbox" }
            checked={ props.value }
            onChange={ e => props.onChange?.(e.target.checked) }
            placeholder={ props.placeholder || "Enter your input here..." }
            onFocus={ props.onFocus }
            onBlur={ props.onBlur } />
      }
    
    </InputBase>
  );
};

export interface IInputCheckbox extends IInput {
  value?: boolean;
};