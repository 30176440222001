import { OrgTemplateOrphan, TransactionDocumentType } from "@bookie/glossary";

export const getDocumentFooter = (
  type: TransactionDocumentType,
  orgTemplate: OrgTemplateOrphan
): string => {
  switch (type) {

    case ("estimate"): {
      return orgTemplate.estimateFooter || "";
    }

    case ("invoice"): {
      return orgTemplate.invoiceFooter || "";
    }

    case ("receipt"): {
      return orgTemplate.receiptFooter || "";
    }

  }
}