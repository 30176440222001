import { Contact, ContactOrphan, ErrorAPI } from "@bookie/glossary"
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel, objectToSnake, stripNullAndUndefinedFromObject } from "@bookie/utils";

export const _createContact = (sb: SupabaseClient): CreateContact => 
  async (contact) => {

    const { data, error } = (
      await sb
        .from("contact")
        .insert([
          stripNullAndUndefinedFromObject(objectToSnake<ContactOrphan>(contact))
        ])
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      contact: objectToCamel<Contact>(data[0])
    };

  }

export type CreateContact = (
  contact: ContactOrphan
) => Promise<CreateContactResponse>

export interface CreateContactResponse {
  contact: Contact;
}