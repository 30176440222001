import React from "react";
import { useSupabase } from "@bookie/supabase";
import { _getTransactions, GetTransactions } from "./_api/_get-transactions";
import { _getTransactionExpanded, GetTransactionExpanded } from "./_api/_get-transaction-expanded";
import { _createTransaction, CreateTransaction } from "./_api/_create-transaction";
import { _createTransactionExpanded, CreateTransactionExpanded } from "./_api/_create-transaction-expanded";
import { _updateTransaction, UpdateTransaction } from "./_api/_update-transaction";
import { _updateTransactionExpanded, UpdateTransactionExpanded } from "./_api/_update-transaction-expanded";
import { _deleteTransaction, DeleteTransaction } from "./_api/_delete-transaction";
import { _createTransactionOfferings, CreateTransactionOfferings } from "./_api/_create-transaction-offerings";
import { _deleteTransactionOfferings, DeleteTransactionOfferings } from "./_api/_delete-transaction-offerings";
import { _updateTransactionOfferings, UpdateTransactionOfferings } from "./_api/_update-transaction-offerings";
import { _createTransactions, CreateTransactions } from "./_api/_create-transactions";
import { _createTransactionExpandedRepeated, CreateTransactionExpandedRepeated } from "./_api/_create-transaction-expanded-repeated";
import { _createTransactionsOfferings, CreateTransactionsOfferings } from "./_api/_create-transactions-offerings";
import { _createTransactionPayments, CreateTransactionPayments } from "./_api/_create-transaction-payments";
import { _updatePayments, UpdatePayments } from "./_api/_update-payment";
import { _deleteTransactionPayments, DeleteTransactionPayments } from "./_api/_delete-transaction-payments";
import { _getTransactionsExpanded, GetTransactionsExpanded } from "./_api/_get-transactions-expanded";

export const useBookkeepingAPI = (): BookkeepingAPI => {

  const { supabase } = useSupabase();

  return React.useMemo(() => ({
    getTransactions: _getTransactions(supabase),
    getTransactionExpanded: _getTransactionExpanded(supabase),
    getTransactionsExpanded: _getTransactionsExpanded(supabase),
    createTransaction: _createTransaction(supabase),
    createTransactions: _createTransactions(supabase),
    createTransactionExpanded: _createTransactionExpanded(supabase),
    createTransactionExpandedRepeated: _createTransactionExpandedRepeated(supabase),
    updateTransaction: _updateTransaction(supabase),
    updateTransactionExpanded: _updateTransactionExpanded(supabase),
    deleteTransaction: _deleteTransaction(supabase),
    createTransactionOfferings: _createTransactionOfferings(supabase),
    createTransactionsOfferings: _createTransactionsOfferings(supabase),
    updateTransactionOfferings: _updateTransactionOfferings(supabase),
    deleteTransactionOfferings: _deleteTransactionOfferings(supabase),
    createTransactionPayments: _createTransactionPayments(supabase),
    updatePayments: _updatePayments(supabase),
    deleteTransactionPayments: _deleteTransactionPayments(supabase)
  }), [ supabase ]);

}

export interface BookkeepingAPI {
  getTransactions: GetTransactions,
  getTransactionExpanded: GetTransactionExpanded,
  getTransactionsExpanded: GetTransactionsExpanded,
  createTransaction: CreateTransaction,
  createTransactions: CreateTransactions,
  createTransactionExpanded: CreateTransactionExpanded,
  createTransactionExpandedRepeated: CreateTransactionExpandedRepeated,
  updateTransaction: UpdateTransaction,
  updateTransactionExpanded: UpdateTransactionExpanded,
  deleteTransaction: DeleteTransaction,
  createTransactionOfferings: CreateTransactionOfferings,
  createTransactionsOfferings: CreateTransactionsOfferings,
  updateTransactionOfferings: UpdateTransactionOfferings,
  deleteTransactionOfferings: DeleteTransactionOfferings,
  createTransactionPayments: CreateTransactionPayments,
  updatePayments: UpdatePayments,
  deleteTransactionPayments: DeleteTransactionPayments
}