import React from "react";
import css from "./index.module.css";
import cx from "classnames";
import { Notification as NotificationInterface, useNotification } from "./ctx";
// import { IconDelete } from "../icon";

export * from "./ctx"

export const Notification: React.FC<INotification> = (props) => {

  const { variants } = useNotification();

  const [ timeLeft, setTimeLeft ] = React.useState<number>(props.duration ?? 0);

  React.useEffect(() => {
    if (props.hasTimer) {
      const endTime = Date.now() + (props.duration ?? 0);
      const interval = setInterval(() => {
        const newTimeLeft = endTime - Date.now();
        if (newTimeLeft <= 0) {
          props.dismiss(props.id);
          clearInterval(interval);
        } else {
          setTimeLeft(newTimeLeft);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [props, props.hasTimer]);

  const variant = variants.find((v) => v.type === props.type);

  return (
    <div
      onClick={() => props.dismiss(props.id)}
      className={cx(
        "hny-notification",
        css.notification,
        "x align-x-between align-y-center border clickable space-lines-children",
        variant?.class
      )}
    >
      <div className={cx(css.meta, "x align-y-center")}>
        {variant?.icon && (
          <div className={css.meta_icon}>
            {React.cloneElement(variant.icon, { size: 22 })}
          </div>
        )}

        {props.message && (
          <p className={css.notification_description}>{props.message}</p>
        )}
      </div>

      <div 
        style={{ flexShrink: 0 }}
        className="x align-y-center space-lines-children">
        
        {
          props.hasTimer && (
            <span className={css.time_left}>
              { Math.ceil(timeLeft / 1000) }s
            </span>
          )
        }

        {/* <div
          onClick={() => props.dismiss(props.id)}
          style={{ flexShrink: 0 }}
          className={"clickable"}
        >
          <IconDelete size={ 20 } />
        </div> */}

      </div>
    </div>
  );
};

export interface INotification extends NotificationInterface {
  dismiss: (id: number) => void;
}
