import React from "react";
import { IAddressEditorAPI, useAddressEditor } from "../../use/use-address-editor";
import { InputText, Button, IconRightAccordian } from "@bookie/components";
import { SelectCountry } from "../../components/select-country";
import { uuid, Country } from "@bookie/glossary";
import cx from "classnames";

export const AddressEditor: React.FC<IAddressEditor> = (props) => {

  const editor = useAddressEditor(
    props.addressId, 
    undefined, 
    props.editor
  );

  return (
    <div 
      className={ "y fill" }>

      {
        !props.hideToolbar && 
          <div 
            style={{ borderRadius: "0" }}
            className={ "x surface border-b space-blocks-frame-lr space-lines-frame-tb align-x-between" }>
            
            {
              !props.editor && 
                <>

                  <div 
                    className={ "x" }>
                    <Button
                      label={ props.addressId === "create" ? "Create" : "Update" }
                      size={ "slim" }
                      onClick={ async () => {
                        const result = await editor.commit();
                        result && props.onFinish?.();
                      } } />
                    <Button
                      label={ "Cancel" }
                      size={ "slim" }
                      variant={ "text" }
                      onClick={ async () => {
                        props.onFinish?.();
                      } } />
                  </div>
                  
                  {
                    (editor.data.id && !props.hideDelete) && 
                      <Button
                        label={ "Delete" }
                        size={ "slim" }
                        variant={ "dangerous" }
                        surface={ "surface-negative" }
                        isDangerous={ true }
                        onClick={ async () => {
                          const result = await editor.delete();
                          result && props.onFinish?.();
                        } } /> 
                  }   
                
                </>
            }

            {
              props.editor && 
                <Button
                  icon={ <IconRightAccordian /> }
                  variant={ "inline" }
                  onClick={ () => props.onFinish?.() } />
            }

          </div>
      }
    
      <div 
        style={{ height: props.isInline ? "inherit" : "0" }}
        className={ cx(
          "y surface fill fill-inputs",
          { "scrollable space-blocks-frame space-blocks-children": !props.isInline },
          { "space-lines-children": props.isInline }
        ) }>
        
        <InputText
          type={ "text" }
          label={ "Label or Name" }
          className={ "fill-x" }
          value={ editor.data.label }
          onChange={ v => editor.edit.label(v as string) }
          error={ editor.errors.validation?.label } />

        <InputText
          type={ "text" }
          label={ "Street Name and Number" }
          description={ "The number of your building and the street name" }
          className={ "fill-x" }
          value={ editor.data.line1 }
          onChange={ v => editor.edit.line1(v as string) }
          error={ editor.errors.validation?.line1 } />
        
        <InputText
          type={ "text" }
          label={ "Apartment" }
          className={ "fill-x" }
          value={ editor.data.line2 }
          onChange={ v => editor.edit.line2(v as string) }
          error={ editor.errors.validation?.line2 } />
        
        <InputText
          type={ "text" }
          label={ "City" }
          className={ "fill-x" }
          value={ editor.data.city }
          onChange={ v => editor.edit.city(v as string) }
          error={ editor.errors.validation?.city } />
        
        <InputText
          type={ "text" }
          label={ "Town" }
          className={ "fill-x" }
          value={ editor.data.town }
          onChange={ v => editor.edit.town(v as string) }
          error={ editor.errors.validation?.town } />
        
        <InputText
          type={ "text" }
          label={ "Postcode" }
          className={ "fill-x" }
          value={ editor.data.postalCode }
          onChange={ v => editor.edit.postalCode(v as string) }
          error={ editor.errors.validation?.postalCode } />
        
        <SelectCountry
          label={ "Country" }
          selectedCountryCode={ editor.data.country }
          onSelect={ (c: Country) => editor.edit.country(c.alpha3) }
          error={ editor.errors.validation?.country } />
      
      </div>

    </div>
  )

}

export interface IAddressEditor {
  addressId?: uuid | "create"
  editor?: IAddressEditorAPI
  onFinish?: () => void
  hideDelete?: boolean
  hideToolbar?: boolean
  isInline?: boolean
}