import { ObjectFieldValidation, OFFERING_TYPES, TransactionOffering, TransactionOfferingOrphan, TransactionType } from "@bookie/glossary";
import { Amount, Button, IconDelete, IconDescription, IconDownAccordian, IconName, IconOfferingCost, IconOfferingPrice, IconQuantity, IconSKU, IconTax, IconTaxType, IconUpAccordian, IconValidation, IconWarning, InputCheckbox, InputMoney, InputNumber, InputSelect, InputText, Tooltip } from "@bookie/components";
import React from "react";
import cx from "classnames";
import { useCurrency } from "@hny_/currency";
import { useIdentity } from "@bookie/module-identity";
import { getLocaleFromCountryCode, SelectOfferingType } from "@bookie/module-core";
import { summariseTransactionOffering } from "../../fns/summarise-transaction-offering";

export const TransactionOfferingEditor: React.FC<ITransactionOfferingEditor> = (props) => {

  const currency = useCurrency();
  const { ownership } = useIdentity();

  const hasValidation = Object.keys(props.errors || {}).length > 0;

  const [ addCost, setAddCost ] = React.useState<boolean>(typeof props.offering.grossCost === "string" || (props.offering.grossCost || 0) > 0);

  if (!props.isSelected) {
    return (
      <div
        onClick={ props.onSelect }
        style={{ cursor: "pointer" }}
        className={ cx(
          "x apply-fg radius border-t fill-x align-x-between hvr-icon-down",
          `${ props.surfaceDisplay || "surface-aside" }`
        ) }>
        
        <div 
          className={ "x space-inline-children align-y-center" }>
          
          <Button
              variant={ "text" }
              className={ "hvr-icon" }
              onClick={ e => {
                e?.preventDefault();
                props.onDelete?.();
              } }
              icon={ <IconDownAccordian /> }
              iconSize={ 14 }
              size={ "slim" } />
          
          <p><b>{ props.offering.quantity + " x " }</b></p>

          <p>{ props.offering.name }</p>

          {
            hasValidation && 
              <Tooltip
                className={ "surface-negative" }
                message={ "There are some errors in here" }
                base={ <IconValidation size={ 18 } /> } />
          }

        </div>
        
        <div
          className={ "x align-y-center" }>
          
          <div 
            className={ "space-inlinez-frame" }>
            <p>
              <b>
                { 
                  currency.toHumanReadable(
                    summariseTransactionOffering(
                      props.offering.grossSale,
                      props.offering.quantity,
                      props.offering.tax,
                      props.offering.taxType
                    )
                  ) 
                }
              </b>
            </p>
          </div>
          
          <div 
            className={ cx(
              "border-l",
              `${ props.surfaceDisplay || "surface" }`
            ) }>
            <Button
              variant={ "text" }
              className={ "hvr-pulse" }
              onClick={ e => {
                e?.preventDefault();
                props.onDelete?.();
              } }
              icon={ 
                <Tooltip 
                  message={ "Delete" }
                  base={ <IconDelete size={ 20 } /> } />
              }
              size={ "slim" } />
          </div>
        
        </div>

      </div>
    )
  };

  const offeringType = OFFERING_TYPES.find(ot => ot.id === props.offering.type)
  
  return (
    <div
      className={ props.isSelected ? "apply-selected" : "" }>
      <div
        style={{ position: "relative" }}
        className={ cx(
          "y surface-aside apply-bg apply-fg radius border fill-x"
        ) }>
        
        <div 
          onClick={ e => {
            e?.preventDefault();
            props.onMinimise?.();
          } }
          style={{ cursor: "pointer" }}
          className={ "x surface border-b space-inline-frame-tb align-x-between" }>
          <Button
            variant={ "text" }
            onClick={ e => {
              e?.preventDefault();
              props.onMinimise?.();
            } }
            icon={
              <Tooltip 
                message={ "Minimise" }
                base={ <IconUpAccordian size={ 18 } /> } />
            }
            size={ "slim" } />
          {/* <Button
            variant={ "text" }
            onClick={ e => {
              e?.preventDefault();
              props.onDelete?.();
            } }
            icon={ 
              <Tooltip 
                message={ "Delete" }
                base={ <IconDelete size={ 22 } /> } />
            }
            size={ "slim" } /> */}
        </div>

        {
          props.transactionType !== "expense" && 
            <div 
              style={{ paddingTop: "3px", paddingBottom: "3px" }}
              className={ "x space-inlinez-frame-lr border-b align-x-end surface align-y-center space-inline-children" }>
              <Tooltip
                style={{
                  whiteSpace: "wrap",
                  width: "400px"
                }}
                position={ "right" }
                base={ <IconWarning size={ 18 } /> }
                message={ 
                  `Saving a copy to your Inventory will **not affect the line item in this transaction**, but allow you to easily add common Products or Services to future transactions.

                  It will also be saved to your Inventory with the **currency of this transaction**. 
                  
                  If you want to create it in another currency, or just make a change to it, **navigate to the Products & Services page** from the top bar.
                  `
                } />
              <InputCheckbox
                size={ "small" }
                value={ props.inventory }
                label={ "Create a copy of this line item in your Inventory" }
                labelPosition={ "left" }
                onChange={ v => {
                  if (v === false) {
                    props.removeFromInventory?.();
                  } else if (v === true) {
                    props.addToInventory?.();
                  }
                }} />
            </div>
        }
        
        <div
          className="x space-lines-frame space-cubes-children">
          
          <SelectOfferingType
            label={ "Type" }
            description={ `The kind of thing you are ${ props.transactionType === "income" ? "selling" : "buying" } here` }
            selectedOfferingType={ props.offering.type }
            onSelect={ v => props.onEdit?.("type", v) }
            error={ props.errors?.["type"] } />
          
          <InputText
            label={ "Name" }
            icon={ <IconName /> }
            className={ "fill-inputs" }
            classNameForContainer={ "y align-y-end fill-y" }
            description={ `A simple identifier or label for this ${ offeringType?.label.toLowerCase() }` }
            placeholder={ `The name of your ${ offeringType?.label }` }
            value={ props.offering.name }
            onChange={ v => props.onEdit?.("name", v) }
            error={ props.errors?.["name"] } />
          
          {
            props.offering.type !== "service" && 
              <InputText
                label={ "SKU" }
                icon={ <IconSKU /> }
                classNameForContainer={ "y align-y-end fill-y" }
                description={ "Optional" }
                placeholder={ "ABC-12345-S-B" }
                value={ props.offering.sku }
                onChange={ v => props.onEdit?.("sku", v) } />
          }

        </div>

        <div 
          className={ "x space-lines-frame fill-x border-t" }>
          
          <InputText
            label={ "Description" }
            icon={ <IconDescription /> }
            className={ "fill-inputs" }
            description={ "Optional" }
            placeholder={ `Any additional information or specifications relevant to this ${ offeringType?.label.toLowerCase() }`}
            value={ props.offering.description }
            onChange={ v => props.onEdit?.("description", v) } />

        </div>

        <div 
          className={ "y space-lines-frame border-t space-lines-children" }>
          
          <div
            className={ "x space-cubes-children" }>
            
            {
              (
                props.transactionType === "income" && 
                props.offering.type !== "service" && 
                addCost 
              ) && 
                <InputMoney
                  style={{ maxWidth: "30%" }}
                  classNameForContainer={ "y align-y-end fill-y" }
                  label={ "Cost Price" }
                  icon={ <IconOfferingCost /> }
                  description={ `Optional. The cost price of this ${ offeringType?.label.toLowerCase() }. This will only be visible to you.` }
                  placeholder={ "10" }
                  // size={ "small" }
                  value={ props.offering.grossCost }
                  onChange={ v => props.onEdit?.("grossCost", v) }
                  toApplicationReadable={ currency.toApplicationReadable }
                  toDataReadable={ currency.toDataReadable }
                  toHumanReadable={ currency.toHumanReadable }
                  config={{
                    currencyCode: props.currencyCode,
                    locale: getLocaleFromCountryCode(ownership.current?.entity?.countryCode)
                  }}
                  error={ props.errors?.["grossCost"] } />
            }

            <InputMoney
              style={{ maxWidth: "40%" }}
              label={ 
                props.transactionType === "income"
                  ? (
                    (props.offering.type === "digital_product" || props.offering.type === "physical_product")
                      ? "Sell Price"
                      : "Rate"
                  )
                  : "Purchase Price"
              }
              description={
                props.transactionType === "income"
                  ? (
                    (props.offering.type === "service")
                      ? `The price per hour for this ${ offeringType?.label.toLowerCase() }`
                      : (
                        addCost
                          ? `The sell price per unit of this ${ offeringType?.label.toLowerCase() }. If you are passing on an expense to your client, you can set this price to the cost price or include a profit on top.`
                          : `The sell price per unit of this ${ offeringType?.label.toLowerCase() }`
                      )
                  )
                  : "Purchase Price"
              }
              classNameForContainer={ "y align-y-end fill-y" }
              icon={ <IconOfferingPrice /> }
              placeholder={ "10" }
              value={ props.offering.grossSale }
              onChange={ v => props.onEdit?.("grossSale", v) }
              toApplicationReadable={ currency.toApplicationReadable }
              toDataReadable={ currency.toDataReadable }
              toHumanReadable={ currency.toHumanReadable }
              config={{
                currencyCode: props.currencyCode,
                locale: getLocaleFromCountryCode(ownership.current?.entity?.countryCode)
              }}
              error={ props.errors?.["grossSale"] } />
            
            <InputNumber
              label={ props.offering.type === "service" ? "HRS" : "QTY" }
              classNameForContainer={ "y align-y-end fill-y" }
              icon={ <IconQuantity /> }
              description={
                props.offering.type === "service"
                  ? "Total number of hours"
                  : "Total quantity of units"
              }
              placeholder={ "5" }
              value={ props.offering.quantity }
              onChange={ v => props.onEdit?.("quantity", v) }
              error={ props.errors?.["quantity"] } />

          </div>

          {
            props.transactionType === "income" &&
              <div 
                className={ "x" }>
                {
                  !addCost &&
                    <Button
                      variant={ "inline" }
                      size={ "slim" }
                      label={ "Add a Cost or as an Expense" }
                      style={{ padding: "0" }}
                      onClick={ () => setAddCost(true) } />
                }
                {
                  addCost &&
                    <Button
                      variant={ "inline" }
                      size={ "slim" }
                      label={ "Remove Cost" }
                      style={{ padding: "0" }}
                      onClick={ () => {
                        props.onEdit?.("grossCost", 0);
                        setAddCost(false);
                      } } />
                }
              </div>
          }
        
        </div>

        <div 
          className={ "x space-lines-frame space-cubes-children border-t" }>

          <InputSelect
            label={ "Tax Type" }
            icon={ <IconTaxType /> }
            options={[
              { id: "additive", label: "Calculate the % off of the final price" },
              { id: "inclusive", label: "Include the tax in the final price" },
            ]}
            matchKey={ "id" }
            formatLabel={ x => x.label }
            value={ [
              { id: "additive", label: "Calculate the % off of the final price" },
              { id: "inclusive", label: "Include the tax in the final price" },
            ].find(tt => tt.id === props.offering.taxType) }
            onSelect={ x => props.onEdit?.("taxType", x.id) }
            description={ "How do you want to apply this tax?" }
            error={ props.errors?.["taxType"] } />
          
          <InputNumber
            label={ "Tax" }
            icon={ <IconTax /> }
            classNameForContainer={ "y align-y-end fill-y" }
            placeholder={ "18%" }
            value={ props.offering.tax }
            onChange={ v => props.onEdit?.("tax", v) } />

        </div>

        <div
          className={ "x space-inlinez-frame-tb space-lines-frame-lr align-y-center space-inline-children border-t surface-alt" }>
          
          {/* <IconOfferingPrice /> */}

          <Amount
            localisedAmount={
              summariseTransactionOffering(
                props.offering.grossSale,
                props.offering.quantity,
                props.offering.tax,
                props.offering.taxType
              )
            }
            currency={ props.currencyCode }
            localisedCurrency={ props.currencyCode || "" }
            locale={ getLocaleFromCountryCode(ownership.current?.entity?.countryCode) || "" } />

        </div> 

      </div>
    </div>
  );

}

export interface ITransactionOfferingEditor {
  surfaceDisplay?: string
  currencyCode?: string
  offering: TransactionOfferingOrphan
  inventory?: boolean 
  addToInventory?: () => void
  removeFromInventory?: () => void
  transactionType?: TransactionType
  isSelected?: boolean
  onSelect?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEdit?: (field: keyof TransactionOffering, value: any) => void
  onDelete?: () => void
  errors?: ObjectFieldValidation
  onMinimise?: () => void
  isUsingAssist?: boolean
}