import { TransactionExpanded, TransactionFilterPeriods } from "@bookie/glossary";
import { getDaysOfThisMonth, getMonthsUntilThisMonth } from "@bookie/utils";
import moment from "moment";

export const inferSegments = (
  key: TransactionFilterPeriods
): { [key: string]: TransactionExpanded[] } => {

  const today = moment();

  switch (key) {

    case "this_month": {

      // Based on today's month, we can 
      // get an array of all the days of this month. 

      const days = getDaysOfThisMonth(today);

      return days.reduce(
        (segments, day) => ({
          ...segments,
          [`${ day }`]: []
        }),
        {}
      );

    }

    case "this_year": {

      // Based on today's year, we can 
      // get an array of all the months 
      // in the year so far. 

      const months = getMonthsUntilThisMonth(today) || [];

      return months.reduce(
        (segments, month) => ({
          ...segments,
          [`${ month }`]: []
        }),
        {}
      );

    }

  }

  return {};

}

export interface InferredSegments {
  segments: string[]
}