import React from "react";
import css from "./index.module.css";
import cx from "classnames";
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";
import { Stage } from "./stage";
import { EstimateToolbar } from "../estimate-toolbar";
import { InvoiceToolbar } from "../invoice-toolbar";
import { PaymentToolbar } from "../payment-toolbar";
import { IconEstimate, IconInvoice, IconPayment, IconSuccessTick } from "@bookie/components";
import { TransactionSummary } from "../transaction-summary";

export const TransactionStage: React.FC<ITransactionStage> = (props) => {

  const [ selectedStage, setSelectedStage ] = React.useState<"estimate" | "invoice" | "payment">();

  React.useEffect(() => {

    if (
      props.editor?.data.estimateState === "draft" ||
      props.editor?.data.estimateState === "submitted" ||
      props.editor?.data.estimateState === "rejected"
    ) {
      setSelectedStage("estimate");
    } else {
      if (
        props.editor?.data.invoiceState === "draft" ||
        props.editor?.data.invoiceState === "submitted" ||
        props.editor?.data.invoiceState === "rejected"
      ) {
        setSelectedStage("invoice");
      } 
      else {
        setSelectedStage("payment");
      }
    }
  }, [
    props.editor?.data.estimateState,
    props.editor?.data.invoiceState
  ]);

  return (
    <div
      className={ cx(
        "y",
        css.transactionStage
      ) }>  

      {
        props.editor?.state.isInitialised && 
          <div
            style={{ gap: "2px" }}
            className={ "x surface space-lines-frame-lr space-lines-frame-tb" }>
            
            {
              (props.editor?.data.estimateState !== "n/a" && props.editor?.type === "income") &&
                <Stage 
                  title={ "Estimate" }
                  icon={ <IconEstimate /> }
                  onClick={ () => setSelectedStage("estimate") }
                  isSelected={ selectedStage === "estimate" }
                  isApproved={ props.editor.data.estimateState === "approved" }
                  isRejected={ props.editor.data.estimateState === "rejected" }
                  isActive={ props.editor.data.estimateState === "draft" }
                  isPending={ props.editor.data.estimateState === "submitted" }
                  renderNearTitle={
                    <div
                      className={ css.statusLabel }>
                      {
                        props.editor.data.estimateState !== "approved" && 
                          <p>{ props.editor.data.estimateState }</p>
                      }
                      {
                        props.editor.data.estimateState === "approved" && 
                          <IconSuccessTick weight={ "fill" } />
                      }
                    </div>
                  }
                  renderActions={
                    <EstimateToolbar 
                      editor={ props.editor }
                      type={ props.editor?.type } /> 
                  } />
            }

            {
              props.editor?.type === "income" && 
                <Stage 
                  title={ "Invoice" }
                  icon={ <IconInvoice /> }
                  onClick={ () => setSelectedStage("invoice") }
                  isSelected={ selectedStage === "invoice" }
                  isInactive={ props.editor.data.estimateState !== "approved" }
                  isApproved={ props.editor.data.invoiceState === "submitted" }
                  isRejected={ props.editor.data.invoiceState === "rejected" }
                  isActive={ props.editor.data.invoiceState === "draft" }
                  renderNearTitle={
                    <div
                      className={ css.statusLabel }>
                      {
                        props.editor.data.invoiceState !== "submitted" && 
                          <p>{ props.editor.data.invoiceState }</p>
                      }
                      {
                        props.editor.data.invoiceState === "submitted" && 
                          <IconSuccessTick weight={ "fill" } />
                      }
                    </div>
                  }
                  renderActions={
                    <InvoiceToolbar
                      editor={ props.editor }
                      type={ props.editor?.type } />
                  } />
            }
            
            <Stage 
              title={ "Payment" }
              icon={ <IconPayment /> }
              onClick={ () => setSelectedStage("payment") }
              isSelected={ selectedStage === "payment" }
              isInactive={ props.editor.data.invoiceState !== "submitted" }
              isApproved={ props.editor.state.paymentState === "paid_in_full" }
              isActive={
                props.editor.state.paymentState === "due" ||
                props.editor.state.paymentState === "overdue" ||
                props.editor.state.paymentState === "paid_in_part"
              }
              isRejected={ props.editor.state.paymentState === "bad_debt" }
              renderNearTitle={
                <div
                  className={ css.statusLabel }>
                  {
                    props.editor.state.paymentState !== "paid_in_full" && 
                      <p>{ props.editor.state.paymentState }</p>
                  }
                  {
                    props.editor.state.paymentState === "paid_in_full" && 
                      <IconSuccessTick weight={ "fill" } />
                  }
                </div>
              }
              renderActions={
                <PaymentToolbar
                  editor={ props.editor }
                  type={ props.editor?.type } />  
              } />

          </div>
      }

      <TransactionSummary
        editor={ props.editor } />

    </div>
  );
}

export interface ITransactionStage {
  editor?: ITransactionEditorAPI
}