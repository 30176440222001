/* eslint-disable react-hooks/rules-of-hooks */
import { Contact, ContactOrphan, ErrorAPI, ErrorValidation, InterfaceAsUndefined, ObjectFieldValidation, uuid } from "@bookie/glossary";
import React from "react";
import { useCoreAPI } from "../use-core-api";
import { useIdentity } from "@bookie/module-identity";
import { useValidator } from "@bookie/utils";
import { validateContact } from "../fns/validate-contact";
import { getCountry } from "../fns/get-country";
import { useNotification } from "@bookie/components";

export const useContactEditor = (
  contactId?: uuid | "create",
  contact?: Contact,
  contactEditor?: IContactEditorAPI,
  config?: {
    countryCode?: string
  }
): IContactEditorAPI => {

  if (contactEditor) {
    return contactEditor;
  }
  

  const n = useNotification();
  const api = useCoreAPI();
  const { ownership } = useIdentity();

  // Contact 
  // these properties are required
  // to create a new Contact

  const [ nameGiven, setNameGiven ] = React.useState<string>();
  const [ nameFamily, setNameFamily ] = React.useState<string>();
  const [ email, setEmail ] = React.useState<string>();
  const [ phoneNumber, setPhoneNumber ] = React.useState<number>();
  const [ phoneCountryCode, setPhoneCountryCode ] = React.useState<string>(getCountry(config?.countryCode || ownership.current?.entity?.countryCode)?.phone || "");
  
  const [ id, setId ] = React.useState<uuid>();
  const [ ownedBy, setOwnedBy ] = React.useState<uuid>();

  const [ isFetchingContact, setIsFetchingContact ] = React.useState<boolean>(false);
  const [ isCreatingContact, setIsCreatingContact ] = React.useState<boolean>(false);
  const [ isDeletingContact, setIsDeletingContact ] = React.useState<boolean>(false);
  const [ isUpdatingContact, setIsUpdatingContact ] = React.useState<boolean>(false);  

  const [ isReady, setIsReady ] = React.useState<boolean>(false);

  React.useEffect(() => {

    // const _country = getCountry(config?.countryCode || ownership.current?.entity?.countryCode);

    if (contactId === "create") {
      
      // setPhoneCountryCode(_country?.phone);
      setTimeout(() => setIsReady(true), 1);
    }

    else if (typeof contactId === "string") {
      (async () => {

        let _contact = contact;

        if (!_contact) {
          setIsFetchingContact(true);
          const { contact } = await api.getContact(contactId);
          _contact = contact;
          setIsFetchingContact(false);
        }

        setNameGiven(_contact?.nameGiven);
        setNameFamily(_contact?.nameFamily);
        setEmail(_contact?.email);
        setPhoneNumber(_contact?.phoneNumber);
        _contact?.phoneCountryCode && setPhoneCountryCode(_contact.phoneCountryCode);
        setId(_contact?.id);
        setOwnedBy(_contact?.ownedBy);

        setIsReady(true);

      })();
    }

  }, [contact, contactId, api, ownership, contactEditor, config?.countryCode]);

  const _contact = {
    nameGiven,
    nameFamily,
    email,
    phoneNumber,
    phoneCountryCode,
    id,
    ownedBy
  };

  const {
    validate,
    isVirgin,
    setDirty,
    validationErrors,
    setValidationErrors
  } = useValidator(
    (_c) => {
      const _validation = validateContact(_c as Contact);
      setValidationErrors(_validation.errors);
      return _validation.isValid;
    },
    _contact,
    undefined,
    isReady
  );

  return {

    data: _contact,

    edit: {
      nameGiven: setNameGiven,
      nameFamily: setNameFamily,
      email: setEmail,
      phoneNumber: setPhoneNumber,
      phoneCountryCode: setPhoneCountryCode
    },

    state: {
      isWorking: (
        isCreatingContact || 
        isUpdatingContact ||
        isDeletingContact ||
        isFetchingContact
      ),
      isCreatingContact,
      isFetchingContact,
      isDeletingContact,
      isUpdatingContact,
      isVirgin
    },

    validate,

    delete: async () => {
      if (contactId) {
        try {
          setIsDeletingContact(true);
          const { contact } = await api.deleteContact(contactId);
          n.notify({ type: "success", message: `Deleted ${ contact.nameGiven || 'your contact' }` });
          setIsDeletingContact(false);
          return true;
        } catch (e) {
          setIsDeletingContact(false);
          if (e instanceof ErrorAPI) {
            n.notify({ type: "error", message: e.error });
          }
        }
      }
      return false;
    },

    commit: async () => {

      setDirty();

      const canCommit = validate();
      if (!canCommit) throw new ErrorValidation({ name: "contact" });

      try {
        
        if (contactId === "create" && !isVirgin) {
          setIsCreatingContact(true);
          const { contact } = await api.createContact(_contact as ContactOrphan);
          n.notify({ type: "success", message: `Created ${ contact.nameGiven + " " + contact.nameFamily }` });
          setIsCreatingContact(false);
          return contact;
        }
  
        else if (contactId && !isVirgin) {
          setIsUpdatingContact(true);
          const { contact } = await api.updateContact(_contact as Contact);
          n.notify({ type: "success", message: `Updated ${ contact.nameGiven + " " + contact.nameFamily }` });
          setIsUpdatingContact(false);
          return contact;
        }

      } catch (e) {
        setIsCreatingContact(false);
        if (e instanceof ErrorAPI) {
          n.notify({ type: "error", message: e.error });
        }
      }

      return false;
    },

    errors: {
      setDirty,
      validation: validationErrors,
      setValidation: setValidationErrors
    }

  }

}

export interface IContactEditorAPI {
  data: InterfaceAsUndefined<Contact>,
  edit: {
    nameGiven: (v: string) => void,
    nameFamily: (v: string) => void,
    email: (v: string) => void,
    phoneNumber: (v: number) => void,
    phoneCountryCode: (v: string) => void
  },
  state: IContactEditorState,
  validate: () => boolean,
  commit: () => Promise<Contact | false>,
  delete: () => Promise<boolean>,
  errors: {
    setDirty: () => void,
    validation?: ObjectFieldValidation,
    setValidation: (v: ObjectFieldValidation) => void 
  }
}

export interface IContactEditorState {
  isWorking: boolean,
  isVirgin?: boolean,
  isFetchingContact: boolean,
  isCreatingContact: boolean,
  isUpdatingContact: boolean,
  isDeletingContact: boolean
}