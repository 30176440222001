import { Button, IconApprove, IconReject } from "@bookie/components";
import React from "react";
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";
import { TransactionType } from "@bookie/glossary";
import { SendEstimate } from "../send-estimate";

export const EstimateToolbar: React.FC<IEstimateToolbar> = (props) => {
  return (
    <div 
      className={ "x space-lines-frame-lr space-inlinez-frame-tb align-x-center align-y-center surface border fill-y" }>

      <div
        className={ "y align-x-center space-inline-children" }>
        
        {
          props.editor?.data.estimateState === "draft" && 
            <SendEstimate 
              editor={ props.editor } />
        }

        {
          props.editor?.data.estimateState === "draft" && 
            <Button
              variant={ "text" }
              size={ "slim" }
              label={ "Mark as Sent" }
              onClick={ () => {
                if (props.editor?.data && props.type) {
                  
                  props.editor.errors.setDirty();
                  const isValid = props.editor.validate(true);

                  if (props.editor.related.transactionOfferingsEditor.all.length === 0) {
                    alert("You need to add at least one Product or Service first");
                    return;
                  }

                  if (isValid) {
                    props.editor?.edit.estimateState("submitted");
                  }

                }
              } } />
        }

        {
          (
            props.editor?.data.estimateState === "submitted"
          ) &&
            <div 
              className={ "x fill-x space-inline-children" }>

              <Button
                size={ "slim" }
                icon={ <IconApprove /> }
                surface={ "surface-primary-positive" }
                label={ "Approve" }
                onClick={ () => {
                  props.editor?.edit.estimateState("approved");
                  props.editor?.edit.invoiceState("draft");
                } } />

              <Button
                size={ "slim" }
                icon={ <IconReject /> }
                surface={ "surface-primary-negative" }
                label={ "Reject" }
                onClick={ () => {
                  props.editor?.edit.estimateState("rejected");
                  props.editor?.edit.invoiceState(undefined);
                } } />

            </div>
        }

        {
          (
            props.editor?.data.estimateState === "approved" ||
            props.editor?.data.estimateState === "rejected"
          ) && 
            <SendEstimate
              editor={ props.editor }
              label={ "Open Estimate PDF" }
              justOpen={ true } />
        }

        {
          (
            props.editor?.data.estimateState === "submitted" ||
            props.editor?.data.estimateState === "approved" ||
            props.editor?.data.estimateState === "rejected"
          ) && 
            <Button
              size={ "slim" }
              label={ "Re-mark as draft" }
              variant={ "text" }
              onClick={ () => {
                props.editor?.edit.estimateState("draft");
                props.editor?.edit.invoiceState(undefined);
                props.editor?.edit.paymentState(undefined);
              } } />
        }
        
      </div>
      
    </div>
  );
}

export interface IEstimateToolbar {
  editor?: ITransactionEditorAPI
  type?: TransactionType
}