import React from "react";
import { FilterByPeriod } from "../filter-by-period";
import { FilterByEntity } from "../filter-by-entity";
import { SegmentTransactions } from "../segment-transactions";
import { useBookkeeping } from "../../use-bookkeeping";
import cx from "classnames";
import { TransactionType } from "@bookie/glossary";

export const TransactionFilter: React.FC<ITransactionFilter> = (props) => {

  const { segment } = useBookkeeping();

  return (
    <div
      style={{ height: "80px", zIndex: 10 }}
      className={ "x fill-x surface border-b align-x-between shadow-bottom" }>
      
      <div
        className={ "x" }>
      
        <div
          className={ "border-r space-lines-frame-lr surface" }>
        </div>
        
        {
          (
            typeof props.filterByPeriod === "undefined" || 
            props.filterByPeriod === true
          ) &&
          <div 
            className={ "x space-inline-children space-lines-frame-lr space-inlinez-frame-tb align-y-center border-r surface" }>
            <FilterByPeriod />
          </div>
        }
        
        {
          (
            typeof props.filterByClient === "undefined" || 
            props.filterByClient === true
          ) &&
          <div
            className={ "x space-lines-frame-lr space-inlinez-frame-tb align-y-center border-r surface" }>
            <FilterByEntity
              label={ "Filter by Client" }
              type={ "client" } />
          </div> 
        }

        {
          (
            typeof props.filterByMerchant === "undefined" || 
            props.filterByMerchant === true
          ) &&
          <div
            className={ "x space-lines-frame-lr space-inlinez-frame-tb align-y-center border-r surface" }>
            <FilterByEntity
              label={ "Filter by Merchant" }
              type={ "merchant" } />
          </div> 
        }

        {
          (
            typeof props.segmentTransactions === "undefined" || 
            props.segmentTransactions === true
          ) &&
          <div
            className={ cx(
              "x space-lines-frame-lr space-inlinez-frame-tb align-y-center border-r surface",
              { "disabled": segment.isUnableToSegment }
            ) }>
            <SegmentTransactions
              type={ props.type } />
          </div>
        }

      </div>
      
      <div 
        className={ "x" }>

        <div 
          className={ "x fill-x center surface" }>
          { props.actions?.map(a => a) }
        </div>

        <div
          className={ "border-l space-lines-frame-lr surface" }>
        </div>

      </div>

    </div>
  );
}

export interface ITransactionFilter {
  actions?: React.ReactNode[]
  filterByClient?: boolean
  filterByMerchant?: boolean
  filterByPeriod?: boolean 
  segmentTransactions?: boolean
  type?: TransactionType
}