export function debounce<F extends FuncType>(func: F, timeout: number): F {
  let timer: ReturnType<typeof setTimeout>;

  return function(this: ThisParameterType<F>, ...args: Parameters<F>) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  } as F;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FuncType = (...args: any[]) => void;