import React from "react"
import css from "./index.module.css"
import cx from "classnames"
import { LogoFaceWhite } from "@bookie/images";

export const Empty: React.FC<IEmpty> = (props) => {
  return (
    <div
      className={ cx(
        css.empty,
        "y space-lines-children surface-primary apply-bg apply-fg space-lines-frame border radius"
      ) }>

      <div 
        className={ "x align-x-between" }>

        <div 
          style={{ width: "70%" }}
          className={ "y space-lines-children" }>
          
          {
            props.label &&
              <h1>
                { props.label }
              </h1> 
          }

          {
            props.description?.map(d => <p>{ d }</p>)
          }

        </div>

        <div 
          className={ "space-lines-frame-r" }>
          <img 
            src={ LogoFaceWhite }
            style={{ height: "80px" }}
            alt={ "Bookie" } />
        </div>

      </div>

      {
        props.callToActions && 
          props.callToActions
      }

    </div>
  );
}

export interface IEmpty {
  label?: string
  description?: string[] 
  callToActions?: React.ReactNode
}