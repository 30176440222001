import React from "react";
import css from "./index.module.css";
import cx from "classnames";

export const Stage: React.FC<IStage> = (props) => {

  return (
    <div
      onClick={ () => { !props.isInactive && props.onClick?.() } }
      className={ cx(
        "y fill-x align-y-center",
        css.stage,
        {
          [css.stage_inactive]: props.isInactive,
          [css.stage_rejected]: props.isRejected,
          [css.stage_approved]: props.isApproved,
          [css.stage_active]: props.isActive,
          [css.stage_selected]: props.isSelected,
          [css.stage_pending]: props.isPending
        }
      ) }>
      
      <div 
        className={ cx(
          css.stage_progressBar,
          "x align-y-center align-x-between"
        ) }>
          
        <div 
          className={ "x space-inline-children align-y-center" }>

          {
            props.icon && 
              <div
                className={ css.stage_symbol }>
                {
                  React.cloneElement(
                    props.icon,
                    { size: 20 }
                  )
                }
              </div>
          }
          
          <div
            className={ css.stage_title }>
            { props.title }
          </div>
        </div>
        
        {
          props.renderNearTitle &&
            <div 
              className={ css.stage_nearTitle }>
              { props.renderNearTitle }
            </div>
        }
        
        {
          (props.renderActions && !props.isInactive) &&
            <div
              className={ cx(
                css.stage_renderActions,
                "y fill-x align-x-center"
              ) }>
              
              {/* <div
                className={ css.stage_renderActions_icon }>
                <IconDownAccordian />
              </div> */}

              <div 
                className={ cx(
                  css.stage_renderActions_actions,
                  "surface shadow"
                ) }>
                { props.renderActions }
              </div>

            </div>
        }

      </div>

    </div>
  );
}

export interface IStage {

  title: string
  icon?: React.ReactElement
  renderNearTitle?: React.ReactNode;
  renderActions?: React.ReactNode;
  onClick?: () => void;

  isInactive?: boolean 
  isRejected?: boolean
  isApproved?: boolean
  isActive?: boolean
  isPending?: boolean
  isSelected?: boolean 
  isClickable?: boolean

}