import React from 'react';
import ReactDOM from 'react-dom';
import { useWorkbench } from './ctx';
import { useSpring, animated } from "@react-spring/web";

export * from "./ctx";

export const Workbench: React.FC<IWorkbench> = ({
  isOpen,
  onClose,
  position = 'right',
  width = 900,
  children
}) => {

  const { 
    registerWorkbench, 
    unregisterWorkbench, 
    getZIndex 
  } = useWorkbench();

  const [ workbenchId, setWorkbenchId ] = React.useState<number | null>(null);
  const _ref = React.useRef<number | null>(null);

  React.useEffect(() => {

    if (isOpen && _ref.current === null) {
      const id = registerWorkbench();
      _ref.current = id;
      setWorkbenchId(id);
    } else if (!isOpen && _ref.current !== null) {
      unregisterWorkbench(_ref.current);
      _ref.current = null;
      setWorkbenchId(null);
    }

    return () => {
      if (_ref.current !== null) {
        unregisterWorkbench(_ref.current);
        _ref.current = null;
      }
    };

  }, [isOpen, registerWorkbench, unregisterWorkbench]);

  const springStyles = useSpring({
    transform: isOpen ? 'translateX(0)' : position === 'right' ? 'translateX(100%)' : 'translateX(-100%)',
    config: { tension: 200, friction: 40 }
  });

  if (!isOpen || workbenchId === null) {
    return null;
  }

  const zIndex = getZIndex(workbenchId);

  return ReactDOM.createPortal(
    <>
      <div 
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.3)',
          zIndex: zIndex - 1,
          backdropFilter: "blur(2px)",
          transition: "backgroundColor 0.3s ease-out"
        }} 
        onClick={onClose} />
        <animated.div style={{
          ...springStyles,
          position: 'fixed',
          top: 0,
          [position]: 0,
          height: '100vh',
          width: typeof width === 'number' ? `${width}px` : width,
          backgroundColor: '#fff',
          boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 10px',
          zIndex: zIndex,
          overflow: 'auto'
        }}>
          <div className={ "fill" }>
            { children }
          </div>
        </animated.div>
    </>,
    document.body,
  );
};


export interface IWorkbench {
  isOpen: boolean;
  onClose: () => void;
  position?: 'left' | 'right';
  width?: number | string;
  children?: React.ReactNode;
}