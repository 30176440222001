import { EntityExpanded, ErrorAPI, uuid } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js";

export const _getEntityExpanded = (sb: SupabaseClient): GetEntityExpanded => 
  async (entityId) => {

    const { data, error } = (
      await sb  
        .from("entity")
        .select(`
          *,
          address!entity_primary_address_fkey (*),
          contact!entity_primary_contact_fkey (*)
        `)
        .eq("id", entityId)
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      entity: objectToCamel<EntityExpanded>(data[0])
    }

  }

export type GetEntityExpanded = (entityId: uuid) => Promise<GetEntityExpandedResponse>

export interface GetEntityExpandedResponse {
  entity: EntityExpanded
}