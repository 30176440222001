import { ITableXColumn } from "@bookie/components"
import { OrgExpanded, TransactionExpanded } from "@bookie/glossary"
import { summarisePayments } from "@bookie/module-bookkeeping"
import { getCurrency, getLocaleFromCountryCode } from "@bookie/module-core"
import { CurrencyAPI } from "@hny_/currency"
import { TimezoneAPI } from "@hny_/timezone"

export const generateCurrencyExchangeDiffTable = (
  org: OrgExpanded,
  curr: CurrencyAPI,
  tz: TimezoneAPI
): ITableXColumn[] => [
  {
    header: "Description",
    renderCell: (tx: TransactionExpanded) => tx.description 
  },
  {
    header: "Invoiced Gross",
    align: "right",
    renderCell: (tx: TransactionExpanded) => (
      curr.toHumanReadable(tx.summary?.gross || 0, getLocaleFromCountryCode(org?.entity?.countryCode), tx.currencyCode)
    )
  },
  {
    header: `Invoiced Gross (${ getCurrency(org.entity?.currencyCode)?.emoji })`,
    align: "right",
    renderCell: (tx: TransactionExpanded) => (
      curr.toHumanReadable(
        tx.summary?.localised?.gross || 0,
        getLocaleFromCountryCode(org?.entity?.countryCode),
        org.entity?.currencyCode
      )
    )
  },
  {
    header: `Cash Recieved (${ getCurrency(org.entity?.currencyCode)?.emoji })`,
    align: "right",
    renderCell: (tx: TransactionExpanded) => {
      const paid = summarisePayments(tx.payments || [], org?.entity?.currencyCode || "");
      return curr.toHumanReadable(
        paid,
        getLocaleFromCountryCode(org?.entity?.countryCode),
        org.entity?.currencyCode
      );
    }
  },
  {
    header: "Diff",
    align: "right",
    width: "150px",
    renderCell: (tx: TransactionExpanded) => {

      const paid = summarisePayments(tx.payments || [], org?.entity?.currencyCode || "");
      const invoiced = tx.summary?.localised?.gross || 0;
      
      const diff = paid - invoiced;
      
      if (diff > 0) {
        return (
          <span className={ "surface-positive apply-fg" }>
            { curr.toHumanReadable(diff) }
          </span>
        );
      } else {
        return (
          <span className={ "surface-negative apply-fg" }>
            { curr.toHumanReadable(diff) }
          </span>
        );
      }
      
    }
  }
]