import React from "react";
import { IInput, InputBase } from "../base";
import CurrencyInput from "react-currency-input-field";

export const InputMoney: React.FC<IInputMoney> = (props) => {

  const [ inputValue, setInputValue ] = React.useState<string>();
  const [ isEditing, setIsEditing ] = React.useState<boolean>(false);

  const renderedValue = (
    Number(props.value) && typeof props.value !== "undefined" 
      ? props.toApplicationReadable(props.value) 
      : ""
  );

  return (
    <InputBase
      hoverEffect={ "hvr-underline-from-left" }
      { ...props }>

      {
        props.mode === "read-only" && 
          <p>{ props.toHumanReadable(props.value || 0) }</p>
      }

      {
        (props.mode === "edit" || !props.mode) && 
          <CurrencyInput
            
            name={ props.name }
            decimalsLimit={ 2 }
            intlConfig={{
              locale: props.config?.locale || "mt-MT",
              currency: props.config?.currencyCode || "EUR"
            }}
            placeholder={ props.placeholder || "€42" }
            
            onBlur={ () => {
              setIsEditing(false);
              props.onChange?.(
                Number(inputValue)
                  ? props.toDataReadable(Number(inputValue))
                  : ""
              );
              setInputValue(undefined);
            } }

            onFocus={ () => {
              setIsEditing(true);
              setInputValue(
                String(props.toApplicationReadable(props.value || 0))
              );
            } }
            
            value={ 
              isEditing 
                ? inputValue
                : renderedValue
            }
            
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onValueChange={(value: any) => {
              setInputValue(value || "");
            }} />
      }

    </InputBase>
  );
}

export interface IInputMoney extends IInput {
  value?: number
  config?: { locale?: string, currencyCode?: string }
  toHumanReadable: (amount: number) => string
  toDataReadable: (amount: number) => number
  toApplicationReadable: (amount: number) => number
}