import React from "react";
import { IInput, InputBase } from "../base";

export const InputText: React.FC<IInputText> = (props) => {
  return (
    <InputBase
      hoverEffect={ "hvr-text" }
      { ...props }>
      
      {
        props.mode === "read-only" && 
          <p>{ props.value }</p>
      }

      {
        (props.mode === "edit" || !props.mode) && 
          <input
            type={ "text" }
            className={ "hvr" }
            value={ props.value }
            onChange={ e => props.onChange?.(e.target.value) }
            placeholder={ props.placeholder || "Enter your input here..." }
            onFocus={ props.onFocus }
            onBlur={ props.onBlur } />
      }
    
    </InputBase>
  );
};

export interface IInputText extends IInput {
  value?: string;
};