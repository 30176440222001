import { ErrorAPI, Payment } from "@bookie/glossary"
import { objectToCamel, objectToSnake } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js"

export const _updatePayments = (sb: SupabaseClient): UpdatePayments => 
  async (
    payments
  ) => {

    const { data, error } = (
      await sb
        .from("payment")
        .upsert(objectToSnake(payments))
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      payments: objectToCamel<Payment[]>(data)
    };

  }

export type UpdatePayments = (
  payments: Payment[]
) => Promise<UpdatePaymentsResponse>

export interface UpdatePaymentsResponse {
  payments: Payment[]
}