import { ErrorAPI, TransactionOffering, TransactionOfferingOrphan } from "@bookie/glossary"
import { objectToCamel, objectToSnake } from "@bookie/utils"
import { PostgrestError, SupabaseClient } from "@supabase/supabase-js"

export const _updateTransactionOfferings = (sb: SupabaseClient): UpdateTransactionOfferings => 
  async (
    transactionOfferings
  ) => {

    const { data, error } = (
      await sb
        .from("transaction_offerings")
        .upsert(objectToSnake(transactionOfferings))
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      transactionOfferings: objectToCamel<TransactionOffering[]>(data)
    };

  }

export type UpdateTransactionOfferings = (
  transactionOfferings: TransactionOfferingOrphan[]
) => Promise<UpdateTransactionOfferingsResponse>

export interface UpdateTransactionOfferingsResponse {
  transactionOfferings: TransactionOffering[],
  error?: PostgrestError | null
}