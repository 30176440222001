import React from "react"
import { ITransactionEditorAPI } from "../../../use/use-transaction-editor";
import { Button, IconDates, InputDate, Scrollable } from "@bookie/components";
import { ITransactionEditorLifecycle } from "../../transaction-editor";
import { useTimezone } from "@hny_/timezone";

export const TransactionAssistInvoiceEssentials: React.FC<ITransactionAssistInvoiceEssentials> = (props) => {

  const tz = useTimezone();

  return (
    <div
      style={{ 
        maxWidth: "70%",
        width: "70%" 
      }}
      className={ "y space-lines-children fill-y space-cubes-frame-tb" }>

      <Scrollable
        className={ "y fill-y space-cubes-frame-tb align-y-center space-cubes-children" }>
        
        <div
          style={{ maxWidth: "100%" }}
          className={ "space-lines-frame-b space-inline-children y" }>
          <h1>
            { "You can get started on the Invoice now" }
          </h1>
          <h2>
            { "Just the 'When' this time, everything else is already there." }
          </h2>
        </div>

        <InputDate
          align={ "right" }
          label={ "When are you sending this Invoice?" }
          icon={ <IconDates /> }
          value={ props.editor?.data.invoiceDate }
          onChange={ v => props.editor?.edit.invoiceDate(v as string) }
          toApplicationReadable={ tz.toApplicationReadable }
          toDataReadable={ tz.toDataReadable }
          toHumanReadable={ tz.toHumanReadable } />

        <InputDate 
          align={ "right" }
          label={ "When will payment for this Invoice be due?" }
          icon={ <IconDates /> }
          value={ props.editor?.data.invoiceDue }
          onChange={ v => props.editor?.edit.invoiceDue(v as string) }
          toApplicationReadable={ tz.toApplicationReadable }
          toDataReadable={ tz.toDataReadable }
          toHumanReadable={ tz.toHumanReadable } />

        <div
          className={ "y fill-x" }>
          <Button 
            label={ "Create Invoice" }
            className={ "fill-btns" }
            onClick={ props.onCreate } />
          <Button 
            variant={ "inline" }
            label={ "Or you can start it another time." }
            onClick={ props.onFinish } />
        </div>

      </Scrollable>

    </div>
  );
}

export interface ITransactionAssistInvoiceEssentials extends ITransactionEditorLifecycle {
  editor?: ITransactionEditorAPI
  onCreate?: () => Promise<void>
}