import React from "react";
import { useUserIdentity } from "./use/use-user-identity";
import { useUserOwnedOrgs } from "./use/use-user-owned-orgs";
import { UserConfiguration, UserProfile, OrgExpanded, OrgTemplate } from "@bookie/glossary";
import { LogoFaceWhite } from "@bookie/images";

const IdentityCtx = React.createContext<IdentityAPI | undefined>(undefined);

const IdentityCtxProvider: React.FC<IIdentityCtxProvider> = (props) => {

  const userIdentity = useUserIdentity();
  
  const userOwnedOrgs = useUserOwnedOrgs(
    userIdentity.profile?.id, 
    userIdentity.configuration?.currentOrgId
  );

  if (!userOwnedOrgs.hasInitialised) {
    return (
      <div 
        className={ "surface-primary fill x align-x-center align-y-center" }>
        <img 
          style={{ height: "90px" }}
          src={ LogoFaceWhite }
          alt={ "bookie" } /> 
      </div>
    );
  }

  return (
    <IdentityCtx.Provider
      value={{
        identity: {
          profile: userIdentity.profile,
          configuration: userIdentity.configuration
        },
        ownership: {
          current: userOwnedOrgs.current,
          memberOf: userOwnedOrgs.owned,
          syncOrgTemplate: userOwnedOrgs.syncOrgTemplate
        },
        status: {
          hasInitialisedIdentity: userIdentity.hasInitialisedIdentity,
          requiresOnboarding: !userIdentity.configuration?.currentOrgId
        }
      }}>
      { props.children }
    </IdentityCtx.Provider>
  );

}

export interface IIdentityCtxProvider {
  children?: React.ReactNode 
}

const useIdentity = (): IdentityAPI => {
  const value = React.useContext(IdentityCtx);
  if (value === undefined) {
    throw new Error("Missing IdentityCtxProvider");
  }
  return value;
}

export { IdentityCtxProvider, useIdentity };

interface IdentityAPI {
  identity: {
    profile?: UserProfile
    configuration?: UserConfiguration
  },
  ownership: {
    current?: OrgExpanded
    memberOf?: OrgExpanded[]
    syncOrgTemplate: (oT: OrgTemplate) => void
  },
  status: {
    hasInitialisedIdentity: boolean,
    requiresOnboarding: boolean
  }
}