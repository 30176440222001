import React from "react"
import { ITransactionEditorAPI } from "../../../use/use-transaction-editor";
import { Button, IconLeftArrow, Scrollable } from "@bookie/components";
import { TransactionOfferingsEditor } from "../../transaction-offerings-editor";
// import { useCurrency } from "@hny_/currency";
// import { useCore } from "@bookie/module-core";

export const TransactionAssistOfferings: React.FC<ITransactionAssistOfferings> = (props) => {

  // const curr = useCurrency();
  // const { offerings } = useCore();

  return (
    <div
      style={{ 
        maxWidth: "70%",
        width: "70%" 
      }}
      className={ "y space-lines-children fill-y space-cubes-frame-tb" }>
        <Scrollable
          className={ "y fill-y align-y-center" }>

          <div
            className={ "space-lines-frame-b space-inline-children y" }>
            <h1>
              { props.title || "Products & Services" }
            </h1>
            <h2>
              { 
                props.subtitle || 
                props.editor?.type === "income"
                  ? `What are you selling? List all of the Products and/or Services that you are ${ props.transactionStage === "estimate" ? "estimating" : "invoicing"} for here.` 
                  : "What are you buying? List all of the Products and/or Services here." 
              }
            </h2>
          </div>
          
          <div
            className={ "fill-x y space-inline-children space-lines-frame-t" }>
            <TransactionOfferingsEditor
              surfaceDisplay={ "surface-primary" }
              editor={ props.editor }
              transactionType={ props.editor?.type }
              transactionStage={ props.transactionStage }
              renderBare={ true }
              currencyCode={ props.editor?.data.currencyCode } />
          </div>

          <div
            className={ "x space-lines-frame-t space-inline-children space-inline-frame-lr" }>
            
            <Button 
              icon={ <IconLeftArrow /> }
              variant={ "outline" }
              onClick={ props.onBack } />
            
            <div
              className={ "fill-x" }>
              <Button 
                label={ `Create ${ props.transactionStage === "estimate" ? "Estimate" : "Invoice" }` }
                style={{ width: "100%" }}
                className={ "fill-btns" }
                onClick={ props.onCreate } />
            </div>

          </div>

      </Scrollable>
      
  </div>
  );
}

export interface ITransactionAssistOfferings {
  editor?: ITransactionEditorAPI
  transactionStage?: "estimate" | "invoice"
  title?: string
  subtitle?: string
  onBack?: () => void 
  onCreate?: () => Promise<void>
}