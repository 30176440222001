import React from "react";
import css from "./index.module.css";
import cx from "classnames";

export const Stat: React.FC<IStat> = (props) => {
  return (
    <div 
      className={ cx(
        css.stat,
        "y",
        props.className,
        css[`stat_${ props.size || "medium" }`]
      ) }>

      {
        props.label && 
          <span
            className={ css.stat_label }>
            { props.label }
          </span>
      }

      <div
        className={ css.stat_value }>
        { props.value }
      </div>

      

    </div>
  );
}

export interface IStat {
  label?: string;
  value: string;
  className?: string;
  size?: "tiny" | "small" | "medium";
}