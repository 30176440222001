/* eslint-disable react-hooks/rules-of-hooks */
import { Address, Contact, Country, Entity, EntityOrphan, EntityType, ErrorAPI, ErrorValidation, InterfaceAsUndefined, ObjectFieldValidation, uuid } from "@bookie/glossary";
import { useIdentity } from "@bookie/module-identity";
import React from "react";
import { useCoreAPI } from "../use-core-api";
import { useCore } from "../use-core";
import { getEntity } from "../fns/get-entity";
import { getCountry } from "../fns/get-country";
import { IAddressEditorAPI, useAddressEditor, IAddressEditorState } from "./use-address-editor";
import { IContactEditorAPI, useContactEditor, IContactEditorState } from "./use-contact-editor";
import { getCountryFromLocale } from "../fns/get-country-from-locale";
import { validateEntity } from "../fns/validate-entity";
import { useValidator, ValidateFn } from "@bookie/utils";
import { useNotification } from "@bookie/components";

export const useEntityEditor = (
  entityId?: uuid | "create" | "seed",
  entityEditor?: IEntityEditorAPI,
  seed?: InterfaceAsUndefined<Entity>,
  config?: {
    entityType?: EntityType,
    editorType?: "base" | "expanded"
  }
): IEntityEditorAPI => {

  if (entityEditor) {
    return entityEditor;
  }

  const n = useNotification();
  const { ownership } = useIdentity();
  const api = useCoreAPI();
  const { entities } = useCore();

  // Entity
  // These properties are properties
  // required to create a new Entity 

  const [ email, setEmail ] = React.useState<string>("");
  const [ name, setName ] = React.useState<string>("");
  const [ countryCode, setCountryCode ] = React.useState<string>("");
  const [ currencyCode, setCurrencyCode ] = React.useState<string>("");
  const [ tin, setTin ] = React.useState<string>("");
  const [ nickname, setNickname ] = React.useState<string>("");
  const [ logoUrl, setLogoUrl ] = React.useState<string>("");
  const [ primaryAddress, setPrimaryAddress ] = React.useState<uuid>();
  const [ primaryContact, setPrimaryContact ] = React.useState<uuid>();
  const [ type, setType ] = React.useState<EntityType>();
  const [ phoneNumber, setPhoneNumber ] = React.useState<number>();
  const [ phoneCountryCode, setPhoneCountryCode ] = React.useState<string>("");

  // And these are post-create properties 

  const [ id, setId ] = React.useState<string>();
  const [ createdAt, setCreatedAt ] = React.useState<string>();
  const [ createdBy, setCreatedBy ] = React.useState<string>();
  
  // TODO this can be pulled out into a separate 
  // use hook.
  const [ logoFile, setLogoFile ] = React.useState<File>();

  // The following are meta properties
  // relevant to the entity,
  // that will help us with any logic. 

  const [ entityCountry, setEntityCountry ] = React.useState<Country>();

  // We need these to be able to pass the current 
  // state to the address and contact useHook editors.

  const [ address, setAddress ] = React.useState<Address>();
  const [ contact, setContact ] = React.useState<Contact>();

  // States.
  // Explain.

  const [ isUpdatingEntity, setIsUpdatingEntity ] = React.useState<boolean>(false);
  const [ isCreatingEntity, setIsCreatingEntity ] = React.useState<boolean>(false);
  const [ isDeletingEntity, setIsDeletingEntity ] = React.useState<boolean>(false);

  const [ isReady, setIsReady ] = React.useState<boolean>(false);

  // Related editors 

  const addressEditor = useAddressEditor(
    address?.id || "create", 
    address,
    undefined
  );

  const contactEditor = useContactEditor(
    contact?.id || "create", 
    contact,
    undefined,
    { countryCode }
  );

  React.useEffect(() => {

    // If the ID is set to "create"
    // then it implies we are creating a new 
    // entity. 

    // Over here all we need to do is initialise
    // with any necessary defaults.

    if (entityId === "create") {
      if (!ownership.current?.entity?.countryCode) {
        setCountryCode(getCountryFromLocale(navigator.language)?.alpha3 || "");
        setCurrencyCode(getCountryFromLocale(navigator.language)?.currency || "");
      } else {
        !seed?.countryCode && setCountryCode(ownership.current?.entity?.countryCode);
        !seed?.currencyCode && setCurrencyCode(ownership.current?.entity?.currencyCode || "");
        !seed?.phoneCountryCode && setPhoneCountryCode(getCountry(ownership.current?.entity?.currencyCode)?.phone || "");
      }
      setType(config?.entityType);
      setEmail(seed?.email || "");
      setName(seed?.name || "");
      setTin(seed?.tin || "");
      setNickname(seed?.nickname || "");
      setLogoUrl(seed?.logoUrl || "");
      setPhoneNumber(seed?.phoneNumber);
      setIsReady(true);
    }

    else if (entityId === "seed" && seed) {
      setEmail(seed.email || "");
      setName(seed.name || "");
      setCountryCode(seed.countryCode || "");
      setCurrencyCode(seed.currencyCode || "");
      setTin(seed.tin || "");
      setNickname(seed.nickname || "");
      setLogoUrl(seed.logoUrl || "");
      setType(seed.type);
      setPhoneNumber(seed.phoneNumber);
      setPhoneCountryCode(seed.phoneCountryCode || "");
      setIsReady(true);
    }

    // If we've got an actual ID
    // then we will need to fetch the
    // full entity object,
    // including any joins.

    else if (typeof entityId === "string") {
      (async () => {
        
        let _entity;

        if (!config?.editorType || config?.editorType === "base") {
          _entity = getEntity(entityId, entities.all);
        }

        else if (config?.editorType === "expanded") {
          try {
            
            const { entity } = await api.getEntityExpanded(entityId);
            _entity = entity;

            if (typeof entity.address === "object") {
              setAddress(entity.address);
            }

            if (typeof entity.contact === "object") {
              setContact(entity.contact);
            }

          } catch (e) {
            if (e instanceof ErrorAPI) {
              n.notify({ type: "error", message: e.error });
            }
          }
        }

        // We'll save all to context store
        // so that it can be easily used for 
        // the rest of the logic.

        setEmail(_entity?.email || "");
        setName(_entity?.name || "");
        setCountryCode(_entity?.countryCode || "");
        setCurrencyCode(_entity?.currencyCode || "");
        setTin(_entity?.tin || "");
        setNickname(_entity?.nickname || "");
        setLogoUrl(_entity?.logoUrl || "");
        setPrimaryAddress(_entity?.primaryAddress || "");
        setPrimaryContact(_entity?.primaryContact || "");
        setType(_entity?.type || config?.entityType);
        setPhoneCountryCode(_entity?.phoneCountryCode || getCountry(_entity?.countryCode || ownership.current?.entity?.countryCode)?.phone || "");
        console.log(_entity?.countryCode, getCountry(_entity?.countryCode || ownership.current?.entity?.countryCode)?.phone);
        setPhoneNumber(_entity?.phoneNumber);

        setId(_entity?.id);
        setCreatedAt(_entity?.createdAt);
        setCreatedBy(_entity?.createdBy);

        _entity?.countryCode && setEntityCountry(getCountry(_entity.countryCode));

        setIsReady(true);

      })();
    }

  }, [
    entities.all, 
    entityId, 
    ownership, 
    api, 
    config?.editorType, 
    seed, 
    n,
    isReady, 
    config?.entityType,
  ]);

  // Used to automatically set the 
  // currency code according to the 
  // country selected. 

  React.useEffect(() => {
    if (countryCode) {
      const country = getCountry(countryCode);
      if (country?.currency !== countryCode && country?.currency) {
        setCurrencyCode(country.currency);
      }
    }
  }, [ countryCode ]);

  const {
    validate,
    isVirgin,
    setDirty,
    validationErrors,
    setValidationErrors
  } = useValidator(
    (_e) => {

      const isAddressValid = addressEditor.validate();
      const isContactValid = contactEditor.validate();
      
      const _validation = validateEntity(_e);
      setValidationErrors(_validation.errors);

      return isAddressValid && isContactValid && _validation.isValid;
      
    },
    {
      email,
      name,
      countryCode,
      currencyCode,
      primaryAddress,
      primaryContact,
      phoneCountryCode,
      phoneNumber,
      tin,
      nickname,
      logoUrl,
      logoFile,
      type
    },
    undefined,
    isReady
  );

  return {

    data: {
      email,
      name,
      countryCode,
      currencyCode,
      primaryAddress,
      primaryContact,
      tin,
      nickname,
      logoUrl,
      phoneNumber,
      phoneCountryCode,
      type,
      id,
      createdAt,
      createdBy
    },

    validate,

    meta: {
      entityCountry,
      address,
      contact,
      logoFile,
    },

    edit: {
      email: setEmail,
      name: setName,
      countryCode: setCountryCode,
      currencyCode: setCurrencyCode,
      tin: setTin,
      nickname: setNickname,
      logoFile: setLogoFile,
      logoUrl: setLogoUrl,
      primaryAddress: setPrimaryAddress,
      primaryContact: setPrimaryContact,
      type: setType,
      phoneNumber: setPhoneNumber,
      phoneCountryCode: setPhoneCountryCode
    },

    state: {
      isVirgin,
      isWorking: (
        isUpdatingEntity ||
        isCreatingEntity ||
        isDeletingEntity ||
        addressEditor.state.isWorking ||
        contactEditor.state.isWorking
      ),
      isUpdatingEntity,
      isCreatingEntity,
      isDeletingEntity,
      isCreatingAddress: addressEditor.state.isCreatingAddress,
      isUpdatingAddress: addressEditor.state.isUpdatingAddress,
      isDeletingAddress: addressEditor.state.isDeletingAddress,
      isFetchingAddress: addressEditor.state.isFetchingAddress,
      isCreatingContact: contactEditor.state.isCreatingContact,
      isUpdatingContact: contactEditor.state.isUpdatingContact,
      isDeletingContact: contactEditor.state.isDeletingContact,
      isFetchingContact: contactEditor.state.isFetchingContact
    },

    delete: async () => {
      if (entityId) {
        try {
          setIsDeletingEntity(true);
          const { entity } = await api.deleteEntity(entityId);
          setIsDeletingEntity(false);
          entities.api.remove(entity.id);
          n.notify({ type: "success", message: "All gone!" });
          return true;
        } catch (e) {
          setIsDeletingEntity(false);
          if (e instanceof ErrorAPI) {
            n.notify({ type: "error", message: e.error });
          }
        }
      }
      return false;
    },

    commit: async () => {
      
      setDirty();
      
      const canCommit = validate();
      if (!canCommit) throw new ErrorValidation({ name: "entity" });

      let _address;
      let _contact;

      try {
        _address = await addressEditor.commit();
        _contact = await contactEditor.commit();
      } catch (e) {
        return false;
      }

      const _entity = {
        email,
        name,
        countryCode,
        currencyCode,
        primaryAddress: typeof _address === "object" ? _address.id : undefined,
        primaryContact: typeof _contact === "object" ? _contact.id : undefined,
        phoneCountryCode,
        phoneNumber,
        tin,
        nickname,
        type,
        logoUrl,
        id
      };

      const hasChangedRelated = (
        typeof _address === "object" || typeof _contact === "object"
      );

      const allowChange = hasChangedRelated || !isVirgin;

      if (
        entityId === "create" && 
        allowChange
      ) {

        setIsCreatingEntity(true);
        
        try {

          const { entity } = await api.createEntity(_entity as EntityOrphan, logoFile);
          setIsCreatingEntity(false);
          entities.api.sync(entity);
          n.notify({ type: "success", message: `Created ${ entity.name }` });
          return entity;

        } catch (e) {
          setIsCreatingEntity(false);
          if (e instanceof ErrorAPI) {
            n.notify({ type: "error", message: e.error });
          }
        }
      }

      else if (
        typeof entityId === "string" && 
        allowChange
      ) {
        try {
          setIsUpdatingEntity(true); 
          const { entity } = await api.updateEntity(_entity as Entity, logoFile, ownership.current?.id);
          setIsUpdatingEntity(false);
          entities.api.sync(entity);
          n.notify({ type: "success", message: `Updated ${ entity.name }` });
          return entity;
        } catch (e) {
          setIsUpdatingEntity(false);
          if (e instanceof ErrorAPI) {
            n.notify({ type: "error", message: e.error });
          }
        }
        
      }

      return false;

    },

    related: {
      addressEditor,
      contactEditor
    },

    errors: {
      setDirty,
      validation: validationErrors,
      setValidation: setValidationErrors
    }

  }

}

export interface IEntityEditorAPI {
  data: EntityOrphan,
  meta: {
    entityCountry?: Country,
    address?: Address,
    contact?: Contact,
    logoFile?: File
  },
  edit: {
    email: (v: string) => void,
    name: (v: string) => void,
    countryCode: (v: string) => void,
    currencyCode: (v: string) => void,
    tin: (v: string) => void,
    nickname: (v: string) => void,
    logoFile: (file: File) => void,
    logoUrl: (v: string) => void,
    primaryAddress: (id: string) => void,
    primaryContact: (id: string) => void,
    type: (t: EntityType) => void,
    phoneCountryCode: (v: string) => void,
    phoneNumber: (v: number) => void,
  },
  state: (
    IEntityEditorState & 
    IAddressEditorState & 
    IContactEditorState
  ),
  validate: ValidateFn,
  delete: () => Promise<boolean>,
  commit: () => Promise<Entity | false>,
  related: {
    addressEditor: IAddressEditorAPI,
    contactEditor: IContactEditorAPI
  },
  errors: {
    setDirty: () => void,
    validation?: ObjectFieldValidation,
    setValidation: (v: ObjectFieldValidation) => void 
  }
}

export interface IEntityEditorState {
  isVirgin?: boolean,
  isWorking: boolean,
  isUpdatingEntity: boolean,
  isDeletingEntity: boolean,
  isCreatingEntity: boolean
}