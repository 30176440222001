import { ObjectFieldValidation, ObjectValidation, AddressOrphan } from "@bookie/glossary";

export const validateAddress = 
  (
    address: AddressOrphan
  ): ObjectValidation => {
  
  let errors: ObjectFieldValidation = {};
  
  if (
    typeof address.line1 === "undefined"
  ) {
    errors = {
      ...errors,
      line1: "Required"
    };
  }

  if (
    typeof address.city === "undefined"
  ) {
    errors = {
      ...errors,
      city: "Required"
    };
  }

  if (
    typeof address.country === "undefined"
  ) {
    errors = {
      ...errors,
      country: "Required"
    };
  }

  if (
    typeof address.postalCode === "undefined"
  ) {
    errors = {
      ...errors,
      postalCode: "Required"
    };
  }

  return {
    isValid: Object.keys(errors).length > 0 ? false : true,
    errors
  };

}