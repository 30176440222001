import { Address, ErrorAPI } from "@bookie/glossary";
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel, objectToSnake } from "@bookie/utils";

export const _updateAddress = (sb: SupabaseClient): UpdateAddress => 
  async (address) => {

    const { data, error } = (
      await sb 
        .from("address")
        .update(objectToSnake(address))
        .eq("id", address.id)
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      address: objectToCamel<Address>(data[0])
    };

  }

export type UpdateAddress = (
  address: Address
) => Promise<UpdateAddressResponse>

export interface UpdateAddressResponse {
  address: Address;
}