import { Entity, ErrorAPI, uuid } from "@bookie/glossary";
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel, objectToSnake } from "@bookie/utils";
import { _uploadFile } from "./_upload-file";

export const _updateEntity = (sb: SupabaseClient): UpdateEntity => 
  async (entity, logo, orgId) => {

    if (logo && orgId) {
      const { filepath } = await _uploadFile(sb)(logo, `/${ orgId }`);
      entity.logoUrl = filepath;
    }

    const { data, error } = (
      await sb 
        .from("entity")
        .update(objectToSnake(entity))
        .eq("id", entity.id)
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      entity: objectToCamel<Entity>(data[0])
    };

  }

export type UpdateEntity = (
  entity: Entity,
  logo?: File,
  orgId?: uuid
) => Promise<UpdateEntityResponse>

export interface UpdateEntityResponse {
  entity: Entity
}