import { Address, ErrorAPI, uuid } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js";

export const _deleteAddress = (sb: SupabaseClient): DeleteAddress => 
  async (addressId) => {

    const { data, error } = (
      await sb
        .from("address")
        .delete()
        .eq("id", addressId)
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      address: objectToCamel<Address>(data[0])
    };

  }

export type DeleteAddress = (
  addressId: uuid
) => Promise<DeleteAddressResponse>

export interface DeleteAddressResponse {
  address: Address;
}