import React from "react";
// import { Moment } from "moment-timezone"; // TODO <- need to define this as an external / peer dependency
import { IInput, InputBase } from "../base";
import { Calendar } from "react-date-range";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export const InputDate: React.FC<IInputDate> = (props) => {

  const calendarPosition = props.calendarPosition || "right";

  const dateRef = React.useRef<HTMLDivElement>(null);

  const [ showCalendar, setShowCalendar ] = React.useState<boolean>(false);

  const handleDocumentClick = (event: MouseEvent) => {
    if (dateRef.current && !dateRef.current.contains(event.target as Node)) {
      setShowCalendar(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => { document.removeEventListener('click', handleDocumentClick); };
  }, []);

  return (    
    <InputBase
      { ...props }>
      
      {
        (props.mode === "read-only" && props.value) && 
          <p>{ props.toHumanReadable(props.value) }</p>
      }

      {
        (props.mode === "edit" || !props.mode) && 
          <div
            ref={ dateRef }
            style={{ position: "relative", fontSize: "var(---hny-input-font-size)" }}
            onClick={ () => setShowCalendar(!showCalendar) }>
            
            <p
              style={{ cursor: "pointer" }}
              className={ "input_face align-y-center x" }>
              <span>
              { props.value ? props.toHumanReadable(props.value) : "Click to add a date" }
              </span>
            </p>

            {
              showCalendar && 
                <div
                  style={{
                    zIndex: "99999",
                    position: "absolute",
                    top: "100%",
                    right: calendarPosition === "right" ? "0" : "inherit",
                    left: calendarPosition === "left" ? "0%" : "inherit",
                    boxShadow: "var(--surface-shadow)"
                  }}
                  onClick={ e => e.stopPropagation() }
                  className={ "surface border radius" }>
                  <Calendar
                    date={ props.toApplicationReadable(props.value) } 
                    onChange={ (date) => {
                      date && props.onChange?.(props.toDataReadable(date));
                      setShowCalendar(false);
                    } } />
                </div>
            }

          </div>
      }

    </InputBase>
  );
}

export interface IInputDate extends IInput {
  value?: string;
  calendarPosition?: "left" | "right";
  toHumanReadable: (date: string) => string;
  toApplicationReadable: (date?: string) => Date;
  toDataReadable: (date: Date) => string;
};