import { useSupabase } from "@bookie/supabase";
import { _getUserConfiguration, GetUserConfiguration } from "./_api/_get-user-configuration";
import { _getUserOrgs, GetUserOrgs } from "./_api/_get-user-orgs";
import { _getUserProfile, GetUserProfile } from "./_api/_get-user-profile";
import { _updateCurrentOrgId, UpdateCurrentOrgId } from "./_api/_update-current-org-id";
import { _addUserToOrg, AddUserToOrg } from "./_api/_add-user-to-org";

export const useIdentityAPI: IUseIdentityAPI = () => {

  const { supabase } = useSupabase();

  return ({
    getUserConfiguration: _getUserConfiguration(supabase),
    getUserOrgs: _getUserOrgs(supabase),
    getUserProfile: _getUserProfile(supabase),
    updateCurrentOrgId: _updateCurrentOrgId(supabase),
    addUserToOrg: _addUserToOrg(supabase)
  });

}

export type IUseIdentityAPI = () => IdentityAPI

export interface IdentityAPI {
  getUserConfiguration: GetUserConfiguration,
  getUserOrgs: GetUserOrgs,
  getUserProfile: GetUserProfile,
  updateCurrentOrgId: UpdateCurrentOrgId,
  addUserToOrg: AddUserToOrg
}