import { Entity, ErrorAPI, uuid } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js";

export const _deleteEntity = (sb: SupabaseClient): DeleteEntity => 
  async (entityId) => {

    const { data, error } = (
      await sb
        .from('entity')
        .delete()
        .eq("id", entityId)
        .select()
    );

    if (error && error.code === "23503") {
      throw new ErrorAPI({
        error: `You can't delete this because it still has Income or Expenses associated with it.`
      });
    }

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      entity: objectToCamel<Entity>(data[0])
    };

  }

export type DeleteEntity = (
  entityId: uuid
) => Promise<DeleteEntityResponse>

export interface DeleteEntityResponse {
  entity: Entity
}