import React from "react";
import { TransactionExpanded, TransactionFilterPeriods, uuid } from "@bookie/glossary";
import { filterTransactions } from "../fns/filter-transactions";

export const useTransactionFilter = (transactions: TransactionExpanded[] = []): ITransactionFilter => {

  const [ filterPeriod, setFilterPeriod ] = React.useState<TransactionFilterPeriods>("this_year");
  const [ filterEntityId, setFilterEntityId ] = React.useState<uuid>("");  

  // We'll watch for any changes to the 
  // full list of txs, and if it changes 
  // we will re-calculate the profits and losses. 

  const filteredTransactions = React.useMemo(
    () => filterTransactions(transactions, filterPeriod, filterEntityId),
    [transactions, filterPeriod, filterEntityId]
  );

  const filterByPeriod = React.useCallback(
    (p: TransactionFilterPeriods) => { setFilterPeriod(p) },
    []
  );

  const filterByEntityId = React.useCallback(
    (id: uuid) => { setFilterEntityId(id) },
    []
  );

  return {
    filteredTransactions,
    filter: {
      period: filterPeriod,
      byPeriod: filterByPeriod,
      entityId: filterEntityId,
      byEntityId: filterByEntityId
    }
  }

}

export interface ITransactionFilter {
  filteredTransactions: TransactionExpanded[]
  filter: ITransactionFilters
}

export interface ITransactionFilters {
  period?: TransactionFilterPeriods,
  byPeriod: (period: TransactionFilterPeriods) => void,
  entityId?: string,
  byEntityId: (entityId: string) => void
}
