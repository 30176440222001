import { ObjectFieldValidation, Payment, PaymentOrphan } from "@bookie/glossary";
import { Button, IconAuto, IconDates, IconDelete, IconExchange, IconGross, IconManual, IconUpAccordian, IconValidation, InputDate, InputMoney, InputNumber, InputSelect, Tooltip } from "@bookie/components";
import React from "react";
import cx from "classnames";
import { useTimezone } from "@hny_/timezone";
import { getCurrency, getLocaleFromCountryCode, SelectCurrency } from "@bookie/module-core";
import { useCurrency } from "@hny_/currency";
import { useIdentity } from "@bookie/module-identity";
import { useLocalisation } from "../../use/use-localisation";

export const TransactionPaymentEditor: React.FC<ITransactionPaymentEditor> = (props) => {

  const curr = useCurrency();
  const tz = useTimezone();

  const { ownership } = useIdentity();
  
  const { 
    exchange,
    state 
  } = useLocalisation(
    (rate) => {
      props.onEdit?.("exchangeRate", rate);
      props.onEdit?.("grossLocalised", Math.round((props.payment.gross || 0) * rate))
    },
    props.payment.currencyCode,
    props.payment.exchangeDate,
    props.payment.isManuallyLocalised
  );

  const hasValidation = Object.keys(props.errors || {}).length > 0;

  if (!props.isSelected) {
    return (
      <div
        onClick={ props.onSelect }
        className={ cx(
          "x apply-fg radius border-t fill-x align-x-between",
          { "clickable": !props.isSelected },
          `${ props.surfaceDisplay || "surface-aside" }`
        ) }>
        
        <div 
          className={ "x space-inline-children align-y-center" }>
          
          <p>
            <b>
              { 
                curr.toHumanReadable(
                  props.payment.gross || 0, 
                  getLocaleFromCountryCode(ownership.current?.entity?.countryCode),
                  props.payment.currencyCode
                ) 
              }
            </b>
          </p>

          {
            hasValidation && 
              <Tooltip
                className={ "surface-negative" }
                message={ "There are some errors in here" }
                base={ <IconValidation size={ 18 } /> } />
          }

        </div>
        
        <div
          className={ "x align-y-center" }>
          
          <div 
            className={ "space-inlinez-frame" }>
            <p>{ tz.toHumanReadable(props.payment.paymentDate || "") }</p>
          </div>
          
          <div 
            className={ cx(
              "border-l",
              `${ props.surfaceDisplay || "surface" }`
            ) }>
            <Button
              variant={ "text" }
              onClick={ e => {
                e?.preventDefault();
                props.onDelete?.();
              } }
              icon={ 
                <Tooltip 
                  message={ "Delete" }
                  base={ <IconDelete size={ 22 } /> } />
              }
              size={ "slim" } />
          </div>
        
        </div>

      </div>
    )
  }

  return (
    <div
      className={ props.isSelected ? "apply-selected" : "" }>
      <div
        style={{ position: "relative" }}
        className={ cx(
          "y surface-aside radius border fill-x apply-bg apply-fg"
        ) }>

        <div 
          onClick={ e => {
            e?.preventDefault();
            props.onMinimise?.();
          } }
          style={{ cursor: "pointer" }}
          className={ "x surface apply-bg border-b space-inline-frame-tb align-x-between" }>
          <Button
            variant={ "text" }
            onClick={ e => {
              e?.preventDefault();
              props.onMinimise?.();
            } }
            icon={
              <Tooltip 
                message={ "Minimise" }
                base={ <IconUpAccordian size={ 18 } /> } />
            }
            size={ "slim" } />
          <Button
            variant={ "text" }
            onClick={ e => {
              e?.preventDefault();
              props.onDelete?.();
            } }
            icon={ 
              <Tooltip 
                message={ "Delete" }
                base={ <IconDelete size={ 22 } /> } />
            }
            size={ "slim" } />
        </div>
        
        <div
          className={cx(
            "x space-inline-children align-x-between",
            { "space-inlinez-frame": props.isSelected },
            { "space-inlinez-frame": !props.isSelected },
          )}>

            
          <SelectCurrency
            label={ props.isSelected ? "Currency" : "" }
            // size={ "small" }
            showName={ true }
            selectedCurrencyCode={ props.payment.currencyCode }
            onSelect={ (curr) => props.onEdit?.("currencyCode", curr.code) } />
          
          <InputDate
            label={ props.isSelected ? "Payment Date" : "" }
            placeholder={ "PaymentDate" }
            // size={ "small" }
            icon={ <IconDates /> }
            value={ props.payment.paymentDate }
            onChange={ v => props.onEdit?.("paymentDate", v) }
            error={ props.errors?.["paymentDate"] }
            toApplicationReadable={ tz.toApplicationReadable }
            toDataReadable={ tz.toDataReadable }
            toHumanReadable={ tz.toHumanReadable } />        
          
          <InputMoney
            label={ props.isSelected ? "Amount" : "" }
            placeholder={ "5" }
            // size={ "small" }
            icon={ <IconGross /> }
            value={ props.payment.gross }
            onChange={ v => props.onEdit?.("gross", v) }
            error={ props.errors?.["gross"] }
            config={{
              currencyCode: props.payment.currencyCode,
              locale: getLocaleFromCountryCode(ownership.current?.entity?.countryCode)
            }}
            toApplicationReadable={ curr.toApplicationReadable }
            toHumanReadable={ curr.toHumanReadable }
            toDataReadable={ curr.toDataReadable } />
        
        </div>
          

        {
          state.requiresLocalisation &&
            <div 
              className={ cx(
                "y space-inlinez-frame space-lines-children border-t",
                { "disabled": state.isLocalisingGross }
              ) }>

              <div 
                className={ "x surface-info" }>
                { "You are being paid in another currency. We have exchanged it to your local currency using the rate on the date of payment." }
              </div>

              <p
                style={{ fontSize: "11px" }}>
                <b>{ `Localising to ${ getCurrency(ownership.current?.entity?.currencyCode)?.name } ${ getCurrency(ownership.current?.entity?.currencyCode)?.emoji }` }</b>
              </p>

              <div 
                className={"y space-lines-children align-x-between"}>

                <div
                  className={ "x align-y-center align-x-between wrap space-lines-children" }>
                  
                  {
                    !props.payment.isManuallyLocalised && 
                      <InputDate
                        label={ props.isSelected ? "Exchange Date" : "" }
                        placeholder={ "Exchange Date" }
                        icon={ <IconDates /> }
                        value={ props.payment.exchangeDate }
                        onChange={ v => props.onEdit?.("exchangeDate", v) }
                        error={ props.errors?.["exchangeDate"] }
                        toApplicationReadable={ tz.toApplicationReadable }
                        toDataReadable={ tz.toDataReadable }
                        toHumanReadable={ tz.toHumanReadable } />
                  }
                  
                  <InputNumber
                    label={ "Exchange Rate" }
                    icon={ <IconExchange /> }
                    value={ props.payment.exchangeRate }
                    onChange={ v => props.onEdit?.("exchangeRate", v) } />
                  
                  <InputMoney
                    label={ "Localised Amount" }
                    placeholder={ "5" }
                    icon={ <IconGross /> }
                    value={ props.payment.grossLocalised }
                    onChange={ v => props.onEdit?.("grossLocalised", v) }
                    error={ props.errors?.["grossLocalised"] }
                    toApplicationReadable={ curr.toApplicationReadable }
                    toHumanReadable={ curr.toHumanReadable }
                    toDataReadable={ curr.toDataReadable } />
                  
                </div>

                <div 
                  className={ "x space-inline-children align-x-end" }>
                  
                  <Button
                    variant={ "inline" }
                    onClick={ e => {
                      e?.preventDefault();
                      exchange();
                    } }
                    label={ "Exchange" }
                    icon={ <IconExchange /> }
                    size={ "slim" } />
                  
                  <Button
                    variant={ "inline" }
                    size={ "slim" }
                    icon={
                      props.payment.isManuallyLocalised
                        ? <IconAuto />
                        : <IconManual /> 
                    }
                    label={ (
                      props.payment.isManuallyLocalised 
                        ? "Automatically Exchange"
                        : "Manually Exchange"
                    ) }
                    onClick={ () => {
                      if (props.payment.isManuallyLocalised) {
                        props.onEdit?.("isManuallyLocalised", false)
                      } else {
                        props.onEdit?.("isManuallyLocalised", true);
                      }
                    } } />

                </div> 

              </div>
            </div>
        } 

        <div
          className="x space-inlinez-frame space-inline-children align-x-between border-t">
          
          <InputSelect
            placeholder={ "How where you paid?" }
            label={ "Payment Type" }
            options={[
              {
                label: "Bank Transfer",
                id: "bank_transfer"
              },
              {
                label: "Cash",
                id: "cash"
              }
            ]}
            value={ {
              label: "Bank Transfer",
              id: "bank_transfer"
            } }
            matchKey={ "id" }
            formatLabel={ i => i.label }
            onSelect={ i => {} } />

        </div>

      </div>
    </div>
  );

}

export interface ITransactionPaymentEditor {
  surfaceDisplay?: string
  payment: PaymentOrphan
  isSelected?: boolean
  onSelect?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEdit?: (field: keyof Payment, value: any) => void
  onDelete?: () => void
  errors?: ObjectFieldValidation
  onMinimise?: () => void
}