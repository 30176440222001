import { SUMMARIES, TransactionExpandedSegment, TransactionSegmentGroup, TransactionsSummary } from "@bookie/glossary";

export const groupSegments = (
  segments: TransactionExpandedSegment[] = []
): TransactionSegmentGroup => {
  
  const summary = segments.reduce(
    (summary: TransactionsSummary, segment: TransactionExpandedSegment) => ({
      all: {
        gross: (summary.all?.gross || 0) + (segment.summary?.all?.gross || 0), 
        net: (summary.all?.net || 0) + (segment.summary?.all?.net || 0), 
        cost: (summary.all?.cost || 0) + (segment.summary?.all?.cost || 0), 
      },
      accrued: {
        gross: (summary.accrued?.gross || 0) + (segment.summary?.accrued?.gross || 0), 
        net: (summary.accrued?.net || 0) + (segment.summary?.accrued?.net || 0), 
        cost: (summary.accrued?.cost || 0) + (segment.summary?.accrued?.cost || 0), 
      },
      cash: {
        gross: (summary.cash?.gross || 0) + (segment.summary?.cash?.gross || 0), 
        net: (summary.cash?.net || 0) + (segment.summary?.cash?.net || 0), 
        cost: (summary.cash?.cost || 0) + (segment.summary?.cash?.cost || 0), 
      },
      invoicePending: {
        gross: (summary.invoicePending?.gross || 0) + (segment.summary?.invoicePending?.gross || 0), 
        net: (summary.invoicePending?.net || 0) + (segment.summary?.invoicePending?.net || 0), 
        cost: (summary.invoicePending?.cost || 0) + (segment.summary?.invoicePending?.cost || 0), 
      },
      estimatePending: {
        gross: (summary.estimatePending?.gross || 0) + (segment.summary?.estimatePending?.gross || 0), 
        net: (summary.estimatePending?.net || 0) + (segment.summary?.estimatePending?.net || 0), 
        cost: (summary.estimatePending?.cost || 0) + (segment.summary?.estimatePending?.cost || 0)
      },
      badDebt:{
        gross: (summary.badDebt?.gross || 0) + (segment.summary?.badDebt?.gross || 0), 
        net: (summary.badDebt?.net || 0) + (segment.summary?.badDebt?.net || 0), 
        cost: (summary.badDebt?.cost || 0) + (segment.summary?.badDebt?.cost || 0)
      },
      expensesDue:{
        gross: (summary.expensesDue?.gross || 0) + (segment.summary?.expensesDue?.gross || 0), 
        net: (summary.expensesDue?.net || 0) + (segment.summary?.expensesDue?.net || 0), 
        cost: (summary.expensesDue?.cost || 0) + (segment.summary?.expensesDue?.cost || 0)
      },
      expensesPaid:{
        gross: (summary.expensesPaid?.gross || 0) + (segment.summary?.expensesPaid?.gross || 0), 
        net: (summary.expensesPaid?.net || 0) + (segment.summary?.expensesPaid?.net || 0), 
        cost: (summary.expensesPaid?.cost || 0) + (segment.summary?.expensesPaid?.cost || 0)
      }
    }),
    SUMMARIES
  );

  return ({
    segments,
    summary
  });

}