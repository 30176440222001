import { Button } from "@bookie/components";
import React from "react";
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";
import { TransactionType } from "@bookie/glossary";
import { SendReceipt } from "../send-receipt";

export const PaymentToolbar: React.FC<IPaymentToolbar> = (props) => {

  if (!props.editor?.state.paymentState) {
    return null;
  }

  return (
    <div 
      className={ "x surface space-lines-frame-lr space-inlinez-frame-tb align-x-center align-y-center border fill-y" }>
      <div
        className={ "y align-y-center space-inline-children" }>

        { 
          (
            props.editor?.state.paymentState !== "paid_in_full" &&
            props.editor?.state.paymentState !== "bad_debt"
          ) && 
            <Button 
              size={ "slim" }
              variant={ "text" }
              label={ "Mark as Bad Debt" }
              onClick={ () => {
                props.editor?.edit.invoiceState("rejected");
              } } />
        }

        {
          (
            props.editor?.state.paymentState === "paid_in_full"
          ) &&
            <SendReceipt
              editor={ props.editor } />
        }
        
      </div>
    </div>
  );
}

export interface IPaymentToolbar {
  editor?: ITransactionEditorAPI
  type?: TransactionType
}