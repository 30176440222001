import { SupabaseClient } from "@supabase/supabase-js";
import { ErrorAPI, Transaction, TransactionType } from "@bookie/glossary";
import { objectToCamel, objectToSnake } from "@bookie/utils";

export const _updateTransaction = (sb: SupabaseClient): UpdateTransaction => 
  async (tx, type) => {

    const { data, error } = (
      await sb
        .from('transaction')
        .update(
          objectToSnake(tx)
        )
        .eq("id", tx.id)
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }
    
    return {
      transaction: objectToCamel<Transaction>(data[0])
    };

  }

export type UpdateTransaction = (
  tx: Transaction,
  type: TransactionType 
) => Promise<UpdateTransactionResponse>

export interface UpdateTransactionResponse {
  transaction: Transaction
}