import { EntityOrphan, ErrorAPI, Org } from "@bookie/glossary";
import { SupabaseClient } from "@supabase/supabase-js";
import { _updateCurrentOrgId, _addUserToOrg } from "@bookie/module-identity";
import { objectToCamel } from "@bookie/utils";
import { _createEntity } from "./_create-entity";
import { _claimEntityForOrg } from "./_claim-entity-for-org";

export const _createOrg = (sb: SupabaseClient): CreateOrg => 
  async (entity) => {

    // We can start off by creating an org. 
    // We don't need to pass any info because 
    // the org is a wrapper around an entity 
    // that tells the system this entity 
    // is a user-owned and to center the 
    // book-keeping around this entity. 
    // Really all we need is the ID which 
    // automatically generated on creation. 

    const { data, error } = (
      await sb 
        .from("org")
        .insert({})
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    const _org = objectToCamel<Org>(data[0]);

    // Once we've created the org,
    // we now need to create a row in the mapping 
    // table to associated the user with this 
    // org. 

    // Orgs and users have a many-many relationship 
    // so we need to describe this via 
    // the mapping table.

    await _addUserToOrg(sb)(_org.id, _org.createdBy);

    // Since we are creating one, the main scenario 
    // would be to use it. We want to set the org 
    // ID under the user's current configuration. 
    // This adds this ID to our JWT for multi-tenancy 
    // validation. 

    await _updateCurrentOrgId(sb)(_org.id, _org.createdBy);

    // We can now go ahead and create the main 
    // entity.

    const { entity: _e } = await _createEntity(sb)({
      ...entity, 
      ownedBy: _org.id,
      type: "self"
    });

    // And associate it back with the org 
    // we just created. 

    await _claimEntityForOrg(sb)(_e.id, _org.id);

    return {
      org: objectToCamel<Org>(data[0])
    }

  }

export type CreateOrg = (
  entity: EntityOrphan
) => Promise<CreateOrgResponse>

export interface CreateOrgResponse {
  org: Org
}