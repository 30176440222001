import React from "react";
import css from "./index.module.css";
import cx from "classnames";

// Seprate this out into two components.
// One generic status pill for Honey.
// The other is a bookie specific wrapper
// defines bookie domain-specific states
// to the generic ones provided by honey.

export const PillStatus: React.FC<IPillStatus> = (props) => {

  if (!props.status) {
    return null;
  }

  return (
    <div
      className={ cx(
        css.pillStatus,
        { [ css.status_active ]: props.isActive },
        { [ css.status_inactive ]: props.isInactive },
        { [ css.status_successful ]: props.isSuccessful },
        { [ css.status_unsuccessful ]: props.isUnsuccessful },
        { [ css.status_na ]: props.isNA },
        { [ css.pillStatus_isMinimised ]: props.isMinimised },
        "x",
        props.className
      ) }>
      
      {
        props.icon && 
          <div 
            className={ css.pillStatus_icon }>
            {
              React.cloneElement(
                props.icon,
                { size: 20 }
              )
            }
          </div>
      }

      <div 
        className={ css.pillStatus_status }>
        { props.status }
      </div>
    
    </div>
  );
}

export interface IPillStatus {
  status?: string
  label?: string
  icon?: React.ReactElement
  isActive?: boolean 
  isInactive?: boolean
  isSuccessful?: boolean
  isUnsuccessful?: boolean
  isNA?: boolean
  isMinimised?: boolean
  className?: string
}