import React from "react";
import { IconClient, IInput, InputSelect } from "@bookie/components";
import { useCore } from "../../use-core";
import { Entity, EntityOrphan, EntityType, uuid } from "@bookie/glossary";
import { getEntity } from "../../fns/get-entity";
import { getCountry } from "../../fns/get-country";
import cx from "classnames";

export const SelectEntities: React.FC<ISelectEntities> = (props) => {

  const { entities } = useCore();

  let options = [];

  switch (props.entityType) {
    case "client": {
      options = entities.clients || [];
      break;
    }
    case "merchant": {
      options = entities.merchants || [];
      break;
    }
    default: {
      options = entities.all || [];
      break;
    }
  }

  return (
    <InputSelect
      { ...props }
      className={ cx(
        props.className
      ) }
      name={ "entity" }
      placeholder={ props.placeholder }
      labelPosition={ props.labelPosition }
      label={ props.label || "Select Entity" }
      optionsAlign={ props.optionsAlign }
      icon={ <IconClient /> }
      size={ props.size }
      type={ "text" }
      mode={ "edit" }
      options={ options }
      noOptionsCopy={ 
        props.entityType === "client"
          ? "You haven't created any Clients yet."
          : props.entityType === "merchant"
              ? "You haven't created any Merchants yet."
              : "You haven't created any yet."
      }
      value={(
        props.selectedNewEntity
          ? props.selectedNewEntity
          : getEntity(props.selectedEntityId, entities.all)
      )}
      actions={ props.actions }
      searchKey={ "name" }
      formatLabel={ (e: Entity) => {

        if (!e || !e.countryCode) {
          return null;
        }

        const _country = getCountry(e.countryCode);

        // TODO 
        // Should we handle the scenario a country isn't found?
        // Unlikley since we have the full list of countries
        // however we may need to block some in future.

        return (
          <div
            className={ "x space-inline-children align-y-baseline" }>
            <div>{ _country?.emoji }</div>
            <div>{ e.name }</div>
          </div>
        );

      } }
      matchKey={(
        props.selectedNewEntity
          ? "name"
          : "id"
      )}
      onSelect={ (e: Entity) => props.onSelect?.(e) }
      error={ props.error }
      
      startSideMission={ props.startSideMission }
      sideMission={ props.sideMission } />
  );
}

export interface ISelectEntities extends IInput {
  selectedEntityId?: uuid
  selectedNewEntity?: EntityOrphan
  onSelect?: (e: Entity) => void
  actions?: { label: string, onClick: () => void }[]
  optionsAlign?: "left" | "right"
  entityType?: EntityType
}