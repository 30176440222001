import React, { useState, useCallback, FC, ReactElement } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';

// Define the shape of your data
interface ChartData {
  name: string;
  value: number;
  fill?: string
  label?: string
}

// Define the props for ChartPie component
interface ChartPieProps {
  data?: ChartData[];
  width?: number | string;
  height?: number | string;
  innerRadius?: number;
  outerRadius?: number;
  fill?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (data: any, index: number) => void;
}

// Default data if none is provided via props
const defaultData: ChartData[] = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

/**
 * Renders a customized active shape for the Pie slice.
 *
 * @param props - Properties passed by Recharts to the active shape.
 * @returns A ReactElement representing the active shape.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RenderActiveShape: FC<any> = (props): ReactElement => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    // value,
  } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 5) * cos;
  const sy = cy + (outerRadius + 5) * sin;
  const mx = cx + (outerRadius + 10) * cos;
  const my = cy + (outerRadius + 10) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text
        x={cx}
        y={cy - 4}
        dy={8}
        textAnchor="middle"
        fill={fill}
        fontWeight="bold"
        fontSize={10}
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 2}
        outerRadius={outerRadius + 5}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={13}
        fontWeight={"bold"}
      >
        {payload.label}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey - 4}
        dy={18}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={11}
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

/**
 * ChartPie Component
 *
 * A reusable Pie Chart component with customizable active shape.
 *
 * @param props - Props to customize the Pie Chart.
 * @returns A responsive Pie Chart.
 */
export const ChartPie: FC<ChartPieProps> = ({
  data = defaultData,
  width = 100,
  height = 400,
  innerRadius = 20,
  outerRadius = 30,
  fill = '#8884d8',
  onClick,
}) => {
  
  // State to manage the active index of the Pie slice

  const [activeIndex, setActiveIndex] = useState<number>(0);

  /**
   * Handler for when a Pie slice is hovered.
   *
   * @param data - Data of the hovered slice.
   * @param index - Index of the hovered slice.
   */
  const onPieEnterHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, index: number) => {
      setActiveIndex(index);
    },
    []
  );

  return (
    <ResponsiveContainer width={ "100%" } height={ "100%" }>
      <PieChart width={Number(width)} height={Number(height)}>
        <Pie
          activeIndex={activeIndex}
          activeShape={<RenderActiveShape />}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill={fill}
          dataKey="value"
          onMouseEnter={onPieEnterHandler}
          onClick={onClick}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
