import { ObjectFieldValidation, ObjectValidationArray, PaymentOrphan } from "@bookie/glossary";

export const validateTransactionPayments = 
  (
    payments: PaymentOrphan[]
  ): ObjectValidationArray => {
  
  const errors: ObjectFieldValidation[] = [];

  for (const p of payments) {

    let _errors: ObjectFieldValidation = {};

    if (
      typeof p.gross === "undefined" ||
      !Number(p.gross)
    ) {
      _errors = {
        ..._errors,
        gross: "Required"
      };
    }

    if (
      typeof p.paymentDate === "undefined" ||
      p.paymentDate === ""
    ) {
      _errors = {
        ..._errors,
        paymentDate: "Required"
      }
    }

    if (
      typeof p.currencyCode === "undefined" ||
      p.currencyCode === ""
    ) {
      _errors = {
        ..._errors,
        currencyCode: "Required"
      }
    }

    if (Object.keys(_errors).length > 0) {
      errors.push(_errors);
    }

  }
  
  return {
    isValid: errors.length > 0 ? false : true,
    errors
  };

}