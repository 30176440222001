import React from "react";
import { TransactionExpanded, TransactionExpandedSegment, TransactionSegmentType } from "@bookie/glossary";
import { summariseTransactions } from "@bookie/module-bookkeeping";

export const useCreditBadDebt = (
  transactions: TransactionExpanded[]
): IUseCreditBadDebt => {

  const creditedTransactions = React.useMemo(
    () => {

      const _txsWithBadDebt = transactions.filter(t => t.type === "income" && t.paymentState === "bad_debt");
      const summary = summariseTransactions(_txsWithBadDebt);

      return {
        label: "Credited from Bad Debt",
        transactions: _txsWithBadDebt,
        summary,
        type: "credited-bad-credit" as TransactionSegmentType
      }

    },
    [ transactions ]
  );

  return {
    creditedTransactions
  };

}

export interface IUseCreditBadDebt {
  creditedTransactions: TransactionExpandedSegment
}