import React from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';

export const ChartBar: React.FC<IChartBar> = (props) => {
  return (
    <ResponsiveContainer width="100%">

      <BarChart 
        layout={ "vertical" } 
        width={300} 
        data={props.data || []}
        barSize={ 12 }
        margin={{ left: -60 }}>
        
        <Tooltip
          formatter={ props.tooltipValueFormatter }
          cursor={{ fill: "#e1efef" }} />
        
        <XAxis 
          type="number" hide />

        <YAxis 
          type="category"
          axisLine={ false }
          tickLine={ false }
          dataKey={ "name" }
          tick={ <CustomLabel /> } />
        
        
        
        <Bar 
          dataKey={ "net" }
          fill={ "#20783a" }
          background={{ fill: "#e1efef" }}
          stackId={ "a" }
          radius={[10, 0, 0, 10]}  />
        
        <Bar 
          dataKey={ "cost" }
          stackId={ "a" } 
          fill={ "#a50f0d" }
          radius={[0, 10, 10, 0]}/>

      </BarChart>

    </ResponsiveContainer>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomLabel: React.FC<any> = (props) => {
  return (
    <text 
      x={ props.x + 10 } 
      y={ props.y - 12 }
      fontSize={ 11 }
      fill={ "#8bb9b9" }
      fontWeight={ "bold" }
      letterSpacing={ 1 }
      >
      { props.payload.value.toUpperCase() } 
    </text>
  );
}

export interface IChartBar {
  data?: ChartData[];
  width?: number | string;
  height?: number | string;
  tooltipValueFormatter?: Formatter<string, string>
}

interface ChartData {
  name: string;
  net: number;
  cost: number;
  fill?: string
  label?: string
}
