import { TransactionExpanded, TransactionIssue, TransactionType } from "@bookie/glossary";
import { auditTransaction } from "./audit-transaction";

export const auditTransactions = (
  transactions: TransactionExpanded[],
  type: TransactionType,
  localCurrencyCode: string
) => {
  return transactions.reduce(
    (acc: TransactionIssue, tx: TransactionExpanded) => {
      const issue = auditTransaction(tx, localCurrencyCode, type);
      if (issue) {
        return ({
          ...acc,
          [ tx.id ]: issue
        });
      }
      return acc;
    },
    {}
  );
}