import React from "react";
import { Button, ButtonSize, IconDocument, IconSend, Workbench } from "../../../../components/src";
import { TransactionDocumentEditor } from "../../views/transaction-document-editor";
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";

export const SendReceipt: React.FC<ISendReceipt> = (props) => {

  const [ showDocument, setShowDocument ] = React.useState<boolean>(false);

  const clientLabel = props.editor?.related.entityEditor.data.name || "your Client";

  return (
    <>

      <Button 
        size={ props.size || "slim" }
        icon={(
          props.justOpen
            ? <IconDocument size={ 10 } />
            : <IconSend size={ 10 } /> 
        )}
        label={ props.label || `Send Receipt to ${ clientLabel }` }
        onClick={ () => setShowDocument(true) } />

      <Workbench
        isOpen={ showDocument }
        onClose={ () => setShowDocument(false) }
        width={ "1200px" }>
        <TransactionDocumentEditor
          type={ "receipt" }
          editor={ props.editor }
          onClose={ () => setShowDocument(false) } />
      </Workbench>
    
    </>
  );
}

export interface ISendReceipt {
  editor?: ITransactionEditorAPI
  size?: ButtonSize
  saveOnFinish?: boolean
  label?: string
  onComplete?: () => void
  justOpen?: boolean
}