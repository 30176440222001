import moment from "moment";

export const getMonthsUntilThisMonth = (today: moment.Moment): string[] => {

  const _thisMonth = Number(today.format("M"));
  
  const _months: string[] = [];

  for (let month = 1; month <= _thisMonth; month++) {
    const _month = moment(month, "M").format("MMMM");
    _months.push(_month);
  }

  return _months;

}
