import React from "react";
import { TransactionType, uuid } from "@bookie/glossary";
import { Button, InputBase, IconDates, IconClient, InputDate, Panel, IconRightAccordian, Bookie, IconEssential } from "@bookie/components";
import { TransactionStage } from "../../components/transaction-stage";
import { SelectEntities, EntityEditorQuick, SelectCurrency, getCurrency } from "@bookie/module-core";
import { useTransactionEditor } from "../../use/use-transaction-editor";
import { TransactionOfferingsEditor } from "../transaction-offerings-editor";
import cx from "classnames";
import { useTimezone } from "@hny_/timezone";
import { TransactionNotes } from "../../components/transaction-notes";
import { TransactionPaymentsEditor } from "../transaction-payments-editor";
import { TransactionAccounting } from "../../components/transaction-accounting";
import { TransactionAssist } from "../transaction-assist";

export const TransactionEditor: React.FC<ITransactionEditor> = (props) => {

  const tz = useTimezone();

  const editor = useTransactionEditor(
    props.txId,
    undefined,
    undefined,
    {
      transactionType: props.transactionType,
      transactionStage: props.transactionStage
    }
  );

  if (props.useAssist) {
    return (
      <TransactionAssist
        editor={ editor }
        transactionStage={ props.transactionStage }
        onFinish={ props.onFinish }
        onFinishAssist={ props.onFinishAssist }
        onEdit={ props.onEdit } 
        onAnother={ props.onAnother } />
    );
  }

  return (
    <div 
      className={ cx(
        "y fill-y surface-alt border-l",
        editor.state.isWorking ? "disabled" : ""
      ) }>
      
      <div
        style={{ height: "55px" }}
        className={ "x surface border-b space-lines-frame-lr space-inlinez-frame-tb align-x-between align-y-center" }>
        
        <div 
          className={ "x space-inline-children" }>
          
          <div
            className={ "space-lines-frame-r" }>
            <Bookie
              message={ "Guide me" }
              onClick={ props.onUseAssist }
              isOpen={ true } />
          </div>

          <Button
            size={ "slim" }
            className={ "hvr-pulse hvr-brighten" }
            label={ "Save" }
            onClick={ async () => {
              const result = await editor.commit();
              result && props.onFinish?.();
            } }
            optionsIconStyle={ "chevron" } />
            {/* options={(
             <TransactionRepeat
                onRepeat={ (repeatConfig: TransactionRepeatConfig) => {
                  (async () => {
                    const result = await editor.commit(repeatConfig);
                    result && props.onFinish?.();
                  })();
                } } />
            )} /> */}
          <Button 
            size={ "slim" }
            variant={ "inline" }
            className={ "hvr-icon-forward" }
            icon={ <IconRightAccordian /> }
            onClick={ props.onFinish } />

        </div>
        
        {
          editor.data.id &&
            <Button
              size={ "slim" }
              variant={ "dangerous" }
              surface={ "surface-negative" }
              label={ "Delete" }
              isDangerous={ true }
              onClick={ async () => {
                const result = await editor.delete();
                result && props.onFinish?.();
              } } /> 
        }

      </div>

      <TransactionStage
        editor={ editor } />

      <div 
        style={{ height: "0px" }}
        className={ "y surface scrollable fill-y" }>

        <Panel 
          label={ "Client, Currency & Timeline" }
          icon={ <IconEssential /> }
          defaultOpen={ props.txId === "create" ? true : false }
          summary={ `${ editor.related.entityEditor.data.name }, ${ editor.data.currencyCode } ${ getCurrency(editor.data.currencyCode)?.emoji }` }>
          <div
            className={ "x space-lines-frame space-lines-children fill-x" }>
            
            <div
              className={ "y align-y-start fill-x space-lines-children space-lines-frame-r" }>

                {
                  !editor.state.isCreatingNewEntity && 
                    <div 
                      style={{ alignSelf: "flex-start" }}
                      className={ "y space-lines-children" }>
                    
                        {
                          editor.type === "income" && 
                            <SelectEntities 
                              // size={ "small" }
                              entityType={ "client" }
                              label={ "Client" }
                              optionsAlign={ "right" }
                              placeholder={ "Choose your Client or create a new one" }
                              actions={[
                                { label: "New", onClick: () => editor.trigger.createNewEntity(true) }
                              ]}
                              selectedEntityId={ editor.data.sourceId }
                              selectedNewEntity={(
                                editor.state.isCreatingNewEntity 
                                  ? editor.related.entityEditor.data
                                  : undefined
                              )}
                              onSelect={ (e) => {
                                editor.edit.sourceId(e.id);
                                editor.trigger.createNewEntity(false);
                              } }
                              error={ editor.errors.validation?.["sourceId"] } />
                        }

                        {
                          editor.type === "expense" && 
                            <SelectEntities 
                              // size={ "small" }
                              label={ "Merchant" }
                              entityType={ "merchant" }
                              optionsAlign={ "right" }
                              actions={[
                                { label: "New", onClick: () => editor.trigger.createNewEntity(true) }
                              ]}
                              selectedEntityId={ editor.data.destinationId }
                              selectedNewEntity={(
                                editor.state.isCreatingNewEntity
                                  ? editor.related.entityEditor.data 
                                  : undefined
                              )}
                              onSelect={ (e) => {
                                editor.edit.destinationId(e.id);
                                editor.trigger.createNewEntity(false);
                              } }
                              error={ editor.errors.validation?.["destinationId"] } />
                        }

                    </div>
                }

                {
                  editor.state.isCreatingNewEntity &&
                    <div 
                      className={ "y fill-x space-inline-children" }>
                      <div 
                        className={ "x align-x-between align-y-center" }>
                        <InputBase
                          label={ "Client" }
                          // size={ "small" }
                          icon={ <IconClient /> } />
                        <Button
                          label={ "Choose an existing Client" }
                          onClick={ () => editor.trigger.createNewEntity(false) }
                          size={ "slim" }
                          variant={ "text" } />
                      </div>   
                      <div 
                        className={ "surface-aside apply-bg apply-fg x space-inline-frame border radius" }>
                        <EntityEditorQuick
                          entityId={ "create" }
                          editor={ editor.related.entityEditor } />
                      </div>
                    </div>
                }

              <div 
                className={ "y fill-x" }>
                
                <div 
                  className={ "x" }>
                  <SelectCurrency
                    label={ "Currency" }
                    showName={ true }
                    // size={ "small" }
                    optionsAlign={ "right" }
                    selectedCurrencyCode={ editor?.data.currencyCode }
                    onSelect={ c => editor?.edit.currencyCode(c.code) } />
                </div>
              </div>
              

            </div>
          
            <div 
              style={{ width: "50%", maxWidth: "50%" }}
              className={ "y space-lines-children fill-x" }>
              {
                editor.data.estimateState !== "n/a" && 
                  <div 
                    className={ "y fill-x space-lines-frame-r fill-inputs" }>
                    
                    <InputDate 
                      size={ "small" }
                      align={ "right" }
                      label={ "Estimate is valid from" }
                      icon={ <IconDates /> }
                      labelPosition={ "left" }
                      value={ editor?.data.estimateDate }
                      onChange={ v => editor?.edit.estimateDate(v as string) }
                      toApplicationReadable={ tz.toApplicationReadable }
                      toDataReadable={ tz.toDataReadable }
                      toHumanReadable={ tz.toHumanReadable } />

                    <InputDate 
                      size={ "small" }
                      align={ "right" }
                      label={ "Estimate expires" }
                      icon={ <IconDates /> }
                      labelPosition={ "left" }
                      value={ editor?.data.estimateExpiration }
                      onChange={ v => editor?.edit.estimateExpiration(v as string) }
                      toApplicationReadable={ tz.toApplicationReadable }
                      toDataReadable={ tz.toDataReadable }
                      toHumanReadable={ tz.toHumanReadable } />

                  </div>
              }
              
              <div 
                className={ "y fill-x space-lines-frame-r" }>
                <InputDate 
                  className={ (
                    (
                      editor.data.estimateState === "approved" ||
                      editor.data.estimateState === "n/a"
                    ) 
                      ? "" 
                      : "disabled"
                  ) }
                  size={ "small" }
                  align={ "right" }
                  label={ "Invoice is valid from" }
                  icon={ <IconDates /> }
                  labelPosition={ "left" }
                  value={ editor?.data.invoiceDate }
                  onChange={ v => editor.edit.invoiceDate(v as string) }
                  toApplicationReadable={ tz.toApplicationReadable }
                  toDataReadable={ tz.toDataReadable }
                  toHumanReadable={ tz.toHumanReadable } />

                <InputDate 
                  className={ (
                    (
                      editor.data.estimateState === "approved" ||
                      editor.data.estimateState === "n/a"
                    ) 
                      ? "" 
                      : "disabled"
                  ) }
                  size={ "small" }
                  align={ "right" }
                  label={ "Payment is due" }
                  icon={ <IconDates /> }
                  labelPosition={ "left" }
                  value={ editor?.data.invoiceDue }
                  onChange={ v => editor.edit.invoiceDue(v as string) }
                  toApplicationReadable={ tz.toApplicationReadable }
                  toDataReadable={ tz.toDataReadable }
                  toHumanReadable={ tz.toHumanReadable } />
              </div>

            </div>

          </div>
        </Panel>

        <TransactionOfferingsEditor
          currencyCode={ editor.data.currencyCode || editor.related.entityEditor.data.currencyCode }
          editor={ editor }
          transactionType={ editor.type } />
        
        <TransactionPaymentsEditor
          editor={ editor.related.transactionPaymentsEditor }
          isDisabled={
            editor.related.transactionOfferingsEditor.total === 0
          } />
        
        {
          editor.type === "income" &&
            <TransactionAccounting 
              editor={ editor }
              isDisabled={
                editor.related.transactionOfferingsEditor.total === 0
              } /> 
        }
        
        <TransactionNotes
          editor={ editor } />

      </div>

    </div>
  );
}

export type ITransactionEditor = ITransactionEditorConfig & ITransactionEditorLifecycle

export interface ITransactionEditorLifecycle {
  onEdit?: (
    id: uuid | "create", 
    stage?: "estimate" | "invoice",
    useAssist?: boolean
  ) => void
  onAnother?: () => void
  onFinish?: () => void
  onFinishAssist?: () => void
  onUseAssist?: () => void
  onCompleteStep?: () => void
}

export interface ITransactionEditorConfig {
  transactionType?: TransactionType
  transactionStage?: "estimate" | "invoice"
  txId?: uuid | "create"
  useAssist?: boolean
}