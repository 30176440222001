import { Entity, EntityOrphan, ErrorAPI } from "@bookie/glossary"
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel, objectToSnake, stripNullAndUndefinedFromObject } from "@bookie/utils";

export const _upsertEntity = (sb: SupabaseClient): UpsertEntity => 
  async (entity) => {

    const { data, error } = (
      await sb
        .from("entity")
        .upsert(
          objectToSnake(stripNullAndUndefinedFromObject(entity)),
          { onConflict: "name,tin" }
        )
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      entity: objectToCamel<Entity>(data[0])
    };

  }

export type UpsertEntity = (
  entity: EntityOrphan
) => Promise<UpsertEntityResponse>

export interface UpsertEntityResponse {
  entity: Entity
}