import { Transaction, TransactionOfferingOrphan } from "@bookie/glossary";

export const repeatTransactionOfferings = (
  txs: Transaction[],
  transactionOfferings: TransactionOfferingOrphan[]
): TransactionOfferingOrphan[] => {

  let _transactionOfferings: TransactionOfferingOrphan[] = [];

  for (const tx of txs) {
    _transactionOfferings = [
      ..._transactionOfferings,
      ...transactionOfferings.map(to => ({ ...to, transactionId: tx.id  }))
    ];
  }

  return _transactionOfferings

}