import React from "react";
import { uuid, OrgExpanded, OrgTemplate } from "@bookie/glossary";
import { useIdentityAPI } from "../use-identity-api";

export const useUserOwnedOrgs = (
  userId?: uuid,
  currentOrgId?: uuid
): IUserOwnedOrgs => {

  const {
    getUserOrgs
  } = useIdentityAPI();

  const [ ownedOrgs, setOwnedOrgs ] = React.useState<OrgExpanded[]>([]);
  const [ refreshOwnedOrgs, setRefreshOwnedOrgs ] = React.useState<boolean>(true);

  const [ hasInitialised, setHasInitialised ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      if (userId && refreshOwnedOrgs && typeof getUserOrgs === "function") {
        const { orgs: _o } = await getUserOrgs(userId);
        _o && setOwnedOrgs(_o);
        setRefreshOwnedOrgs(false);
        setHasInitialised(true);
      }
    })();
  }, [ userId, getUserOrgs, refreshOwnedOrgs ]);

  const syncOrgTemplate = (orgTemplate: OrgTemplate) => {
    setOwnedOrgs((prevOwnedOrgs) => {
      return prevOwnedOrgs.map((org) => {
        if (org.id === orgTemplate.id) {
          return {
            ...org,
            template: orgTemplate,
          };
        }
        return org;
      });
    });
  }

  return {
    current: ownedOrgs.find(oo => oo.id === currentOrgId),
    owned: ownedOrgs,
    refreshOwned: () => setRefreshOwnedOrgs(true),
    hasInitialised,
    syncOrgTemplate
  };

}

export interface IUserOwnedOrgs {
  current?: OrgExpanded
  owned: OrgExpanded[]
  refreshOwned?: () => void
  hasInitialised: boolean,
  syncOrgTemplate: (orgTemplate: OrgTemplate) => void;
}