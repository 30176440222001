import React from "react"
import { ITransactionEditorAPI } from "../../../use/use-transaction-editor";
import { Button, IconClient, IconDates, InputBase, InputDate, Scrollable } from "@bookie/components";
import { EntityEditorQuick, SelectCurrency, SelectEntities } from "@bookie/module-core";
import { useTimezone } from "@hny_/timezone";
import cx from "classnames";

export const TransactionAssistEssentials: React.FC<ITransactionAssistEssentials> = (props) => {

  const tz = useTimezone();

  return (
    <div
      className={ "y space-lines-children fill-y space-cubes-frame-tb" }>
      <Scrollable
        className={ cx(
          "y fill-y space-cubes-frame-tb align-y-center"
        ) }>
      
        <div
          className={ "space-lines-frame-b space-inline-children y" }>
          <h1>
            { "Basic Information" }
          </h1>
          <h2>
            { "Client, Currency and Timeline" }
          </h2>
        </div>

        <div 
          className={ "space-cubes-frame-tb border-b" }>

          {
            !props.editor?.state.isCreatingNewEntity && props.editor?.type === "income" &&
              <SelectEntities 
                entityType={ "client" }
                label={
                  props.transactionStage === "estimate"
                    ? "Who is this Estimate for?"
                    : "Who is this Invoice for?"
                }
                align={ "right" }
                optionsAlign={ "right" }
                placeholder={ "Choose an existing Client or create a new one" }
                actions={[
                  { label: "Create new Client", onClick: () => props.editor?.trigger.createNewEntity(true) }
                ]}
                selectedEntityId={ props.editor?.data.sourceId }
                selectedNewEntity={(
                  props.editor?.state.isCreatingNewEntity 
                    ? props.editor?.related.entityEditor.data
                    : undefined
                )}
                onSelect={ (e) => {
                  props.editor?.edit.sourceId(e.id);
                  props.editor?.trigger.createNewEntity(false);
                } }
                error={ props.editor?.errors.validation?.["sourceId"] } />
          }

          {
            !props.editor?.state.isCreatingNewEntity && props.editor?.type === "expense" &&
              <SelectEntities 
                entityType={ "merchant" }
                label={ "Who are you buying from?" }
                align={ "right" }
                optionsAlign={ "right" }
                placeholder={ "Choose an existing Merchant or create a new one" }
                actions={[
                  { label: "Create New Merchant", onClick: () => props.editor?.trigger.createNewEntity(true) }
                ]}
                selectedEntityId={ props.editor?.data.destinationId }
                selectedNewEntity={(
                  props.editor?.state.isCreatingNewEntity 
                    ? props.editor?.related.entityEditor.data
                    : undefined
                )}
                onSelect={ (e) => {
                  props.editor?.edit.destinationId(e.id);
                  props.editor?.trigger.createNewEntity(false);
                } }
                error={ props.editor?.errors.validation?.["destinationId"] } />
          }

          {
            props.editor?.state.isCreatingNewEntity &&
              <div className={ "y space-inlinez-children fill-x space-lines-frame-b" }>
                <div 
                  className={ "x align-x-between align-y-center" }>
                  <InputBase
                    label={ "New  Client" }
                    size={ "small" }
                    icon={ <IconClient /> } />
                  <Button
                    variant={ "text" }
                    label={ "Choose an existing Client" }
                    onClick={ () => props.editor?.trigger.createNewEntity(false) }
                    size={ "slim" } />
                </div>   
                <div 
                  className={ "surface-primary-aside apply-bg x border radius" }>
                  <EntityEditorQuick
                    entityId={ "create" }
                    editor={ props.editor?.related.entityEditor } />
                </div>
              </div>
          }

        </div>
        
        <div 
          className={ "space-cubes-frame-tb border-b" }>
          <SelectCurrency
            label={ 
              props.editor?.type === "income"
                ? "In what currency are you going to be paid?"
                : "In what currency are you going to be paying?"
            }
            align={ "right" }
            optionsAlign={ "left" }
            showName={ true }
            selectedCurrencyCode={ props.editor?.data.currencyCode }
            onSelect={ (c) => props.editor?.edit.currencyCode(c.code) } />
        </div>

        <div 
          className={ "y space-lines-children space-cubes-frame-tb" }>          

          {
            props.transactionStage === "estimate" && 
              <>  
                <InputDate
                  align={ "right" }
                  calendarPosition={ "left" }
                  label={ "When are you sending this Estimate?" }
                  icon={ <IconDates /> }
                  value={ props.editor?.data.estimateDate }
                  onChange={ v => props.editor?.edit.estimateDate(v as string) }
                  toApplicationReadable={ tz.toApplicationReadable }
                  toDataReadable={ tz.toDataReadable }
                  toHumanReadable={ tz.toHumanReadable } />

                <InputDate 
                  align={ "right" }
                  calendarPosition={ "left" }
                  label={ "When does this Estimate expire?" }
                  icon={ <IconDates /> }
                  value={ props.editor?.data.estimateExpiration }
                  onChange={ v => props.editor?.edit.estimateExpiration(v as string) }
                  toApplicationReadable={ tz.toApplicationReadable }
                  toDataReadable={ tz.toDataReadable }
                  toHumanReadable={ tz.toHumanReadable } />    
              </>
          }  

          {
            props.transactionStage === "invoice" && 
              <>  
                <InputDate
                  align={ "right" }
                  calendarPosition={ "left" }
                  label={ 
                    props.editor?.type === "income"
                      ? "When are you sending this Invoice?"
                      : "When did you receive this Invoice?"
                  }
                  icon={ <IconDates /> }
                  value={ props.editor?.data.invoiceDate }
                  onChange={ v => props.editor?.edit.invoiceDate(v as string) }
                  toApplicationReadable={ tz.toApplicationReadable }
                  toDataReadable={ tz.toDataReadable }
                  toHumanReadable={ tz.toHumanReadable } />

                <InputDate 
                  align={ "right" }
                  calendarPosition={ "left" }
                  label={ 
                    props.editor?.type === "income"
                      ? "When will payment be due?"
                      : "When is payment due?" 
                  }
                  icon={ <IconDates /> }
                  value={ props.editor?.data.invoiceDue }
                  onChange={ v => props.editor?.edit.invoiceDue(v as string) }
                  toApplicationReadable={ tz.toApplicationReadable }
                  toDataReadable={ tz.toDataReadable }
                  toHumanReadable={ tz.toHumanReadable } />    
              </>
          }      

        </div>

        <div
          className={ "space-cubes-frame-tb" }>
          <Button
            label={ "Next" }
            onClick={ props.onNext }
            className={ "fill-btns" } />
        </div>

      </Scrollable>
    </div>
  );
}

export interface ITransactionAssistEssentials {
  editor?: ITransactionEditorAPI
  transactionStage?: "estimate" | "invoice"
  onNext?: () => void
}