import { Button, IconReject, Workbench } from "@bookie/components";
import React from "react"
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";
import { TransactionDocumentEditor } from "../../views/transaction-document-editor";
import { TransactionType } from "@bookie/glossary";
import { SendInvoice } from "../send-invoice";

export const InvoiceToolbar: React.FC<IInvoiceToolbar> = (props) => {

  const [ showDocument, setShowDocument ] = React.useState<boolean>(false);
  
  if (!props.editor?.data.invoiceState) {
    return null;
  }

  return (
    <div 
      className={ "x surface border radius space-lines-frame-lr space-inlinez-frame-tb align-x-center border align-y-center fill-y" }>

      <div
        className={ "y align-x-center space-inline-children" }>

        {
          props.editor?.data.invoiceState === "draft" && 
            <>

              <SendInvoice
                editor={ props.editor } />

              <Button
                size={ "slim" }
                label={ "Mark as Sent" }
                variant={ "inline" }
                onClick={ () => {
                  if (props.editor?.data && props.type) {
                    props.editor.errors.setDirty();
                    const isValid = props.editor.validate(true);

                    if (props.editor.related.transactionOfferingsEditor.all.length === 0) {
                      alert("You need to add at least one Product or Service first");
                      return;
                    }

                    if (isValid) {
                      props.editor?.edit.invoiceState("submitted");
                      props.editor?.edit.paymentState("due");
                    }
                  }
                } } />

            </>
        }

        {
          (
            props.editor?.data.invoiceState === "submitted" ||
            props.editor?.data.invoiceState === "rejected"
          ) && 
            <>

              <SendInvoice 
                editor={ props.editor }
                label={ `Open Invoice PDF` }
                justOpen={ true } />
              
              <div 
                className={ "x" }>
                <Button
                  size={ "slim" }
                  label={ "Re-mark as draft" }
                  variant={ "text" }
                  onClick={ () => {
                    props.editor?.edit.invoiceState("draft");
                    props.editor?.edit.paymentState(undefined);
                  } } />
                
                <Button
                  size={ "slim" }
                  icon={ <IconReject /> }
                  variant={ "text" }
                  label={ "Reject" }
                  onClick={ () => {
                    props.editor?.edit.invoiceState("rejected");
                    props.editor?.edit.paymentState("bad_debt");
                  } } />
              </div>
            
            </>
        }
        
      </div>

      <Workbench
        isOpen={ showDocument }
        onClose={ () => setShowDocument(false) }
        width={ "1200px" }>
        <TransactionDocumentEditor
          type={ "invoice" }
          editor={ props.editor }
          onFinish={ () => setShowDocument(false) } />
      </Workbench>
      
    </div>
  );
}

export interface IInvoiceToolbar {
  editor?: ITransactionEditorAPI
  type?: TransactionType
}