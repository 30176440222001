import { ErrorAPI, TransactionOffering, TransactionOfferingOrphan, uuid } from "@bookie/glossary"
import { objectToCamel, objectToSnake, stripNullAndUndefinedFromObject } from "@bookie/utils"
import { SupabaseClient } from "@supabase/supabase-js"

export const _createTransactionOfferings = (sb: SupabaseClient): CreateTransactionOfferings => 
  async (
    transactionId,
    transactionOfferings
  ) => {

    const sanitisedOfferings = transactionOfferings.map(o => (
      stripNullAndUndefinedFromObject({
        ...o,
        transactionId
      })
    ));

    const { data, error } = (
      await sb
        .from("transaction_offerings")
        .insert(objectToSnake(sanitisedOfferings))
        .select()
    );

    if (error?.code) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      transactionOfferings: objectToCamel<TransactionOffering[]>(data)
    };

  }

export type CreateTransactionOfferings = (
  transactionId: uuid,
  transactionOfferings: TransactionOfferingOrphan[]
) => Promise<CreateTransactionOfferingsResponse>

export interface CreateTransactionOfferingsResponse {
  transactionOfferings: TransactionOffering[]
}