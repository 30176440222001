import { TransactionType, Transaction, uuid } from "@bookie/glossary"

export const inferTransactionType = (tx: Transaction, userEntityId?: uuid): TransactionType => {
  if (tx.sourceId === userEntityId) {
    return "expense"
  } else if (tx.destinationId === userEntityId) {
    return "income"
  } else {
    return "unknown"
  }
}