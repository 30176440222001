import { Contact, ErrorAPI, uuid } from "@bookie/glossary"
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel } from "@bookie/utils";

export const _getContact = (sb: SupabaseClient): GetContact => 
  async (contactId) => {

    const { data, error } = (
      await sb
        .from("contact")
        .select()
        .eq("id", contactId)
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      contact: objectToCamel<Contact>(data[0])
    };

  }

export type GetContact = (
  contactId: uuid
) => Promise<GetContactResponse>

export interface GetContactResponse {
  contact: Contact
}