/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { IInput, InputBase } from "../base";
import { InputText } from "../text";
import css from "./index.module.css";
import cx from "classnames";
import { IconDown, IconUp } from "../../icon";

export const InputSelect: React.FC<IInputSelect> = (props) => {

  const inputSelectRef = React.useRef<HTMLDivElement>(null);

  const [ isOpen, setIsOpen ] = React.useState<boolean>(false);

  const [ searchKey, setSearchKey ] = React.useState<string>("");

  const handleToggleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (inputSelectRef.current && !inputSelectRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => { document.removeEventListener('click', handleDocumentClick); };
  }, []);

  // For toggleState, if is true, then 
  // it means that we need to select the option. 
  // If it is false, then it means 
  // we need to deselect the option. 

  const handleSelect = (option: any, depth: number, toggleState: boolean, parent?: any) => {
    if (props.multiSelect) {

      // If we are selecting, 
      // then we can just call the `onSelect` fn

      if (toggleState) {
        
        props.onSelect(option, depth);

        // we need to check the depth,
        // because if we are selecting a parent,
        // we want to select all the children. 

        if (depth === 0 && option.children && option.children.length > 0) {
          
          const matchKey = props.matchKeyChild || props.matchKey;
          
          const toSelect = option.children.filter((childOption: any) => {
            const isAlreadySelected = props.value.some((selectedOption: any) => selectedOption[matchKey] === childOption[matchKey]);
            if (!isAlreadySelected) {
              return true;
            }
            return false;
          });

          props.onSelect(toSelect, 1);

        }

        // if however we are selecting a child,
        // we just need to make sure the parent 
        // is also selected. 

        else {
          const isParentSelected = props.value.some((selectedOption: any) => selectedOption[props.matchKey] === parent?.[props.matchKey]);
          if (parent && !isParentSelected) {
            props.onSelect(parent, 0);
          }
        }

      }

      // but if we are deselecting,
      // then we can just call the `onDeSelect` fn

      else {

        if (!props.onDeSelect) {
          // need to throw some sort of error here
        }

        props.onDeSelect?.(option, depth);

        // we need to check the depth,
        // because if we are deselecting a parent,
        // we want to deselect all the children

        if (depth === 0 && option.children && option.children.length > 0) {
          
          const matchKey = props.matchKeyChild || props.matchKey;

          const toDeselect = option.children.filter((childOption: any) => {
            const isAlreadySelected = props.value.some((selectedOption: any) => selectedOption[matchKey] === childOption[matchKey]);
            if (isAlreadySelected) {
              return true;
            }
            return false;
          });
          
          props.onDeSelect?.(toDeselect, 1);

        }

        // if however we are deselecting a child,
        // we just need to make sure that we deselect 
        // the parent if it has no other selected 
        // children.
        // We can safely assume that the parent 
        // is already selected.  

        else {

          const isAnotherChildSelected = parent?.children.some((child: any) => {

            const matchKey = props.matchKeyChild || props.matchKey;
            
            // we can ignore our current one, as we know it is
            // going to be deselected. 

            if (child[matchKey] === option[matchKey]) {
              return false;
            };

            return props.value.some((opt: any) => opt[matchKey] === child[matchKey]);

          });

          if (!isAnotherChildSelected) {
            props.onDeSelect?.(parent, 0);
          }
        }

      }

    } else {
      props.onSelect(option);
      setIsOpen(false);
    }
  
  };

  const renderOptions = (options: any[], depth = 0, parent?: any) => {

    let _options = options;
    if (props.searchKey && searchKey) {
      _options = options.filter(o => props.searchKey && o[props.searchKey].toLowerCase().includes(searchKey.toLowerCase()));
    }

    return _options.map((option: any, i: number) => {

      const matchKey = (
        depth === 0 
          ? props.matchKey
          : props.matchKeyChild || props.matchKey
      );

      const isSelected =
        matchKey && Array.isArray(props.value)
          ? props.value.some((selectedOption) => selectedOption[matchKey] === option[matchKey])
          : props.value === option;

      const hasChildren = Array.isArray(option.children) && option.children.length > 0;
      const hasCheckbox = props.multiSelect;

      return (
        <div
          key={i}
          className={cx(
            css.inputSelect_options_option,
            isSelected && !props.multiSelect ? css.inputSelect_options_option_isSelected : "",
            hasChildren ? css.inputSelect_options_option_hasChildren : ""
          )}
          onClick={(event) => {
            event.stopPropagation();
            handleSelect(option, depth, !isSelected, parent);
          }}
        >
          <label
            className={ css.inputSelect_options_option_label }>
            {hasCheckbox && <input type="checkbox" checked={isSelected} readOnly />}
            {props.formatLabel?.(option)}
          </label>

          {hasChildren && (
            <div className={css.inputSelect_nestedOptions}>
              {renderOptions(option.children, depth + 1, option)}
            </div>
          )}

        </div>
      );
    });
  };

  return (
    <InputBase 
      { ...props }>

      {
        props.mode === "read-only" && 
          <p>{ props.formatLabel?.(props.value) }</p>
      }

      {
        (props.mode === "edit" || !props.mode) && 
          <div
            id={`${props.name}`}
            style={
              props.onlyOptions
                ? { "---hny-input-h": "0px" } as { [key: string]: string }
                : {} as { [key: string]: string }
            }
            className={ cx(
              css.inputSelect,
              "y input_face align-y-center"
            ) }
            ref={inputSelectRef}
          >
            
            {
              !props.onlyOptions && 
              <div
                id={`${props.name}-value`}
                onClick={ handleToggleOpen }
                className={cx(
                  css.inputSelect_face,
                  isOpen ? "" : "",
                  props.value?.length > 0 && props.multiSelect ? css.inputSelect_face_hasSelected : "", 
                  !props.multiSelect && props.value ? css.inputSelect_face_hasSelected : "",
                  props.classNameInput,
                  "x space-inline-children hvr-icon-down"
                )}
              >
                <label
                  style={{ cursor: "pointer" }}>
                  {
                    props.multiSelect 
                      ? (
                        props.value?.length > 0
                          ? (
                            <span
                              className={ css.inputSelect_face_label_selected }>
                              { props.formatSelectedLabel?.(props.value[0]) || props.formatLabel?.(props.value[0]) }
                              { props.value.length > 1 ? ` (+${ props.value.length - 1 })` : '' }
                            </span>
                          )
                          : (
                            <span 
                              className={css.inputSelect_face_label_placeholder}>
                              { props.placeholder || "Click here to select an option" }
                            </span>
                          )
                      )
                      : props.value 
                        ? (
                          <span
                            className={ css.inputSelect_face_label_selected }>
                            { props.formatSelectedLabel?.(props.value) || props.formatLabel?.(props.value) } 
                          </span>
                        )
                        : (
                          <span 
                            className={css.inputSelect_face_label_placeholder}>
                            { props.placeholder || "Click here to select an option" }
                          </span>
                        )
                  }
                </label>
                
                { 
                  isOpen 
                    ? <div className={ "hvr-icon" }><IconUp /></div> 
                    : <div className={ "hvr-icon" }><IconDown /></div>
                }

              </div>
            }

            <div
              id={`${props.name}-options`}
              className={cx(
                css.inputSelect_options,
                isOpen || props.forceOpen ? css.inputSelect_options_isOpen : "",
                css[`inputSelect_options_${ props.optionsAlign || "right" }`]
              )}
            >

              {
                props.searchKey && 
                  <div
                    className={ cx(
                      css.inputSelect_options_actions, 
                      "x align-x-between align-y-center"
                    ) }>
                    
                    {
                      props.searchKey &&
                        <InputText
                          placeholder={ "Search" }
                          size={ "small" }
                          type={ "text" }
                          value={ searchKey }
                          backgroundType={ "none" }
                          onChange={ (v) => setSearchKey(v as string) } />
                    }

                    {
                      props.actions && 
                        <div
                          className={ "x space-inline-children" }>
                          {
                            props.actions.map(a => (
                              <div
                                className={ cx(
                                  css.inputSelect_options_actions_action,
                                  "clickable"
                                ) }
                                onClick={ a.onClick }>
                                { a.label }
                              </div>
                            )) 
                          }
                        </div>
                    }
                    
                  </div>
              }

              {
                props.onClear && 
                  <div
                    className={ css.inputSelect_options_actions }>
                    <div
                      onClick={ props.onClear }
                      className={ cx(
                        css.inputSelect_options_actions_clear,
                        "clickable"
                      ) }>
                      { "Clear filters" }
                    </div>
                  </div>
              }

              {
                (props.options && props.options.length === 0 && props.noOptionsCopy) &&
                  <div
                    className={ css.inputSelect_options_noOptions }>
                    <p>{ props.noOptionsCopy }</p>
                  </div>  
              }

              {renderOptions(props.options || [])}

            </div>
          </div>
      }

    </InputBase>
  );
};

export interface IInputSelect extends IInput {
  options?: any[];
  value?: any;
  formatLabel: (option: any) => React.ReactNode;
  formatSelectedLabel?: (option: any) => React.ReactNode;
  onSelect: (option: any, depth?: number) => void;
  onDeSelect?: (option: any, depth?: number) => void;
  onClear?: () => void;
  classNameInput?: string;
  multiSelect?: boolean;
  matchKey: string;
  matchKeyChild?: string;
  searchKey?: string;
  actions?: IInputSelectAction[]
  optionsAlign?: "left" | "right"
  forceOpen?: boolean
  onlyOptions?: boolean
  noOptionsCopy?: string
}

export interface IInputSelectAction {
  label: string 
  onClick: () => void
}