import { ErrorAPI, Payment } from "@bookie/glossary"
import { objectToCamel } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js"

export const _deleteTransactionPayments = (sb: SupabaseClient): DeleteTransactionPayments => 
  async (
    transactionPayments
  ) => {

    const { data, error } = (
      await sb
        .from("transaction_payments")
        .delete()
        .in(
          "payment_id", 
          transactionPayments.map(to => to.id)
        )
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }    

    if (data === null) {
      throw new ErrorAPI({});
    }

    const { data: _p, error: error2 } = (
      await sb
        .from("payment")
        .delete()
        .in(
          "id", 
          transactionPayments.map(to => to.id)
        )
        .select()
    );

    if (error2) {
      throw new ErrorAPI({});
    }    

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      transactionPayments: objectToCamel<Payment[]>(_p)
    };

  }

export type DeleteTransactionPayments = (
  transactionPayments: Payment[]
) => Promise<DeleteTransactionPaymentsResponse>

export interface DeleteTransactionPaymentsResponse {
  transactionPayments: Payment[]
}