import React from "react";
import { TransactionExpanded, TransactionExpandedSegment, TransactionSegments } from "@bookie/glossary";
import { segmentTransactions } from "../fns/segment-transactions";
import { ITransactionFilters } from "./use-transaction-filter";
import { useIdentity } from "@bookie/module-identity";
import { useCore } from "@bookie/module-core";

export const useTransactionSegmentation = (
  transactions: TransactionExpanded[] = [],
  filter: ITransactionFilters
): ITransactionSegmentation => {

  const { ownership } = useIdentity();
  const { entities } = useCore();

  const [ segment, setSegment ] = React.useState<TransactionSegments>("segment_by_month");

  const isUnableToSegment = (
    (filter.entityId && segment === "segment_by_entity") ||
    (filter.period === "this_month" && segment === "segment_by_month")
  );

  const segmentedTransactions = React.useMemo(
    () => {
      if (isUnableToSegment) {
        return [{ transactions }];
      } else {
        return segmentTransactions(
          transactions, 
          segment, 
          "income", 
          ownership.current?.entity, 
          filter.period, 
          entities.all
        )
      }
    },
    [
      filter.period, 
      transactions, 
      isUnableToSegment, 
      ownership, 
      segment, 
      entities
    ]
  );

  // const incomeSegmented = React.useMemo(
  //   () => {
  //     if (isUnableToSegment) {
  //       return [{ transactions: income }];
  //     } else {
  //       return segmentTransactions(
  //         income, 
  //         segment, 
  //         "income", 
  //         ownership.current?.entity, 
  //         filter.period, 
  //         entities.all
  //       )
  //     }
  //   },
  //   [filter.period, income, isUnableToSegment, ownership, segment, entities]
  // );

  // const expensesSegmented = React.useMemo(
  //   () => {
  //     if (isUnableToSegment) {
  //       return [{ transactions: expenses }];
  //     } else {
  //       return segmentTransactions(expenses, segment, "expense", ownership.current?.entity?.currencyCode || "", undefined, entities.all)
  //     }
  //   },
  //   [expenses, isUnableToSegment, ownership, segment, entities]
  // )

  return {
    segmentedTransactions,
    segment: {
      isUnableToSegment,
      segmentation: segment,
      segmentBy: setSegment
    }
  }

}

export interface ITransactionSegmentation {
  segmentedTransactions: TransactionExpandedSegment[]
  segment: ITransactionSegmentationSegment
}

export interface ITransactionSegmentationSegment {
  isUnableToSegment: boolean
  segmentation: TransactionSegments
  segmentBy: (s: TransactionSegments) => void
}