import { SupabaseClient } from "@supabase/supabase-js";
import { ErrorAPI, Transaction, TransactionOrphan, TransactionType } from "@bookie/glossary";
import { objectToCamel, objectToSnake, stripNullAndUndefinedFromObject } from "@bookie/utils";

export const _createTransaction = (sb: SupabaseClient): CreateTransaction => 
  async (tx, type) => {

    const _sanitised = objectToSnake(stripNullAndUndefinedFromObject(tx));

    const { data, error } = (
      await sb
        .from('transaction')
        .insert([
          _sanitised
        ])
        .select()
    );

    const _transaction = data?.[0];

    if (error?.code) {
      throw new ErrorAPI({});
    }

    if (!_transaction.id) {
      throw new ErrorAPI({});
    }

    return {
      transaction: objectToCamel<Transaction>(_transaction)
    };

  }

export type CreateTransaction = (
  tx: TransactionOrphan,
  type: TransactionType
) => Promise<CreateTransactionResponse>

export interface CreateTransactionResponse {
  transaction: Transaction
}