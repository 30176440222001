import { timestamptz, TransactionExpanded, TransactionFilterPeriods } from "@bookie/glossary";
import { isSameDay, startOfMonth, endOfMonth, startOfYear, endOfYear, subYears, isWithinInterval, parseISO } from 'date-fns';

export const filterByPeriod = (
  txs: TransactionExpanded[], 
  key: TransactionFilterPeriods,
  opts: FilterByPeriodOpts = { invoiceDate: true, deferredDate: true }
): TransactionExpanded[] => {

  const today = new Date();

  switch (key) {

    case "today": {
      return txs.filter(tx => {
        if (
          (opts.invoiceDate && isToday(tx.invoiceDate)) || 
          (opts.deferredDate && isToday(tx.deferredDate))
        ) {
          return true;
        }
        return false;
      });
    }
      
    case "this_month": {
      const _start = startOfMonth(today);
      const _end = endOfMonth(today);
      return txs.filter(tx => {
        if (
          (opts.invoiceDate && tx.invoiceDate && isWithinInterval(parseISO(tx.invoiceDate), { start: _start, end: _end })) ||
          (opts.deferredDate && tx.deferredDate && isWithinInterval(parseISO(tx.deferredDate), { start: _start, end: _end }))
        ) {
          return true;
        } 
        return false;
      });
    }
    
    case "this_year": {
      const _start = startOfYear(today);
      const _end = endOfYear(today);
      return txs.filter(tx => {
        if (
          (opts.invoiceDate && tx.invoiceDate && isWithinInterval(parseISO(tx.invoiceDate), { start: _start, end: _end })) ||
          (opts.deferredDate && tx.deferredDate && isWithinInterval(parseISO(tx.deferredDate), { start: _start, end: _end }))
        ) {
          return true;
        }
        return false;
      });
    }

    case "last_year": {
      const lastYear = subYears(today, 1);
      const _start = startOfYear(lastYear);
      const _end = endOfYear(lastYear);
      return txs.filter(tx => {
        if (
          (opts.invoiceDate && tx.invoiceDate && isWithinInterval(parseISO(tx.invoiceDate), { start: _start, end: _end })) ||
          (opts.deferredDate && tx.deferredDate && isWithinInterval(parseISO(tx.deferredDate), { start: _start, end: _end }))
        ) {
          return true;
        } 
        return false;
      });
    }
    
    case "all_time":
    default:
      return txs;
  }
}

export const isToday = (date?: timestamptz):boolean => {
  const today = new Date();
  return (typeof date !== "undefined" && isSameDay(parseISO(date), today)); 
} 

export interface FilterByPeriodOpts {
  invoiceDate: boolean
  deferredDate: boolean
}