import { ErrorValidation, Payment, PaymentOrphan, Transaction, TransactionOffering, TransactionOfferingOrphan, TransactionType } from "@bookie/glossary";
import { SupabaseClient } from "@supabase/supabase-js";
import { _createTransactionOfferings } from "./_create-transaction-offerings";
import { _deleteTransactionOfferings } from "./_delete-transaction-offerings";
import { _updateTransactionOfferings } from "./_update-transaction-offerings";
import { _updateTransaction } from "./_update-transaction";
import { validateTransactionOfferings } from "../fns/validate-transaction-offerings";
import { validateTransaction } from "../fns/validate-transaction";
import { validateTransactionPayments } from "../fns/validate-transaction-payments";
import { _createTransactionPayments } from "./_create-transaction-payments";
import { _deleteTransactionPayments } from "./_delete-transaction-payments";
import { _updatePayments } from "./_update-payment";

export const _updateTransactionExpanded = (sb: SupabaseClient): UpdateTransactionExpanded => 
  async (
    tx,
    transactionType,
    transactionOfferingsToCreate,
    transactionOfferingsToUpdate,
    transactionOfferingsToDelete,
    paymentsToCreate,
    paymentsToUpdate,
    paymentsToDelete
  ) => {

    // Because this is a multi-layered API endpoint
    // that creates related objects,
    // we will front-load the validation
    // before creating anything. 

    if (transactionOfferingsToCreate && transactionOfferingsToCreate.length > 0) {
      const validation = validateTransactionOfferings(transactionOfferingsToCreate);
      if (!validation.isValid) {
        throw new ErrorValidation({
          name: "transaction_offerings",
          errorsArray: validation.errors
        });
      }
    }

    if (transactionOfferingsToUpdate && transactionOfferingsToUpdate.length > 0) {
      const validation = validateTransactionOfferings(transactionOfferingsToUpdate);
      if (!validation.isValid) {
        throw new ErrorValidation({
          name: "transaction_offerings",
          errorsArray: validation.errors
        });
      }
    }

    if (paymentsToCreate && paymentsToCreate.length > 0) {
      const validation = validateTransactionPayments(paymentsToCreate);
      if (!validation.isValid) {
        throw new ErrorValidation({
          name: "transaction_payments",
          errorsArray: validation.errors
        });
      }
    }

    if (paymentsToUpdate && paymentsToUpdate.length > 0) {
      const validation = validateTransactionPayments(paymentsToUpdate);
      if (!validation.isValid) {
        throw new ErrorValidation({
          name: "transaction_payments",
          errorsArray: validation.errors
        });
      }
    }

    const validation = validateTransaction(tx, transactionType);
    if (!validation.isValid) {
      throw new ErrorValidation({
        name: "transaction",
        errors: validation.errors
      });
    }

    // If we're here, then it means everything 
    // has been validated and we can start 
    // the process. 

    let transactionOfferings: TransactionOffering[] = [];

    if (transactionOfferingsToCreate && transactionOfferingsToCreate.length > 0) {
      const { transactionOfferings: _to } = await _createTransactionOfferings(sb)(
        tx.id,
        transactionOfferingsToCreate
      );
      transactionOfferings = [
        ...transactionOfferings,
        ..._to
      ];
    }

    if (transactionOfferingsToDelete && transactionOfferingsToDelete.length > 0) {
      const { transactionOfferings: _to } = await _deleteTransactionOfferings(sb)(transactionOfferingsToDelete);
      transactionOfferings = [
        ...transactionOfferings,
        ..._to
      ];
    }

    if (transactionOfferingsToUpdate && transactionOfferingsToUpdate.length > 0) {
      const { transactionOfferings: _to } = await _updateTransactionOfferings(sb)(transactionOfferingsToUpdate);
      transactionOfferings = [
        ...transactionOfferings,
        ..._to
      ];
    }

    let payments: Payment[] = [];

    if (paymentsToCreate && paymentsToCreate.length > 0) {
      const { transactionPayments: _p } = await _createTransactionPayments(sb)(
        tx.id,
        paymentsToCreate
      );
      payments = [
        ...payments,
        ..._p
      ];
    }

    if (paymentsToDelete && paymentsToDelete.length > 0) {
      await _deleteTransactionPayments(sb)(paymentsToDelete);
      // payments = [
      //   ...payments,
      //   ..._p
      // ];
    }

    if (paymentsToUpdate && paymentsToUpdate.length > 0) {
      const { payments: _p } = await _updatePayments(sb)(paymentsToUpdate);
      payments = [
        ...payments,
        ..._p
      ];
    }

    const { transaction } = await _updateTransaction(sb)(tx, transactionType);

    // TODO 
    // Add some proper error handling here.
    
    return {
      transaction,
      transactionOfferings,
      payments
    };

  }

export type UpdateTransactionExpanded = (
  tx: Transaction,
  transactionType: TransactionType,
  transactionOfferingsToCreate?: TransactionOfferingOrphan[],
  transactionOfferingsToUpdate?: TransactionOffering[],
  transactionOfferingsToDelete?: TransactionOffering[],
  paymentsToCreate?: PaymentOrphan[],
  paymentsToUpdate?: Payment[],
  paymentsToDelete?: Payment[]
) => Promise<UpdateTransactionExpandedResponse>

export interface UpdateTransactionExpandedResponse {
  transaction: Transaction
  transactionOfferings: TransactionOffering[]
  payments: Payment[]
}