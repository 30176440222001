import React from "react"
import css from "./index.module.css"
import cx from "classnames"
import { Amount, ChartPie } from "@bookie/components";
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";
import { useIdentity } from "@bookie/module-identity";
import { getCountry } from "@bookie/module-core";
import { useCurrency } from "@hny_/currency";

export const TransactionSummary: React.FC<ITransactionSummary> = (props) => {

  const { ownership } = useIdentity();
  const curr = useCurrency();

  return (
    <div 
      style={{ paddingLeft: "100px", paddingRight: "100px", position: "relative", height: "160px" }}
      className={ cx(
        css.transactionSummary,
        "x border-b border-t space-lines-frame-tb space-lines-children align-x-between align-y-center"
      ) }>

        {
          props.editor?.type !== "expense" && 
            <div
              style={{ width: "350px", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}
              className={ "x align-x-center align-y-center" }>

              <ChartPie
                data={[
                  { 
                    name: "Net",
                    label: `${ curr.toHumanReadable(props.editor?.summary.net || 0) }`, 
                    value: props.editor?.summary.net || 0, 
                    fill: "#20783a" 
                  },
                  { 
                    name: "Costs",
                    label: `${ curr.toHumanReadable(props.editor?.summary.cost || 0) }`, 
                    value: props.editor?.summary.cost || 0,
                    fill: "#a50f0d" 
                  }
                ]} />

            </div>
        }

        <div></div>

        {/* <Amount
          variant={ "inline" }
          onlyShowLocalised={ true }
          label={ "Cost" }
          amount={ props.editor?.summary.cost || 0 }
          currency={ props.editor?.data.currencyCode || "" }
          locale={( getCountry(ownership.current?.entity?.countryCode)?.default_locale ) || ""}
          localisedAmount={ props.editor?.summary?.localised?.cost }
          localisedCurrency={ ownership.current?.entity?.currencyCode }
          type={ "expense" } /> */}
          
        <div
          className={ "y align-x-center" }>
          
          <Amount
            variant={ "display" }
            amount={ props.editor?.summary.gross || 0 }
            currency={ props.editor?.data.currencyCode || "" }
            locale={( getCountry(ownership.current?.entity?.countryCode)?.default_locale ) || ""}
            localisedAmount={ props.editor?.summary?.localised?.gross || 0 }
            localisedCurrency={ ownership.current?.entity?.currencyCode || "" }
            type={ "income" }
            // isPending={ props.editor?.state.paymentState !== "paid_in_full" }
            isBad={ (
              props.editor?.state.paymentState === "bad_debt" ||
              props.editor?.data.invoiceState === "rejected" ||
              props.editor?.data.estimateState === "rejected" ||
              props.editor?.type === "expense"
            ) } />

        </div>
        
        {/* <Amount
          variant={ "inline" }
          onlyShowLocalised={ true }
          label={ "Net" }
          amount={ props.editor?.summary.net || 0 }
          currency={ props.editor?.data.currencyCode || "" }
          locale={( getCountry(ownership.current?.entity?.countryCode)?.default_locale ) || ""}
          localisedAmount={ props.editor?.summary?.localised?.net }
          localisedCurrency={ ownership.current?.entity?.currencyCode }
          type={ "income" } /> */}
        
        <div></div>

    </div>
  );
}

export interface ITransactionSummary {
  editor?: ITransactionEditorAPI
}