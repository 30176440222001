import React from "react";
import { InputSelect, IInput, IconCountry } from "@bookie/components";
import { COUNTRIES, Country, uuid } from "@bookie/glossary";
import { getCountry } from "../../fns/get-country";

export const SelectCountry: React.FC<ISelectCountry> = (props) => {
  return (
    <InputSelect
      { ...props }
      icon={ props.icon || <IconCountry /> }
      style={ props.style }
      className={ "fill-x" }
      optionsAlign={ props.optionsAlign }
      name={ "country" }
      label={ props.label }
      type={ "text" }
      options={ COUNTRIES }
      value={ getCountry(props.selectedCountryCode) }
      actions={ props.actions }
      searchKey={ "name" }
      formatLabel={ (c: Country) => {
        return (
          <div
            className={ "x space-inline-children align-y-center" }>
            <div>{ c.emoji }</div>
            <div>{ c.name }</div>
          </div>
        ); 
      } }
      matchKey={ "alpha3" }
      onSelect={ (c: Country) => props.onSelect?.(c) }
       
      labelPosition={ props.labelPosition }
      backgroundType={ props.backgroundType } />
  );
}

export interface ISelectCountry extends IInput {
  selectedCountryCode?: uuid
  onSelect?: (c: Country) => void
  actions?: { label: string, onClick: () => void }[]
  optionsAlign?: "left" | "right"
}