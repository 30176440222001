import { SupabaseClient } from "@supabase/supabase-js";
import { Entity, ErrorAPI } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";

export const _getEntities = (sb: SupabaseClient): GetEntities => 
  async () => {

    const { data, error } = (
      await sb
        .from("entity")
        .select(`*`)
        // .neq("type", "self")
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    const _entities = objectToCamel<Entity[]>(data);

    return {
      entities: _entities.filter(e => e.type !== "self")
    };

  };

export type GetEntities = () => Promise<GetEntitiesResponse>

export interface GetEntitiesResponse {
  entities: Entity[]
}