import React from "react"
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";
import { Bookie } from "@bookie/components";
import { TransactionAssistEssentials } from "./essentials";
import { TransactionAssistOfferings } from "./offerings";
import { TransactionAssistEstimateSend } from "./estimate-send";
import { TransactionAssistEstimateApprove } from "./estimate-approve";
import { ITransactionEditorLifecycle } from "../transaction-editor";
import { InferredTransactionStage, inferTransactionStage } from "../../fns/infer-transaction-stage";
import { TransactionAssistInvoiceEssentials } from "./invoice-essentials";
import { TransactionAssistInvoiceSend } from "./invoice-send";
import { TransactionAssistPayment } from "./payment";
import css from "./index.module.css";
import cx from "classnames";

export type TransactionAssistStages = 
  "required" |
  "offerings" |
  "stage" |
  "stage-send";

export const TransactionAssist: React.FC<ITransactionAssist> = (props) => {

  const [ step, setStep ] = React.useState<TransactionAssistStages>(
    !props.editor?.data.id
      ? "required"
      : "stage"
  );

  const stage: InferredTransactionStage | undefined = React.useMemo(
    () => inferTransactionStage(props.editor?.data, props.editor?.state.paymentState),
    [ props.editor?.data, props.editor?.state.paymentState ]
  );

  return (
    <div
      style={{ position: "relative" }}
      className={ "y surface-primary apply-bg x fill-x fill-y align-y-center" }>
      
      <div 
        className={ cx(
          css.helper,
          "x align-x-center space-lines-frame-tb"
        ) }>
        <Bookie
          isOpen={ false }
          message={ "Guiding you through.." }
          isHelping={ true }
          onClose={ () => props.onFinishAssist?.() } />
      </div>
      
      <div 
        style={{ width: "90%" }}
        className={ "y fill-y fill-x align-x-center align-y-center" }>
      
        {
          step === "required" && 
            <TransactionAssistEssentials
              editor={ props.editor } 
              transactionStage={ props.transactionStage }
              onNext={ () => {
                const isValid = props.editor?.validate(true, { ignoreDescription: true });
                if (isValid) {
                  setStep("offerings");
                }
              } } />
        }

        {
          step === "offerings" && 
            <TransactionAssistOfferings
              editor={ props.editor }
              transactionStage={ props.transactionStage }
              onBack={ () => {
                const result = props.editor?.related.transactionOfferingsEditor.validate();
                if (step === "offerings" && result) {
                  setStep("required");
                }
              } }
              onCreate={ async () => {
                const isValid = props.editor?.validate(true);
                if (isValid) {
                  const transaction = await props.editor?.commit();
                  if (typeof transaction === "object") {
                    if (props.transactionStage === "estimate") {
                      props.onEdit?.(transaction.id, "estimate");
                      setStep("stage");
                    } else if (props.transactionStage === "invoice") {
                      props.onEdit?.(transaction.id, "invoice");
                      if (props.editor?.type === "income") {
                        setStep("stage-send");
                      } else {
                        setStep("stage");
                      }
                    }
                    
                  }
                }
              }} />
        }

        {
          (step === "stage" && stage?.stage === "estimate" && stage?.status === "draft") && 
            <TransactionAssistEstimateSend
              editor={ props.editor }
              onFinish={ props.onFinish }
              onFinishAssist={ props.onFinishAssist }
              onAnother={ props.onAnother }
              onEdit={ props.onEdit } />
        }

        {
          (step === "stage" && stage?.stage === "estimate" && stage?.status === "submitted") && 
            <TransactionAssistEstimateApprove
              editor={ props.editor }
              onFinish={ props.onFinish }
              onFinishAssist={ props.onFinishAssist }
              onAnother={ props.onAnother }
              onEdit={ props.onEdit } />
        }

        {
          (step === "stage" && stage?.stage === "estimate" && stage?.status === "rejected") && 
            <div>{"Estimate Rejected"}</div>
        }

        {
          (step === "stage" && stage?.stage === "invoice" && stage?.status === "draft") && 
            <TransactionAssistInvoiceEssentials
              editor={ props.editor }
              onFinish={ props.onFinish }
              onFinishAssist={ props.onFinishAssist }
              onAnother={ props.onAnother }
              onEdit={ props.onEdit }
              onCreate={ async () => {
                const isValid = props.editor?.validate();
                if (isValid) {
                  await props.editor?.commit();
                  setStep("stage-send")
                }
              } } />
        }

        {
          (step === "stage-send") && 
            <TransactionAssistInvoiceSend
              editor={ props.editor }
              onFinish={ props.onFinish }
              onFinishAssist={ props.onFinishAssist }
              onAnother={ props.onAnother }
              onEdit={ props.onEdit }
              onCompleteStep={ () => setStep("stage") } />   
        }

        {
          (step === "stage" && stage?.stage === "payment") &&
            <TransactionAssistPayment
              editor={ props.editor }
              onFinish={ props.onFinish }
              onFinishAssist={ props.onFinishAssist }
              onAnother={ props.onAnother }
              onEdit={ props.onEdit } />
        }

      </div>

    </div>
  );
}

export interface ITransactionAssist extends ITransactionEditorLifecycle {
  editor?: ITransactionEditorAPI
  transactionStage?: "estimate" | "invoice"
}