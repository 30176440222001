import { EstimateState, InvoiceState, PaymentState, TransactionType } from "@bookie/glossary";
import React from "react";
import css from "./index.module.css";
import cx from "classnames";

export const TransactionStatus: React.FC<ITransactionStatus> = (props) => {
  return (
    <div
      style={{ width: "75px" }}
      className={ "x align-x-end" }>
        
      {
        props.type === "income" && 
          <>
            <div
              className={ cx(
                css.status,
                { [css.status_pending]: props.estimate === "submitted" },
                { [css.status_approved]: props.estimate === "approved" },
                { [css.status_rejected]: props.estimate === "rejected" },
                { [css.status_active]: props.estimate === "draft" },
                { [css.status_inactive]: !props.estimate || props.estimate === "n/a" }
              ) }>
            </div>

          <div
            className={ cx(
              css.status,
              { [css.status_approved]: props.invoice === "submitted" },
              { [css.status_rejected]: props.invoice === "rejected" },
              { [css.status_active]: props.invoice === "draft" },
              { [css.status_inactive]: !props.invoice },
            ) }>
          </div>
          </>
      }

      <div
        className={ cx(
          css.status,
          { [css.status_approved]: props.payment === "paid_in_full" },
          { [css.status_rejected]: props.payment === "bad_debt" || props.payment === "overdue" },
          { [css.status_active]: props.payment === "due" || props.payment === "paid_in_part" },
          { [css.status_inactive]: !props.payment },
        ) }>
      </div>

    </div>
  );
}

export interface ITransactionStatus {
  type?: TransactionType
  estimate?: EstimateState
  invoice?: InvoiceState
  payment?: PaymentState
}