import { SupabaseClient } from "@supabase/supabase-js";
import { ErrorAPI, UserConfiguration } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";

export const _getUserConfiguration = (sb: SupabaseClient): GetUserConfiguration =>
  async () => {

    const { data, error } = (
      await sb
        .from("user_configuration")
        .select("*")
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      configuration: objectToCamel<UserConfiguration>(data[0]),
      error
    };
  
  };

export type GetUserConfiguration = () => Promise<GetUserConfigurationResponse>

export interface GetUserConfigurationResponse {
  configuration: UserConfiguration
}