import { ObjectFieldValidation, TransactionOfferingOrphan, ObjectValidationArray } from "@bookie/glossary";

export const validateTransactionOfferings = 
  (
    transactionOfferings: TransactionOfferingOrphan[]
  ): ObjectValidationArray => {
  
  const errors: ObjectFieldValidation[] = [];

  for (const to of transactionOfferings) {

    let _errors: ObjectFieldValidation = {};

    // if (
    //   typeof to.id === "undefined"
    // )

    if (
      typeof to.quantity === "undefined" ||
      !Number(to.quantity)
    ) {
      _errors = {
        ..._errors,
        quantity: "Required"
      };
    }

    if (
      typeof to.name === "undefined" ||
      to.name === ""
    ) {
      _errors = {
        ..._errors,
        name: "Required"
      }
    }

    if (
      typeof to.grossSale === "undefined" ||
      !Number(to.grossSale)
    ) {
      _errors = {
        ..._errors,
        grossSale: "Required"
      }
    }

    if (
      typeof to.type === "undefined"
    ) {
      _errors = {
        ..._errors,
        type: "Required"
      }
    }

    if (Object.keys(_errors).length > 0) {
      errors.push(_errors);
    }

  }
  
  return {
    isValid: errors.length > 0 ? false : true,
    errors
  };

}