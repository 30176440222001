import React, { useState } from "react";
import { IInput, InputBase } from "../base";
import { IconRepeat, IconUpload } from "../../icon";
import { Button } from "../../button";

export const InputImage = React.forwardRef<HTMLInputElement, IInputImage>((props, ref) => {

  const [ previewUrl, setPreviewUrl ] = useState<string | null>(props.currentImageUrl || null);

  const fileInputRef = ref || React.createRef<HTMLInputElement>();

  const handleUploadClick = () => {
    if (fileInputRef && "current" in fileInputRef) {
      fileInputRef.current?.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    const _file = e.target.files?.[0];
    
    const acceptedTypes = props.accept?.split(',').map(type => type.trim()) || [];

    const isFileTypeSupported = (_file: File) => {
      // Get the file extension (e.g., 'jpg' from 'image.jpg')
      const fileExtension = _file.name.split('.').pop()?.toLowerCase();
      
      // Check if the file matches any of the accepted types (both MIME and extensions)
      return acceptedTypes.some(type => {
        const trimmedType = type.toLowerCase().trim();
        return (
          (trimmedType.startsWith('.') && fileExtension === trimmedType.substring(1)) || // Extension match
          _file.type.startsWith(trimmedType) // MIME type match
        );
      });
    };
    
    if (_file) {
      if (_file.size > 800 * 1024) {
        // If the file size exceeds the max limit (800KB), trigger onMaxFileSize
        props.onMaxFileSize?.();
      } else if (!isFileTypeSupported(_file)) {
        // If the file extension/type is not supported, trigger onUnsupportedFileType
        props.onUnsupportedFileType?.();
      } else {
        // File is valid, proceed with generating the preview URL and calling onChange
        const fileUrl = URL.createObjectURL(_file);
        setPreviewUrl(fileUrl);
        props.onChange?.(_file);
      }
    }

  };

  return (
    <InputBase {...props}>
      {(props.mode === "edit" || !props.mode) && (
        
        <div
          style={{ marginTop: "8px" }} 
          className={"x space-inline-frame-tb space-lines-children align-y-center"}>

          {
            (previewUrl) && (
              <>
                <img
                  style={{ height: "50px" }}
                  src={ previewUrl }
                  alt={"Logo"}
                />

                <Button
                  onClick={() => {
                    props.onChange?.(null);
                    setPreviewUrl(null);
                  }}
                  label={"Change"}
                  variant={ "text" }
                  icon={<IconRepeat size={24} />}
                />
              </>
            )
          }

          {
            !previewUrl && (
              <Button
                onClick={handleUploadClick}
                label={"Upload from your computer"}
                variant={ "inline" }
                icon={<IconUpload size={24} />}
              />
            )
          }

          <input
            ref={fileInputRef}
            type={"file"}
            accept={props.accept || "image/*"}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />

        </div>
      )}
    </InputBase>
  );
});

export interface IInputImage extends IInput {
  value?: File;
  accept?: string;
  currentImageUrl?: string;
  onMaxFileSize?: () => void;
  onUnsupportedFileType?: () => void
}
