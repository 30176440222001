import React from "react";
import { TransactionExpanded } from "@bookie/glossary";
import { inferExpenseTransactions } from "../fns/infer-expense-transactions";
import { inferIncomeTransactions } from "../fns/infer-income-transactions";
import { useIdentity } from "@bookie/module-identity";

export const useTransactionInference = (
  transactions: TransactionExpanded[] = []
): ITransactionInference => {

  const { ownership } = useIdentity();

  const { income, expenses } = React.useMemo(
    () => ({
      income: inferIncomeTransactions(transactions, ownership.current?.entity?.id),
      expenses: inferExpenseTransactions(transactions, ownership.current?.entity?.id)
    }),
    [ transactions, ownership ]
  );

  return {
    income,
    expenses
  };

}

export interface ITransactionInference {
  income: TransactionExpanded[]
  expenses: TransactionExpanded[]
}