import { Payment } from "@bookie/glossary";

export const summarisePayments = (
  payments: Payment[],
  localCurrencyCode: string
): number => {
  return payments.reduce(
    (sum, payment) => {

      const gross = (
        payment.currencyCode === localCurrencyCode
          ? payment.gross || 0
          : payment.grossLocalised || 0
      );

      return sum += gross;

    },
    0
  );
}