import { ErrorAPI, uuid } from "@bookie/glossary";
import { objectToSnake } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js";

export const _claimEntityForOrg = (sb: SupabaseClient): ClaimEntityForOrg => 
  async (entityId, orgId) => {

    const { data, error } = (
      await sb 
        .from("org")
        .update(objectToSnake({ entityId }))
        .eq("id", orgId) // We might be able to handle via RLS for added security
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }
    
    return

  };

export type ClaimEntityForOrg = (
  entityId: uuid,
  orgId: uuid
) => Promise<void>