import { IconNotes, InputTextArea, Panel } from "@bookie/components";
import React from "react";
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";

export const TransactionNotes: React.FC<ITransactionNotes> = (props) => {
  return (
    <Panel
      label={ "Notes" }
      icon={ <IconNotes /> }>
      <InputTextArea
        className={ "fill-x" }
        value={ props.editor?.data.notes }
        onChange={ v => props.editor?.edit.notes(v as string) } />
    </Panel> 
  );
}

export interface ITransactionNotes {
  editor?: ITransactionEditorAPI
}