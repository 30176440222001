import { SupabaseClient, PostgrestError } from "@supabase/supabase-js";
import { objectToCamel } from "@bookie/utils";
import { ErrorAPI, OrgExpanded } from "@bookie/glossary";

export const _getUserOrgs = (sb: SupabaseClient): GetUserOrgs =>
  async (userId) => {
    
    const { data, error } = (
      await sb
        .from("orgs_users")
        .select(`
          org (
            *,
            entity:org_entity_id_fkey (
              *,
              address!entity_primary_address_fkey (*),
              contact!entity_primary_contact_fkey (*)
            ),
            template:org_template!org_template_id_fkey (*)
          )
        `)
        .eq("user_id", userId)
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }
    
    const _orgs = data.map((d) => d.org as unknown as OrgExpanded);

    return {
      orgs: objectToCamel<OrgExpanded[]>(_orgs)
    };
  
  };

export type GetUserOrgs = (userId: string) => Promise<GetUserOrgsResponse>

export interface GetUserOrgsResponse {
  orgs: OrgExpanded[]
  error?: PostgrestError | null
}