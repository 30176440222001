
export class ErrorValidation extends Error {

  override name: string
  errors?: ObjectFieldValidation
  errorsArray?: ObjectFieldValidation[]

  constructor(
    {
      name,
      errors,
      errorsArray
    }: 
    {
      name: string
      errors?: ObjectFieldValidation
      errorsArray?: ObjectFieldValidation[]
    }
  ) {
    super();
    this.name = name;
    this.errors = errors;
    this.errorsArray = errorsArray
  }

}

export interface ObjectValidation {
  isValid: boolean;
  errors: ObjectFieldValidation;
}

export interface ObjectValidationArray {
  isValid: boolean;
  errors: ObjectFieldValidation[]
}

export interface ObjectFieldValidation {
  [ field: string ]: string
}
