import React from "react";
import { PaymentState, Transaction, TransactionSummary, uuid } from "@bookie/glossary";
import { useTimezone } from "@hny_/timezone";
import { Amount, Tooltip } from "@bookie/components"; 
import css from "./index.module.css";
import cx from "classnames";
import { inferTransactionType } from "../../fns/infer-transaction-type";
import { useIdentity } from "@bookie/module-identity";
import { useCore, getCountry, getEntity } from "@bookie/module-core";
import { TransactionStatus } from "../transaction-status";

export const TransactionCard: React.FC<ITransactionCard> = (props) => {

  const timezone = useTimezone();
  const { ownership } = useIdentity();
  const { entities } = useCore();

  const transactionType = inferTransactionType(props.tx, ownership.current?.entity?.id);

  const clientEntity = (
    transactionType === "income"
      ? getEntity(props.tx.sourceId, entities.all)
      : getEntity(props.tx.destinationId, entities.all)
  );
  
  const clientCountry = getCountry(clientEntity?.countryCode);

  return (
    <div
      className={ cx(
        css.transactionCard,
        { [css.transactionCard_isSelected]: props.isSelected },
        "surface border-b x align-y-center space-lines-frame-lr space-lines-children align-x-between",
        // props.onClick ? "clickable" : "",
        { "gr-income": transactionType === "income" },
        { "gr-expense": transactionType === "expense" },
      ) }
      onClick={ () => props.onClick?.(props.tx.id) }>

      <div 
        style={{ flex: 0 }}
        className={ cx(
          css.transactionCard_cell,
          "x"
        ) }>
        <TransactionStatus
          type={ transactionType }
          estimate={ props.tx.estimateState }
          invoice={ props.tx.invoiceState }
          payment={ props.paymentState } />
      </div>
      
      <div 
        className={ cx(
          "y align-y-center",
          css.transactionCard_cell
        ) }>
        <div
          className={ "x align-y-baseline space-inline-children" }>
          <p><b>{ clientEntity?.name }</b></p>
          <div>{ clientCountry?.emoji }</div>
        </div>
        <p 
          style={{ fontSize: 12 }}
          className={ cx(
            "fill-x"
          ) }>
          { props.tx.description }
        </p>
      </div>

      <div 
        className={ cx(
          "y align-y-center align-x-end space-inline-children",
          css.transactionCard_cell
        ) }>
        
        <div 
          className={ "x space-lines-children align-y-center" }>

          {
            (props.summary?.cost || 0) > 0 && 
              <Tooltip
                message={ "This is the total expense associated with this income." }
                base={
                  <Amount
                    variant={ "aside" }
                    isBad={ true }
                    amount={ props.summary?.cost || 0 }
                    currency={ props.tx.currencyCode }
                    locale={ clientCountry?.default_locale || "en-MT" }
                    localisedAmount={ props.summary?.localised?.cost || 0 }
                    localisedCurrency={ ownership.current?.entity?.currencyCode || "" }
                    type={ transactionType } />
                } />
          }

          <Amount
            amount={ props.summary?.gross || 0 }
            currency={ props.tx.currencyCode }
            locale={ clientCountry?.default_locale || "en-MT" }
            localisedAmount={ props.summary?.localised?.gross || 0 }
            localisedCurrency={ ownership.current?.entity?.currencyCode || "" }
            type={ transactionType } />
        
        </div>
        
        <div 
          className={ "x space-inlinez-children align-y-center" }>
          
          
          {
            props.tx.deferredDate && 
              <div 
                className={ "surface-base surface-pill" }>
                { "Deferred" }
              </div>
          }

          {
            props.tx.invoiceState === "rejected" && 
              <div 
                style={{ padding: "3px 8px", textTransform: "uppercase", fontSize: "11px" }}
                className={ "surface-negative apply-bg apply-fg apply-border radius" }>
                { "Bad Debt" }
              </div>
          }

          {
            props.tx.invoiceState && props.tx.invoiceDate && 
            <p
              style={{ fontSize: 11, opacity: 0.6,  letterSpacing: "0.6px" }}>
              { timezone.toHumanReadable(props.tx.invoiceDate, "DD MMM YYYY") }
            </p>
          }

          {
            !props.tx.invoiceState && props.tx.estimateDate && 
            <p
              style={{ fontSize: 11, opacity: 0.6,  letterSpacing: "0.6px" }}>
              { timezone.toHumanReadable(props.tx.estimateDate, "DD MMM YYYY") }
            </p>
          }

        </div>
        
      </div> 

      

    </div>
  );
}

export interface ITransactionCard {
  tx: Transaction
  paymentState?: PaymentState
  summary?: TransactionSummary
  onClick?: (txId: uuid) => void
  isSelected?: boolean
}