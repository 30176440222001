import React from "react";
import { SelectEntities } from "@bookie/module-core";
import { useBookkeeping } from "../../use-bookkeeping";
import { Entity, EntityType } from "@bookie/glossary";

export const FilterByEntity: React.FC<IFilterByEntity> = (props) => {

  const { filter } = useBookkeeping();

  return (
    <SelectEntities
      size={ "small" }
      label={ props.label }
      selectedEntityId={ filter.entityId }
      actions={[
        { label: "Clear", onClick: () => filter.byEntityId("") }
      ]}
      onSelect={ (e: Entity) => filter.byEntityId(e.id) }
      entityType={ props.type } />
  );
}

export interface IFilterByEntity {
  label?: string
  type?: EntityType
}