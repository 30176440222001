import React from "react";
import { Button, Empty, IconAdd, IconTakeMe, Workbench } from "@bookie/components";
import { getPeriodStartAndEnd, ITransactionEditorConfig, TransactionEditor, TransactionFilter, useBookkeeping } from "@bookie/module-bookkeeping";
import { TransactionsReport } from "../../components/transactions-report";
import { useProfitAndLoss } from "../../use/use-profit-and-loss";
import { useNavigate } from "react-router-dom";
import css from "./index.module.css";
import cx from "classnames";
import { useCurrency } from "@hny_/currency";
import { getLocaleFromCountryCode } from "@bookie/module-core";
import { useIdentity } from "@bookie/module-identity";
import { format } from "date-fns";

export const ProfitAndLoss: React.FC<IProfitAndLoss> = (props) => {

  const navigate = useNavigate();
  const curr = useCurrency();

  const { ownership } = useIdentity();
  const { transactions, filter } = useBookkeeping(bk => bk.filter.byPeriod("this_year"));
  const pl = useProfitAndLoss(); 

  const isEmpty = (
    transactions.meta.totalIncome === 0
  );

  const [ editorConfig, setEditorConfig ] = React.useState<ITransactionEditorConfig>({});

  const range = getPeriodStartAndEnd(filter.period || "this_year");
  const start = range?.start && format(range?.start, "dd/MM/yyyy");
  const end = range?.end && format(range?.end, "dd/MM/yyyy");

  return (
    <div
      className={ "y fill-x" }>

      <div 
        style={{ position: "relative" }}
        className={ "x fill-x fill-y" }>
        
        <div 
          className={ "y fill-x surface" }>
          
          <TransactionFilter
            filterByClient={ false }
            filterByMerchant={ false }
            actions={[
              <div
                className={ "x space-inlinez-children space-lines-frame-r" }>

                {
                  !isEmpty && 
                    <>
                      <Button
                        surface={ "surface-positive" }
                        icon={ <IconAdd /> }
                        onClick={ () => {
                          setEditorConfig({
                            transactionStage: "invoice",
                            transactionType: "income",
                            txId: "create"
                          });
                        } } />
                      
                      <Button
                        icon={ <IconAdd /> }
                        surface={ "surface-negative" }
                        onClick={ () => {
                          setEditorConfig({
                            transactionStage: "invoice",
                            transactionType: "expense",
                            txId: "create"
                          });
                        } } />
                    </>
                }

              </div>
            ]} />
          
          <div
            className={ "x fill" }>
            
            <div 
              style={{ width: "50%", maxWidth: "1200px" }}
              className={ "y fill-x border-r" }>
              <div
                style={{ height: "0" }}
                className={ "y surface-aside apply-bg apply-fg fill-y scrollable space-lines-frame space-lines-children" }>

                {
                  isEmpty && 
                    <Empty
                      label={ "This is where you can see your Profit & Loss." }
                      description={[]}
                      callToActions={
                        <div 
                          className={ "x space-lines-children space-lines-frame-t fill-x" }>

                          <Button
                            variant={ "inline" }
                            icon={ <IconTakeMe /> }
                            label={ "Take me to my Income and Expenses" } 
                            onClick={ () => navigate("/bookkeeping/income-&-expenses") } />
                        
                        </div>
                      } />
                }

                {
                  !isEmpty && 
                    <div 
                      className={ "x space-lines-frame" }>
                      <TransactionsReport
                        transactionSegments={ pl.all.segments } 
                        selectedTransactionId={ editorConfig?.txId }
                        onSelectTransaction={ (id) => setEditorConfig({ txId: id }) } />
                    </div>
                }

              </div>
            </div>

            <div 
              className={ "fill-y fill-x surface-alt space-cubes-frame y space-cubes-children apply-bg apply-fg" }>
              
              <div
                className={ "y space-lines-children" }>

                <div>
                  <h1>{ ownership.current?.entity?.name }</h1>
                  <h3>{ `Profit & Loss Statement` }</h3>
                </div>

                <p
                  style={{ gap: "12px" }}
                  className={ "x fill-x align-x-end " }>
                  <span><i>{ "From" }</i></span>
                  <span><b>{ start }</b></span>
                  <span><i>{ " to" }</i></span>
                  <span><b>{ end }</b></span>
                </p>

              </div>

              <div 
                className={ "y" }>
                
                <div 
                  className={ cx(
                    css.row_label,
                    "x border-b space-inline-frame-tb"
                  ) }>
                  <p>{ "Revenue" }</p>
                </div>

                <div 
                  className={ cx(
                    css.row_data,
                    "x border-b space-inline-frame-tb align-x-between"
                  ) }>  
                  <p>{ "Sales Revenue" }</p>
                  <p className={ "surface-positive apply-fg" }>
                    {
                      curr.toHumanReadable(
                        pl.all.summary.accrued?.gross || 0,
                        getLocaleFromCountryCode(ownership.current?.entity?.countryCode), 
                        ownership.current?.entity?.currencyCode
                      )
                    }
                  </p>
                </div>

                <div 
                  className={ cx(
                    css.row_data,
                    "x border-b space-inline-frame-tb align-x-between"
                  ) }>  
                  <p>{ "Profits from Currency Exchange" }</p>
                  <p className={ "surface-positive apply-fg" }>
                    {
                      curr.toHumanReadable(
                        pl.cx.profit.summary?.all?.gross || 0,
                        getLocaleFromCountryCode(ownership.current?.entity?.countryCode), 
                        ownership.current?.entity?.currencyCode
                      )
                    }
                  </p>
                </div>

                <div 
                  className={ cx(
                    css.row_summary,
                    "x border-b space-inline-frame-tb align-x-between"
                  ) }>  
                  <p>{ "Total Revenue" }</p>
                  <p className={ "surface-positive apply-fg" }>
                    {
                      curr.toHumanReadable(
                        (pl.cx.profit.summary?.all?.gross || 0) + (pl.all.summary.accrued?.gross || 0),
                        getLocaleFromCountryCode(ownership.current?.entity?.countryCode), 
                        ownership.current?.entity?.currencyCode
                      )
                    }
                  </p>
                </div>

              </div>

              <div 
                className={ "y" }>
                
                <div 
                  className={ cx(
                    css.row_label,
                    "x border-b space-inline-frame-tb"
                  ) }>
                  <p>{ "Expenses" }</p>
                </div>

                <div 
                  className={ cx(
                    css.row_data,
                    "x border-b space-inline-frame-tb align-x-between"
                  ) }>  
                  <p>{ "Expenses" }</p>
                  <p className={ "surface-negative apply-fg" }>
                    {
                      curr.toHumanReadable(
                        (pl.all.summary?.accrued?.cost || 0) + (pl.all.summary.expensesPaid?.gross || 0),
                        getLocaleFromCountryCode(ownership.current?.entity?.countryCode), 
                        ownership.current?.entity?.currencyCode
                      )
                    }
                  </p>
                </div>

                <div 
                  className={ cx(
                    css.row_data,
                    "x border-b space-inline-frame-tb align-x-between"
                  ) }>  
                  <p>{ "Losses from Currency Exchange" }</p>
                  <p className={ "surface-negative apply-fg" }>
                    {
                      curr.toHumanReadable(
                        pl.cx.loss.summary?.all?.cost || 0,
                        getLocaleFromCountryCode(ownership.current?.entity?.countryCode), 
                        ownership.current?.entity?.currencyCode
                      )
                    }
                  </p>
                </div>

                <div 
                  className={ cx(
                    css.row_summary,
                    "x border-b space-inline-frame-tb align-x-between"
                  ) }>  
                  <p>{ "Total Expenses" }</p>
                  <p className={ "surface-negative apply-fg" }>
                    {
                      curr.toHumanReadable(
                        (pl.all.summary?.accrued?.cost || 0) + (pl.all.summary.expensesPaid?.gross || 0) + (pl.cx.loss.summary?.all?.cost || 0),
                        getLocaleFromCountryCode(ownership.current?.entity?.countryCode), 
                        ownership.current?.entity?.currencyCode
                      )
                    }
                  </p>
                </div>

              </div>

              <div 
                className={ "y" }>

                <div 
                  className={ cx(
                    css.row_data,
                    "x border-b space-inline-frame-tb align-x-between"
                  ) }>  
                  <p>{ "Income before Tax" }</p>
                  <p className={ "surface-positive apply-fg" }>
                    {
                      curr.toHumanReadable(
                        (
                          (
                            (pl.cx.profit.summary?.all?.gross || 0) + 
                            (pl.all.summary.accrued?.gross || 0)
                          ) - 
                          (
                            (pl.all.summary?.accrued?.cost || 0) + 
                            (pl.all.summary.expensesPaid?.gross || 0) + 
                            (pl.cx.loss.summary?.all?.cost || 0)
                          )
                        ),
                        getLocaleFromCountryCode(ownership.current?.entity?.countryCode), 
                        ownership.current?.entity?.currencyCode
                      )
                    }
                  </p>
                </div>

                <div 
                  className={ cx(
                    css.row_data,
                    "x border-b space-inline-frame-tb align-x-between"
                  ) }>  
                  <p>{ "Income Tax" }</p>
                  <p className={ "surface-negative apply-fg" }>{ "-" }</p>
                </div>

                <div 
                  className={ cx(
                    css.row_summary,
                    "x border-b space-inline-frame-tb align-x-between"
                  ) }>  
                  <p>{ "Net Profit" }</p>
                  <p className={ "surface-negative apply-fg" }>
                    {
                      curr.toHumanReadable(
                        (
                          (
                            (pl.cx.profit.summary?.all?.gross || 0) + 
                            (pl.all.summary.accrued?.gross || 0)
                          ) - 
                          (
                            (pl.all.summary?.accrued?.cost || 0) + 
                            (pl.all.summary.expensesPaid?.gross || 0) + 
                            (pl.cx.loss.summary?.all?.cost || 0)
                          )
                        ),
                        getLocaleFromCountryCode(ownership.current?.entity?.countryCode), 
                        ownership.current?.entity?.currencyCode
                      )
                    }
                  </p>
                </div>

              </div>

            </div>
          
          </div>

        </div>

        <Workbench
          isOpen={ typeof editorConfig?.txId !== "undefined" }
          onClose={ () => setEditorConfig({}) }>
          {
            editorConfig?.txId && 
              <TransactionEditor 
                transactionType={ editorConfig.transactionType }
                txId={ editorConfig.txId }
                transactionStage={ editorConfig.transactionStage }
                onFinish={ () => setEditorConfig({}) } />
          }
        </Workbench>

      </div>

    </div>
  );
}

export interface IProfitAndLoss {}