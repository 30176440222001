import { TransactionFilterPeriods } from "@bookie/glossary";
import { startOfDay, endOfDay, startOfMonth, endOfMonth, startOfYear, endOfYear, subYears } from 'date-fns';

export const getPeriodStartAndEnd = (
  period: TransactionFilterPeriods
): { start?: Date, end?: Date } => {

  const now = new Date();

  switch (period) {
    case "today":
      return {
        start: startOfDay(now),
        end: endOfDay(now)
      };

    case "this_month":
      return {
        start: startOfMonth(now),
        end: endOfMonth(now)
      };

    case "this_year":
      return {
        start: startOfYear(now),
        end: endOfYear(now)
      };

    case "last_year": {
      const lastYear = subYears(now, 1);
      return {
        start: startOfYear(lastYear),
        end: endOfYear(lastYear)
      };
    }

    default:
      return {}
  }

}