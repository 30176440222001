import React from "react";
import css from "./index.module.css";
import cx from "classnames";

export const List = <T,>(props: IList<T>): React.ReactElement => {
  return (
    <ul 
      className={ cx(
        css.list,
        "y fill-x"
      ) }>
      {
        props.data?.map((d, index) => (
          <li 
            key={index}
            onClick={() => props.onClick?.(d) }
            className={cx(
              "clickable radius space-inline-frame",
              { [css.list_isSelected]: props.isSelected?.(d) }
            )}
          >
            {props.renderLabel(d)}
          </li>
        ))
      }
    </ul>
  );
}

export interface IList<T> {
  data?: T[]
  renderLabel: (d: T) => React.ReactNode
  onClick?: (d: T) => void
  isSelected?: (d: T) => boolean
}
