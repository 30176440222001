import React from "react";
import { useDebounce } from "@bookie/utils";
import { getExchangeRate } from "@bookie/oracle-cx";
import { timestamptz } from "@bookie/glossary";
import { useIdentity } from "@bookie/module-identity";

export const useLocalisation = (
  onUpdate: (exchangeRate: number) => void,
  currencyCode?: string,
  exchangeDate?: timestamptz,
  isManuallyLocalised?: boolean
): ILocalisation => {

  const { ownership } = useIdentity();

  const [ isLocalisingGross, setIsLocalisingGross ] = React.useState<boolean>(false);
  const [ requiresLocalisation, setRequiresLocalisation ] = React.useState<boolean>(false);

  // A simple check to figure out if 
  // we need to localise the currency 
  // of the transaction. 
  // If the currency code defined by the user's entity 
  // is different to the source or destination (client or supplier)
  // in this transaction then we will need to localise. 

  React.useEffect(() => {
    if (currencyCode && ownership.current?.entity?.currencyCode !== currencyCode) {
      setRequiresLocalisation(true);
    } else {
      setRequiresLocalisation(false);
    }
  }, [ ownership, currencyCode ]);

  //--- 

  const exchange_debounced = useDebounce(
    async () => {
      if (ownership.current?.entity?.currencyCode && currencyCode && currencyCode !== ownership.current?.entity?.currencyCode) {
        const { exchangeRate } = await getExchangeRate(currencyCode, ownership.current.entity.currencyCode, exchangeDate);
        onUpdate(exchangeRate);
        setIsLocalisingGross(false);
      }
    },
    1200
  );

  //---

  React.useEffect(() => {
    if (requiresLocalisation && !isManuallyLocalised && exchangeDate) {
      if (exchangeDate) {
        setIsLocalisingGross(true);
        exchange_debounced();
      } else {
        //
      }
    }
  }, [  
    exchangeDate,
    isManuallyLocalised, 
    requiresLocalisation,
    exchange_debounced 
  ]);

  //---

  return {
    exchange: exchange_debounced,
    state: {
      isLocalisingGross,
      requiresLocalisation
    }
  };

}

export interface ILocalisation {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exchange: (...args: any[]) => any
  state: ILocalisationState
}

export interface ILocalisationState {
  isLocalisingGross: boolean
  requiresLocalisation: boolean 
}