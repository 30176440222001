import React from "react";
import { Button, ButtonSize, IconDocument, IconSend, Workbench } from "../../../../components/src";
import { TransactionDocumentEditor } from "../../views/transaction-document-editor";
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";

export const SendInvoice: React.FC<ISendInvoice> = (props) => {

  const [ showDocument, setShowDocument ] = React.useState<boolean>(false);

  const clientLabel = props.editor?.related.entityEditor.data.name || "your Client";

  return (
    <>

      <Button 
        size={ props.size || "slim" }
        icon={(
          props.justOpen
            ? <IconDocument size={ 10 } />
            : <IconSend size={ 10 } /> 
        )}
        label={ props.label || `Send Invoice to ${ clientLabel }` }
        onClick={ () => {
          if (props.editor?.data) {
            
            props.editor.errors.setDirty();
            const isValid = props.editor.validate(true);

            if (props.editor.related.transactionOfferingsEditor.all.length === 0) {
              alert("You need to add at least one Product or Service first");
              return;
            }

            if (isValid && props.editor.related.transactionOfferingsEditor.all.length > 0) {
              setShowDocument(true);
            }
            
          }
        } } />

      <Workbench
        isOpen={ showDocument }
        onClose={ () => setShowDocument(false) }
        width={ "1200px" }>
        <TransactionDocumentEditor
          type={ "invoice" }
          editor={ props.editor }
          onClose={ () => setShowDocument(false) }
          onFinish={ async () => {
            props.editor?.edit.invoiceState("submitted");
            if (props.saveOnFinish) {
              await props.editor?.commit({ invoiceState: "submitted" });
              props.onComplete?.();
            }
          } } />
      </Workbench>
    
    </>
  );
}

export interface ISendInvoice {
  editor?: ITransactionEditorAPI
  size?: ButtonSize
  saveOnFinish?: boolean
  label?: string
  onComplete?: () => void
  justOpen?: boolean
}