import React from "react";
import { InputSelect } from "@bookie/components";
import { COUNTRIES, Country, uuid } from "@bookie/glossary";
import { getCountryFromPhone } from "../../fns/get-country-from-phone";

export const SelectCountryPhone: React.FC<ISelectCountryPhone> = (props) => {
  return (
    <InputSelect
      name={ "country" }
      label={ props.label }
      type={ "text" }
      options={ COUNTRIES }
      value={ getCountryFromPhone(props.selectedPhoneCountryCode) }
      actions={ props.actions }
      searchKey={ "name" }
      formatLabel={ (c: Country) => {
        return (
          <div
            className={ "x space-inline-children align-y-baseline" }>
            <div>{ c.emoji }</div>
            <div>{ c.name }</div>
            <div>({ c.phone })</div>
          </div>
        ); 
      } }
      matchKey={ "phone" }
      onSelect={ (c: Country) => props.onSelect?.(c.phone) }
      error={ props.error } />
  );
}

export interface ISelectCountryPhone {
  label?: string
  selectedPhoneCountryCode?: uuid
  onSelect?: (c?: string) => void
  actions?: { label: string, onClick: () => void }[]
  error?: string
}