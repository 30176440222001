import { ErrorAPI, OrgTemplate, OrgTemplateOrphan, uuid } from "@bookie/glossary";
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel, objectToSnake } from "@bookie/utils";

export const _createOrgTemplate = (sb: SupabaseClient): CreateOrgTemplate => 
  async (orgId, orgTemplate) => {

    const { data, error } = (
      await sb 
        .from("org_template")
        .insert({ id: orgId, ...objectToSnake(orgTemplate || {}) })
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      orgTemplate: objectToCamel<OrgTemplate>(data[0])
    }

  }

export type CreateOrgTemplate = (
  orgId: uuid,
  orgTemplate?: OrgTemplateOrphan
) => Promise<CreateOrgTemplateResponse>

export interface CreateOrgTemplateResponse {
  orgTemplate: OrgTemplate
}