import { Button, IconWave, InputText } from "@bookie/components";
import React from "react";
import { SelectCountry } from "../../components/select-country";
import { SelectCurrency } from "../../components/select-currency";
import { useOrgEditor } from "../../use/use-org-editor";
import { LogoFaceWhite, LogoFaceWhiteDown, LogoFaceWhiteSmile } from "@bookie/images";
import { useLocate } from "@bookie/oracle-location";
import css from "./index.module.css";

export const Onboarding: React.FC = () => {

  const editor = useOrgEditor();

  const [ hasBeenIntroduced, setHasBeenIntroduced ] = React.useState<boolean>(false);
  const [ isOnboarded, setIsOnboarded ] = React.useState<boolean>(false);

  useLocate(
    hasBeenIntroduced,
    (l) => {
      editor.related.entityEditor.edit.countryCode(l.countryCodeIso3);
    }
  );

  return (
    <div
      className={ "y fill-x fill-y surface-primary apply-bg apply-fg align-x-center align-y-center" }>

        <div 
          style={{ width: "500px" }}
          className={ "y space-surface-children" }>
          
          {
            !isOnboarded && !hasBeenIntroduced &&
              <div 
                className={ "y space-cubes-children align-x-center" }>

                  <div 
                    className={ "surface-primary-aside apply-bg apply-fg apply-border x align-x-center align-y-center" }
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      position: "relative",
                      padding: "18px"
                    }}>
                    <img 
                      style={{ position: "relative", top: "3px" }}
                      src={ LogoFaceWhiteSmile }
                      alt={ "Bookie" } />
                    <div 
                      className={ css.wave }
                      style={{ position: "absolute", left: "-25px", bottom: "2px"  }}>
                      <IconWave size={ 46 } />
                    </div>
                  </div>
                
                <div
                  className={ "x align-y-center space-inlinez-children" }>
                  <h1>
                    { "Hello! I'm" }
                    <span
                      style={{ color: "#ff5c22" }}>{ " Bookie." }</span>
                  </h1>
                </div>

                <div 
                  className={ "y space-lines-children" }>
                    <h2 
                  style={{ textAlign: "center" }}>
                  { "I am here to help you with your business' bookkeeping." }
                </h2>

                <h2 
                  style={{ textAlign: "center" }}>
                  { "Whenever you see me and you're not sure what to do next -- just click on me and I'll help you with what's next." }
                </h2>
                </div>

                <div
                  className={ "y space-inline-children align-x-center" }
                  style={{ textAlign: "center" }}>
                  
                  <Button
                    onClick={ () => setHasBeenIntroduced(true) }
                    className={ "fill-x" }
                    label={ "Let's get you setup as quickly as possible." } 
                    isWorking={ editor.state.isCreatingOrg } />

                  <p
                    style={{ width: "70%" }}>
                    { "You can continue filling in the rest of your information later." }
                  </p>
                </div>

              </div> 
          }

          {
            !isOnboarded && hasBeenIntroduced &&
              <>

                <div 
                  className={ "y space-lines-children align-x-center" }>
                  <img 
                    height={ "40px" }
                    style={{ position: "relative", top: "3px" }}
                    src={ LogoFaceWhiteDown }
                    alt={ "Bookie" } />
                </div> 
                
                <form
                  className={ "y space-lines-children" }>

                  <InputText
                    style={{
                      "--hny-input-w": "100%" 
                    } as React.CSSProperties}
                    label={ "Name of your Company" }
                    description={ "If you are registered as an individual, just enter your full name." }
                    backgroundType={ "fill" }
                    value={ editor.related.entityEditor.data.name }
                    onChange={ v => editor.related.entityEditor.edit.name(v as string) }
                    error={ editor.related.entityEditor.errors.validation?.name } />
                  
                  <div 
                    className={ "x space-lines-children" }>
                    
                    <SelectCountry
                      style={{ 
                        "--hny-input-w": "100%" 
                      } as React.CSSProperties}
                      label={ "Where are you based?" }
                      backgroundType={ "fill" }
                      selectedCountryCode={ editor.related.entityEditor.data.countryCode }
                      onSelect={ v => editor.related.entityEditor.edit.countryCode(v.alpha3) }
                      error={ editor.related.entityEditor.errors.validation?.countryCode } />
                    
                    <SelectCurrency
                      style={{ 
                        "--hny-input-w": "100%" 
                      } as React.CSSProperties}
                      label={ "Which currency do you use?" }
                      backgroundType={ "fill" }
                      selectedCurrencyCode={ editor.related.entityEditor.data.currencyCode }
                      onSelect={ v => editor.related.entityEditor.edit.currencyCode(v.code) }
                      error={ editor.related.entityEditor.errors.validation?.currencyCode }
                      showName={ true } />

                  </div>

                  <Button
                    onClick={ async (e) => {
                      e?.preventDefault();
                      const result = await editor.commit();
                      if (result) {
                        setIsOnboarded(true);
                        setTimeout(
                          () => window.location.href = `${ window.location.origin }/bookkeeping/income-&-expenses`,
                          3000 
                        );
                      }
                    } }
                    className={ "fill-x" }
                    label={ "Let's Go" } 
                    isWorking={ editor.state.isCreatingOrg } />

                </form>

              </>
          }

          {
            isOnboarded && 
              <img 
                height={ "40px" }
                style={{ position: "relative", top: "3px", alignSelf: "center" }}
                src={ LogoFaceWhite }
                alt={ "Bookie" } />
          }

        </div>        

    </div>
  );
}