import React from "react"
import { TransactionExpandedSegment, TransactionSegmentGroup } from "@bookie/glossary"
import { groupSegments } from "../fns/group-segments";

export const useGroupSegments = (
  segments: TransactionExpandedSegment[]
): IGroupSegments => {

  const group = React.useMemo(
    () => groupSegments(segments),
    [ segments ]
  );

  return {
    group
  };

}

export interface IGroupSegments {
  group: TransactionSegmentGroup
}