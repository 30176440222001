import React from "react";
import { useSupabase } from "@bookie/supabase";
import { _getEntities, GetEntities } from "./_api/_get-entities";
import { _getEntityExpanded, GetEntityExpanded } from "./_api/_get-entity-expanded";
import { _createEntity, CreateEntity } from "./_api/_create-entity";
import { _updateEntity, UpdateEntity } from "./_api/_update-entity";
import { _deleteEntity, DeleteEntity } from "./_api/_delete-entity";
import { _getAddress, GetAddress } from "./_api/_get-address";
import { _createAddress, CreateAddress } from "./_api/_create-address";
import { _deleteAddress, DeleteAddress } from "./_api/_delete-address";
import { _updateAddress, UpdateAddress } from "./_api/_update-address";
import { _getContact, GetContact } from "./_api/_get-contact";
import { _createContact, CreateContact } from "./_api/_create-contact";
import { _deleteContact, DeleteContact } from "./_api/_delete-contact";
import { _updateContact, UpdateContact } from "./_api/_update-contact";
import { _getOfferings, GetOfferings } from "./_api/_get-offerings";
import { _createOrg, CreateOrg } from "./_api/_create-org";
import { _claimEntityForOrg, ClaimEntityForOrg } from "./_api/_claim-entity-for-org";
import { _createEntityExpanded, CreateEntityExpanded } from "./_api/_create-entity-expanded";
import { _upsertEntity, UpsertEntity } from "./_api/_upsert-entity";
import { _uploadFile, UploadFile } from "./_api/_upload-file";
import { _deleteOffering, DeleteOffering } from "./_api/_delete-offering";
import { _createOffering, CreateOffering } from "./_api/_create-offering";
import { _updateOffering, UpdateOffering } from "./_api/_update-offering";
import { _createOrgTemplate, CreateOrgTemplate } from "./_api/_create-org-template";
import { _updateOrgTemplate, UpdateOrgTemplate } from "./_api/_update-org-template";
import { _uploadDocument, UploadDocument } from "./_api/_upload-document";
import { _getDocumentURL, GetDocumentURL } from "./_api/_get-document-url";

export const useCoreAPI = (): CoreAPI => {

  const { supabase } = useSupabase();

  return React.useMemo(() => ({
    getEntities: _getEntities(supabase),
    getEntityExpanded: _getEntityExpanded(supabase),
    createEntityExpanded: _createEntityExpanded(supabase),
    upsertEntity: _upsertEntity(supabase),
    createEntity: _createEntity(supabase),
    updateEntity: _updateEntity(supabase),
    deleteEntity: _deleteEntity(supabase),
    getAddress: _getAddress(supabase),
    createAddress: _createAddress(supabase),
    deleteAddress: _deleteAddress(supabase),
    updateAddress: _updateAddress(supabase),
    getContact: _getContact(supabase),
    createContact: _createContact(supabase),
    deleteContact: _deleteContact(supabase),
    updateContact: _updateContact(supabase),
    getOfferings: _getOfferings(supabase),
    deleteOffering: _deleteOffering(supabase),
    createOffering: _createOffering(supabase),
    updateOffering: _updateOffering(supabase),
    createOrg: _createOrg(supabase),
    claimEntityForOrg: _claimEntityForOrg(supabase),
    uploadFile: _uploadFile(supabase),
    createOrgTemplate: _createOrgTemplate(supabase),
    updateOrgTemplate: _updateOrgTemplate(supabase),
    uploadDocument: _uploadDocument(supabase),
    getDocumentURL: _getDocumentURL(supabase)
  }), [ supabase ]);

}

export interface CoreAPI {
  getEntities: GetEntities,
  getEntityExpanded: GetEntityExpanded,
  createEntityExpanded: CreateEntityExpanded,
  upsertEntity: UpsertEntity,
  createEntity: CreateEntity,
  updateEntity: UpdateEntity,
  deleteEntity: DeleteEntity,
  getAddress: GetAddress,
  createAddress: CreateAddress,
  deleteAddress: DeleteAddress,
  updateAddress: UpdateAddress,
  getContact: GetContact,
  createContact: CreateContact,
  deleteContact: DeleteContact,
  updateContact: UpdateContact,
  getOfferings: GetOfferings,
  deleteOffering: DeleteOffering,
  createOffering: CreateOffering,
  updateOffering: UpdateOffering,
  createOrg: CreateOrg,
  claimEntityForOrg: ClaimEntityForOrg,
  uploadFile: UploadFile,
  createOrgTemplate: CreateOrgTemplate,
  updateOrgTemplate: UpdateOrgTemplate,
  uploadDocument: UploadDocument,
  getDocumentURL: GetDocumentURL
}