import { ErrorAPI, Payment, PaymentOrphan, uuid } from "@bookie/glossary"
import { objectToCamel, objectToSnake, stripNullAndUndefinedFromObject } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js";

export const _createTransactionPayments = (sb: SupabaseClient): CreateTransactionPayments => 
  async (
    transactionId,
    transactionPayments
  ) => {

    const sanitisedPayments = objectToSnake(transactionPayments.map(to => stripNullAndUndefinedFromObject(to)));

    const { data: _payments, error } = (
      await sb
        .from("payment")
        .insert(sanitisedPayments)
        .select()
    );

    if (error?.code) {
      throw new ErrorAPI({});
    }

    if (_payments === null) {
      throw new ErrorAPI({});
    }

    const { data: _to, error: error2 } = (
      await sb 
        .from("transaction_payments")
        .insert(
          _payments.map((_p: Payment) => ({
            transaction_id: transactionId,
            payment_id: _p.id
          }))
        )
        .select()
    ) 

    if (error2?.code) {
      throw new ErrorAPI({});
    }

    if (_to === null) {
      throw new ErrorAPI({});
    }

    return {
      transactionPayments: objectToCamel<Payment[]>(_payments)
    };

  }

export type CreateTransactionPayments = (
  transactionId: uuid,
  transactionPayments: PaymentOrphan[]
) => Promise<CreateTransactionPaymentsResponse>

export interface CreateTransactionPaymentsResponse {
  transactionPayments: Payment[]
}