import { ITableXColumn } from "@bookie/components";
import { OrgExpanded, TransactionExpanded } from "@bookie/glossary";
import { getLocaleFromCountryCode } from "@bookie/module-core";
import { CurrencyAPI } from "@hny_/currency";
import { TimezoneAPI } from "@hny_/timezone";

export const generateCreditedBadDebtTable = (
  org: OrgExpanded,
  curr: CurrencyAPI,
  tz: TimezoneAPI
): ITableXColumn[] => [
  {
    header: "Description",
    renderCell: (tx: TransactionExpanded) => tx.description 
  },
  {
    header: "Unpaid Gross",
    align: "right",
    renderCell: (tx: TransactionExpanded) => (
      tx.summary?.localised && tx.summary?.localised.gross > 0 && tx.summary.gross
        ? curr.toHumanReadable(tx.summary.gross, getLocaleFromCountryCode(org?.entity?.countryCode), tx.currencyCode)
        : "-"
    )
  },
  {
    header: "Unpaid Gross Localised",
    align: "right",
    renderCell: (tx: TransactionExpanded) => (
      tx.summary?.localised
        ? curr.toHumanReadable(tx.summary.localised.gross)
        : curr.toHumanReadable(tx.summary?.gross || 0)
    )
  }
]