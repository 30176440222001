import { InvoiceState, Payment, PaymentState, timestamptz } from "@bookie/glossary";
import { summarisePayments } from "./summarise-payments";
import { isAfter } from "date-fns";

export const inferPaymentState = (
  payments: Payment[],
  localCurrencyCode: string,
  grossDue: number = 0,
  paymentDueDate?: timestamptz,
  invoiceState?: InvoiceState
): PaymentState | undefined => {

  if (invoiceState === "rejected") {
    return "bad_debt"
  }

  if (!invoiceState || invoiceState !== "submitted") {
    return;
  }

  const totalPaid = summarisePayments(payments, localCurrencyCode)

  if (totalPaid === grossDue && grossDue > 0) {
    return "paid_in_full";
  } else {

    // Over here it implies it hasn't been
    // paid in full. There a few scenarios here,
    // but if it's overdue - it's overdue. 

    const today = new Date();
    const dateDue = new Date(paymentDueDate || today);

    if (isAfter(today, dateDue)) {
      return "overdue";
    } else {

      if (totalPaid < grossDue && totalPaid > 0) {
        return "paid_in_part";
      } else {
        return "due";
      }
    }

  }
}