import React from "react"
import { Entity, TransactionExpanded } from "@bookie/glossary"
import { summariseTransaction } from "../fns/summarise-transaction";
import { inferPaymentState } from "../fns/infer-payment-state";
import { inferTransactionType } from "../fns/infer-transaction-type";

export const useTransactionSummary = (
  transactions: TransactionExpanded[],
  orgEntity?: Entity
): ITransactionSummary => {

  const summarisedTransactions = React.useMemo(
    () => transactions.map(t => {

      const type = inferTransactionType(t, orgEntity?.id);

      const summary = summariseTransaction(t);
      
      const paymentState = inferPaymentState(
        t.payments || [],
        t.currencyCode,
        summary.gross,
        t.invoiceDue,
        t.invoiceState
      );

      return (
        { 
          ...t, 
          summary,
          type,
          paymentState
        }
      )

    }),
    [ 
      transactions,
      orgEntity?.id
    ]
  );

  return {
    summarisedTransactions
  };

}

export interface ITransactionSummary {
  summarisedTransactions: TransactionExpanded[]
}