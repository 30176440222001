import { useCurrency } from "@hny_/currency";
import { TransactionType } from "@bookie/glossary";
import React from "react";
import css from "./index.module.css";
import cx from "classnames";

export const Amount: React.FC<IAmount> = (props) => {

  const {
    toHumanReadable
  } = useCurrency();

  const wasExchanged = props.localisedCurrency !== props.currency;

  return (
    <div
      style={{ textAlign: props.align || "center" }}
      className={ cx(
        css.amount,
        css[`amount_variant_${ props.variant || "inline" }`],
        "y align-y-center"
      ) }>
      
      {
        props.label && 
          <p className={ css.amount_label }>
            { props.label }
          </p>
      }
      
      <div
        className={ cx(
          css.amount_value,
          "x align-y-center space-inline-children",
          props.type ? css[`type_${ props.type }`] : "",
          { [css.amount_isPending]: props.isPending },
          { [css.amount_isBad]: props.isBad }
        ) }>
        {
          (Boolean(wasExchanged) && !props.onlyShowLocalised) &&
            <>

              <div
                className={ cx(
                  "",
                  css.amount_before
                ) }>
              { toHumanReadable(props.amount || 0, props.locale, props.currency) }
              </div>
                
              <div 
                className={ cx(
                  css.amount_exchangeIcon,
                  "x center"
                ) }>
                <span>→</span>
              </div>

            </>
        }

        <div 
          className={ cx(
            css.amount_final
          ) }>
          { toHumanReadable(props.localisedAmount || 0, props.locale, props.localisedCurrency) }
        </div>
      </div>

    </div>
  );
}

export interface IAmount {
  amount?: number
  currency?: string 
  locale: string
  localisedAmount: number
  localisedCurrency: string
  type?: TransactionType
  variant?: "inline" | "display" | "aside"
  isPending?: boolean
  isBad?: boolean
  onlyShowLocalised?: boolean
  label?: string
  align?: "left" | "right" | "center"
}