import { ErrorAPI, Transaction } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js";

export const _deleteTransaction = (sb: SupabaseClient): DeleteTransaction => 
  async (txId) => {

    const { data, error } = (
      await sb
        .from('transaction')
        .delete()
        .eq("id", txId)
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      transaction: objectToCamel<Transaction>(data[0])
    };

  }

export type DeleteTransaction = (
  txId: string
) => Promise<DeleteTransactionResponse>

export interface DeleteTransactionResponse {
  transaction: Transaction
}