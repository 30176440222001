import { SupabaseClient } from "@supabase/supabase-js";
import { ErrorAPI, UserConfiguration, uuid } from "@bookie/glossary";
import { objectToCamel, objectToSnake } from "@bookie/utils";

export const _updateCurrentOrgId = (sb: SupabaseClient): UpdateCurrentOrgId =>
  async (orgId, userId) => {

    const { data, error } = (
      await sb
        .from("user_configuration")
        .update(objectToSnake({ currentOrgId: orgId }))
        .eq("id", userId)
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    await sb.auth.refreshSession();
  
    return {
      configuration: objectToCamel<UserConfiguration>(data[0])
    };
  
  };

export type UpdateCurrentOrgId = (
  orgId: uuid,
  userId: uuid
) => Promise<UpdateCurrentOrgIdResponse>

export interface UpdateCurrentOrgIdResponse {
  configuration: UserConfiguration
}