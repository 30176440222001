import { TransactionDocumentType } from "@bookie/glossary";
import React from "react"
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";
import { Button, IconAddress, IconContact, IconDownload, IconFooter, IconRightAccordian, IconShipping, InputTextArea, Panel, Scrollable } from "@bookie/components";
import { useIdentity } from "@bookie/module-identity";
import { useCurrency } from "@hny_/currency";
import { AddressEditor, EntityEditorContact, useEntityEditor, useOrgTemplateEditor } from "@bookie/module-core";
import { BlobProvider, PDFViewer } from "@react-pdf/renderer";
import { TransactionDocument } from "../../components/transaction-document";

export const TransactionDocumentEditor: React.FC<ITransactionDocumentEditor> = (props) => {

  const { ownership } = useIdentity();
  const curr = useCurrency();

  const downloadDocument = React.useRef<HTMLAnchorElement>(null);

  const userEditor = useEntityEditor(
    ownership.current?.entity?.id,
    undefined,
    undefined,
    { editorType: "expanded" }
  );

  const orgTemplateEditor = useOrgTemplateEditor();

  const Document = () => (
    <TransactionDocument
      type={ props.type }
      orgId={ ownership.current?.id }
      txEditor={ props.editor } 
      userEditor={ userEditor }
      orgTemplateEditor={ orgTemplateEditor }
      toHumanReadableCurrency={ curr.toHumanReadable } />
  );

  return (
    <div 
      className={ "y fill" }>
      
      <div 
        className={ "x surface space-lines-frame border-b align-y-center align-x-between" }>
        
        <div 
          className={ "x space-lines-children" }>
          <BlobProvider 
            document={ <Document /> }>
            {
              ({ blob, url }) => {
                return (
                  <>

                    <Button 
                      size={ "slim" }
                      icon={ <IconDownload /> }
                      label={ "Save and Download PDF" }
                      onClick={ async () => {
                        await userEditor.commit();
                        await orgTemplateEditor.commit();
                        downloadDocument.current?.click();
                        props.onFinish?.();
                        props.onClose?.();
                      } } />
                    
                    <Button 
                      size={ "slim" }
                      variant={ "text" }
                      icon={ <IconDownload /> }
                      label={ "Download PDF without Saving" }
                      onClick={ () => {
                        downloadDocument.current?.click();
                        props.onFinish?.();
                        props.onClose?.();
                      } } />
                    
                    {
                      url && 
                        <a 
                          ref={ downloadDocument }
                          download={`${ props.type }.pdf`}
                          href={ url }>
                        </a>
                    }

                  </>
                )
              }
            }
          </BlobProvider>

        </div>

        <Button
          variant={ "inline" }
          icon={ <IconRightAccordian /> } 
          onClick={ () => props.onClose?.() } />

      </div>
      
      <div 
        className={ "x fill" }>

        <Scrollable 
          style={{ width: "380px" }}
          className={ "surface fill-y" }>

          <Panel
            label={ "Your Business Contact" }
            icon={ <IconContact /> }>
            <div 
              className={ "fill-x y space-lines-children space-lines-frame" }>
              <p
                className={ "surface-info" }>
                { "Changing these details will also update your main company's details" }
              </p>
              <EntityEditorContact
                editor={ userEditor } />
            </div>
          </Panel>
          
          {/* <InputText 
            label={ "Line 1" } 
            value={ props.editor?.related.entityEditor.related.addressEditor.data.line1 }
            onChange={ v => props.editor?.related.entityEditor.related.addressEditor.edit.line1(v as string) } /> */}
          
          <Panel
            label={ "Your Business Address" }
            icon={ <IconAddress /> }>
            <div 
              className={ "fill-x y space-lines-children space-lines-frame" }>
              <p
                className={ "surface-info" }>
                { "Changing these details will also update your main company's details" }
              </p>
              <AddressEditor
                editor={ userEditor.related.addressEditor }
                hideToolbar={ true }
                isInline={ true } />
            </div>
          </Panel>
          
          <Panel
            label={ "Customer Contact" }
            icon={ <IconContact /> }>
              <div 
                className={ "fill-x y space-lines-children space-lines-frame" }>
                <p
                  className={ "surface-info" }>
                  { "Changing these details will update this customer's details in your system." }
                </p>
              <EntityEditorContact
                editor={ props.editor?.related.entityEditor } />
              </div>
          </Panel>

          <Panel
            label={ "Customer Address" }
            icon={ <IconAddress /> }>
              <div 
                className={ "fill-x y space-lines-children space-lines-frame" }>
                <p
                  className={ "surface-info" }>
                  { "Changing these details will update this customer's address in your system." }
                </p>
                <AddressEditor
                  editor={ props.editor?.related.entityEditor.related.addressEditor }
                  hideToolbar={ true }
                  isInline={ true } />
              </div>
          </Panel>

          {
            props.type === "invoice" && 
              <Panel
                label={ "Shipping Address" }
                icon={ <IconShipping /> }>
                  <div 
                    className={ "fill-x y space-lines-children space-lines-frame" }>
                    <p
                      className={ "surface-info" }>
                      { "Specify a separate address for shipping " }
                    </p>
                    {/* <Button 
                      className={ "surface-button-link" }
                      label={ "Clear" }
                      onClick={ () => { props.editor?.related.shippingAddressEditor.reset() } } /> */}
                    <AddressEditor
                      editor={ props.editor?.related.shippingAddressEditor }
                      hideToolbar={ true }
                      isInline={ true } />
                  </div>
              </Panel>
          }

          <Panel
            label={ "Footer" }
            icon={ <IconFooter /> }>
              <div 
                className={ "fill-x y space-lines-children space-lines-frame" }>
                
                <p
                  className={ "surface-info" }>
                  { "Changing these details will update your document templates." }
                </p>

                {
                  props.type === "estimate" && 
                    <InputTextArea
                      backgroundType={ "fill" }
                      value={ orgTemplateEditor.data.estimateFooter }
                      onChange={ v => orgTemplateEditor.edit.estimateFooter(v as string) } />
                }

                {
                  props.type === "invoice" && 
                    <InputTextArea
                      backgroundType={ "fill" }
                      value={ orgTemplateEditor.data.invoiceFooter }
                      onChange={ v => orgTemplateEditor.edit.invoiceFooter(v as string) } />
                }

                {
                  props.type === "receipt" && 
                    <InputTextArea
                      backgroundType={ "fill" }
                      value={ orgTemplateEditor.data.receiptFooter }
                      onChange={ v => orgTemplateEditor.edit.receiptFooter(v as string) } />
                }
                
              </div>
          </Panel>
          
        </Scrollable>

        <div 
          className={ "x surface-base surface-x fill space-lines-frame align-x-center" }>
          <PDFViewer
            width={ "100%" }
            style={{ border: "none" }}
            showToolbar={ false }>
            <Document />
          </PDFViewer>
        </div>

      </div>

    </div>
  );
}

export interface ITransactionDocumentEditor {
  editor?: ITransactionEditorAPI
  type: TransactionDocumentType
  onFinish?: () => void
  onClose?: () => void
}