import React from "react";
import { Offering, uuid } from "@bookie/glossary";
import { useCoreAPI } from "../use-core-api";

export const useOfferings = (): IUseOfferings => {

  const {
    getOfferings
  } = useCoreAPI();

  const [ offerings, setOfferings ] = React.useState<Offering[]>([]);
  const [ refreshOfferings, setRefreshOfferings ] = React.useState<boolean>(true);

  React.useEffect(() => {
    (async () => {
      if (refreshOfferings && typeof getOfferings === "function") {
        const { offerings: _o } = await getOfferings();
        _o && setOfferings(_o);
        setRefreshOfferings(false);
      }
    })();
  }, [ refreshOfferings, getOfferings ]);

  const sync = (offering: Offering) => {
    setOfferings((prevOfferings) => {
      const existingIndex = prevOfferings.findIndex(o => o.id === offering.id);

      if (existingIndex !== -1) {
        // Replace the existing offering
        const updatedOfferings = [...prevOfferings];
        updatedOfferings[existingIndex] = offering;
        return updatedOfferings;
      } else {
        // Add the new offering
        return [...prevOfferings, offering];
      }
    });
  }

  const remove = (offeringId: string) => {
    setOfferings((prevOfferings) => {
      return prevOfferings.filter(o => o.id !== offeringId);
    });
  }

  return {
    offerings,
    api: {
      sync,
      remove
    }
  };

}

export interface IUseOfferings {
  offerings?: Offering[]
  api: IUseOfferingsAPI
}

export interface IUseOfferingsAPI {
  sync: (o: Offering) => void
  remove: (oId: uuid) => void
}