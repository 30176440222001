import React from "react";
import cx from "classnames";

export const Scrollable: React.FC<IScrollable> = (props) => {
  return (
    <div 
      className={ "y fill-y fill-x" }>
      <div
        style={{ height: "0px" }}
        className={ cx(
          "y scrollable fill-y fill-x"
        ) }>
        <div
          style={ props.style }
          className={ cx(
            "fill-y",
            props.className
          ) }>
          { props.children }
        </div>
      </div>
    </div>
  );
}

export interface IScrollable {
  children?: React.ReactNode;
  className?: string;
  style?: { [key: string]: string }
}