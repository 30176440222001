import React from "react";
import { GeoLocation, getUserlocation } from "./api/get-user-location";

export const useLocate = (
  isReadyToLocate?: boolean,
  onLocate?: (location: GeoLocation) => void
): IUseLocate => {

  const [ location, setLocation ] = React.useState<GeoLocation>();
  const [ hasLocated, setHasLocated ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      if (!hasLocated && isReadyToLocate) {
        const location = await getUserlocation();
        setLocation(location);
        setHasLocated(true);
      }
    })();
  }, [ hasLocated, isReadyToLocate ]);

  return {
    hasLocated,
    location
  }

}

export interface IUseLocate {
  hasLocated: boolean
  location?: GeoLocation
}

