import { IMoney, Payment } from "@bookie/glossary";

export const getPaymentGrossLocalised = (payment: Payment, localCurrencyCode: string): IMoney => {

  if (payment.currencyCode === localCurrencyCode) {
    return {
      value: payment.gross || 0,
      currencyCode: payment.currencyCode
    };
  } else {
    return {
      value: payment.grossLocalised || 0,
      currencyCode: localCurrencyCode
    };
  }

}