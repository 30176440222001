/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDebounce } from "./use-debounce";
import React from "react";
import hash from "object-hash";

export const useValidator = (
  // Validation function that takes data and returns a boolean
  validate: (data: { [field: string]: any }, opts?: object) => boolean,

  // Data to be validated; can be an object or an array
  data: { [field: string]: any } | any[],

  // Optional observables that might influence validation
  observables?: any[],

  // Flag indicating if the validation is ready to run
  isReady?: boolean
) => {
  
  // State to track if the form has been interacted with
  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  // State to track if the form is in its initial (virgin) state
  const [isVirgin, setIsVirgin] = React.useState<boolean>(true);

  // State to store the original hash of the data for comparison
  const [originalHash, setOriginalHash] = React.useState<string>();

  // State to store validation errors as an object
  const [validationErrors, setValidationErrors] = React.useState<{ [field: string]: string }>();

  // State to store validation errors as an array of objects
  const [validationErrorsArray, setValidationErrorsArray] = React.useState<{ [field: string]: string }[]>();

  // Debounced validation function to prevent excessive validations
  const _validate = useDebounce(
    () => validate(data),
    400
  );

  /**
   * Memoize the dependencies array to ensure it only changes when 'data' changes.
   * This prevents unnecessary re-renders and avoids the ESLint warning.
   */
  const deps = React.useMemo(() => {
    if (Array.isArray(data)) {
      return data;
    } else if (typeof data === "object" && data !== null) {
      return Object.values(data);
    } else {
      return [];
    }
  }, [data]);

  /**
   * Compute a stable hash of the dependencies.
   * Using a hash ensures that the dependency array length remains consistent,
   * preventing ESLint warnings related to conditional dependencies.
   */
  const depsHash = React.useMemo(() => hash(deps), [deps]);

  /**
   * Similarly, compute a stable hash for 'observables' to include in dependencies.
   * If 'observables' are objects or arrays, hashing ensures their reference stability.
   */
  const observablesHash = React.useMemo(() => hash(observables || []), [observables]);

  /**
   * Effect to run validation when 'isDirty', 'depsHash', or 'observablesHash' change.
   * This replaces the conditional spreading and ensures a consistent dependency array.
   */
  React.useEffect(() => {
    if (isDirty) {
      _validate();
    }
  }, [depsHash, isDirty, _validate, observablesHash]);

  /**
   * Effect to update the 'isVirgin' state based on changes in the dependencies.
   * It compares the current hash of dependencies with the original hash.
   */
  React.useEffect(() => {
    if (isReady && originalHash) {
      const currentHash = depsHash;
      if (originalHash !== currentHash) {
        setIsVirgin(false);
      } else {
        setIsVirgin(true);
      }
    }
  }, [depsHash, isReady, originalHash, deps]);

  /**
   * Effect to set the original hash of dependencies when the component is ready.
   * This initializes the comparison for future changes.
   */
  React.useEffect(() => {
    if (isReady && !originalHash) {
      const currentHash = depsHash;
      setOriginalHash(currentHash);
    }
  }, [isReady, depsHash, originalHash, deps]);

  return {
    validate: (isRequired?: boolean, opts?: object) => {
      if (!isDirty) {
        setIsDirty(true);
      }
      if (isRequired || !isVirgin) {
        return validate(data, opts);
      }
      return true;
    },
    validationErrors,
    setValidationErrors,
    validationErrorsArray,
    setValidationErrorsArray,
    setDirty: () => {
      if (!isDirty) {
        setIsDirty(true);
      }
    },
    isVirgin
  };
}

export type ValidateFn = (isRequired?: boolean, opts?: object) => boolean;
