import { ErrorAPI } from "@bookie/glossary";
import { toCustomFormat } from "@hny_/timezone";

export const getExchangeRate: IExchange = async (
  currencyFrom,
  currencyTo,
  date
) => {

  if (!date) {
    throw new ErrorAPI({});
  }

  const formattedDate = toCustomFormat(date, "YYYY-MM-DD");
  
  // const _response = await fetch(`https://api.vatcomply.com/rates?date=${  }&base=${ currencyFrom }&symbols=${ currencyTo }`);
  const _response = await fetch(
    `https://api.frankfurter.app/${ formattedDate }?from=${ currencyFrom }&to=${ currencyTo }`, 
    {
      method: 'GET',
      headers: {
        'x-rapidapi-key': 'e3c3ef7e3emsh2051eecf6208767p19348ejsn445eeaa1fbf4',
        'x-rapidapi-host': 'currency-conversion-and-exchange-rates.p.rapidapi.com'
      }
    }
  );
  
  const response = await _response.json();

  if (!response?.rates?.[currencyTo]) {
    throw new ErrorAPI({}); 
  }

  return {
    exchangeRate: response.rates[currencyTo]
  }

}

export type IExchange = (
  currencyFrom: string,
  currencyTo: string,
  date?: string
) => Promise<IExchangeResponse>

export interface IExchangeResponse {
  exchangeRate: number
}