import { Button, IconOfferingsAdd, IconPayments, Panel } from "@bookie/components";
import React from "react";
import { ITransactionPaymentsEditorAPI } from "../../use/use-transaction-payments-editor";
import { TransactionPaymentEditor } from "../transaction-payment-editor";

export const TransactionPaymentsEditor: React.FC<ITransactionPaymentsEditor> = (props) => {

  const [ selected, setSelected ] = React.useState<number>(-1);

  // Refs to store previous lengths
  const prevNewLength = React.useRef<number>(props.editor?.new.data.length || 0);
  const prevExistingLength = React.useRef<number>(props.editor?.existing.data.length || 0);

  // Effect for handling additions to 'new' offerings
  React.useEffect(() => {
    const currentNewLength = props.editor?.new.data.length || 0;
    if (currentNewLength > prevNewLength.current) {
      // New offering added to 'new' array
      const newIndex = (props.editor?.existing.data.length || 0) + (currentNewLength - 1);
      setSelected(newIndex);
    }
    prevNewLength.current = currentNewLength;
  }, [props.editor?.existing.data.length, props.editor?.new.data]);

  // Effect for handling additions to 'existing' offerings
  React.useEffect(() => {
    const currentExistingLength = props.editor?.existing.data.length || 0;
    if (currentExistingLength > prevExistingLength.current) {
      // New offering added to 'existing' array
      const newIndex = currentExistingLength - 1;
      setSelected(newIndex);
    }
    prevExistingLength.current = currentExistingLength;
  }, [props.editor?.existing.data]);

  const totalPayments = props.editor?.all.length || 0;

  if (props.isDisabled) {
    return null;
  }

  return (
    <Panel
      isDisabled={ props.isDisabled }
      disabledMessage={ "Complete the estimate (if applicable) and invoice before adding payments." }
      label={ "Payments" }
      isGhost={ props.renderBare }
      defaultOpen={ props.renderBare }
      icon={ <IconPayments /> }
      summary={
        totalPayments > 0 
          ? `${ totalPayments } payments`
          : ""
      }
      isEmpty={
        (props.editor?.existing.data && props.editor?.existing.data.length === 0) &&
        (props.editor?.new.data && props.editor?.new.data.length === 0) &&
        !props.renderBare
      }
      emptyMessage={ "You haven't logged any payments yet." }
      actions={
        open => (
          <Button 
            onClick={ () => {
              props.editor?.new.add()
              open();
            } }
            variant={ "text" }
            icon={ <IconOfferingsAdd /> }
            size={ "slim" } />
        )
      }>
      
      <div
        className={ "y space-lines-frame fill-x" }>
        
        {
          props.editor?.existing.data.map((payment, i) => (
            <TransactionPaymentEditor
              surfaceDisplay={ props.surfaceDisplay }
              key={ payment.id }
              payment={ payment }
              isSelected={ selected === i }
              onSelect={ () => setSelected(i) }
              onEdit={ (f, v) => props.editor?.existing.edit(i, f, v) }
              onDelete={ () => props.editor?.existing.remove(payment.id) }
              errors={ props.editor?.existing.errors.validation?.[i] }
              onMinimise={ () => setSelected(-1) } />
          ))
        }

        {
          props.editor?.new.data.map((payment, i) => (
            <TransactionPaymentEditor
              surfaceDisplay={ props.surfaceDisplay }
              key={ i }
              payment={ payment }
              isSelected={ selected === (i + (props.editor?.existing.data.length || 0)) }
              onSelect={ () => setSelected(i + (props.editor?.existing.data.length || 0)) }
              onEdit={ (f, v) => props.editor?.new.edit(i, f, v) }
              onDelete={ () => props.editor?.new.remove(i) }
              errors={ props.editor?.new.errors.validation?.[i] }
              onMinimise={ () => setSelected(-1) } />
          ))
        }

      </div>

    </Panel>
  );
}

export interface ITransactionPaymentsEditor {
  editor?: ITransactionPaymentsEditorAPI
  isDisabled?: boolean
  renderBare?: boolean
  surfaceDisplay?: string
}