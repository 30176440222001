import { TaxType } from "@bookie/glossary";

export const summariseTransactionOffering = (
  grossSale: number = 0,
  quantity: number = 0,
  taxPercent: number = 0,
  taxType: TaxType = "inclusive"
): number => {

  const totalSale = grossSale * quantity;
  
  // We only want to add this 
  // if the type if additive. 
  // For inclusive, we are assuming the 
  // tax calculation has already been 
  // factored into the pricing. 
  
  const totalTax = (
    taxType === "additive"
      ? (totalSale * taxPercent) / 100
      : 0
  );

  // Simple as. 
  // Total sale price added 
  // with tax, if any.

  return (totalTax + totalSale);

}