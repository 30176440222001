import { ErrorAPI, uuid } from "@bookie/glossary";
import { objectToSnake } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js";

export const _addUserToOrg = (sb: SupabaseClient): AddUserToOrg => 
  async (orgId, userId) => {

    const { data, error } = (
      await sb 
        .from("orgs_users")
        .insert(objectToSnake({
          orgId,
          ...(userId ? { userId } : {})
        }))
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return

  };

export type AddUserToOrg = (
  orgId: uuid,
  userId?: uuid
) => Promise<void>