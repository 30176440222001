import { uuid } from "@bookie/glossary";
import React from "react";
import { IContactEditorAPI, useContactEditor } from "../../use/use-contact-editor";
import { Button, IconRightAccordian, InputBase, InputText } from "@bookie/components";
import { SelectCountryPhone } from "../../components/select-country-phone";
import cx from "classnames";

export const ContactEditor: React.FC<IContactEditor> = (props) => {

  const editor = useContactEditor(
    props.contactId, 
    undefined,
    props.editor
  );
  
  return (
    <div 
      className={ "y fill" }>

      {
        !props.hideToolbar && 
          <div 
            style={{ borderRadius: "0" }}
            className={ "x surface border-b space-blocks-frame-lr space-lines-frame-tb align-x-between" }>
            
            {
              !props.editor && 
                <>

                  <div 
                    className={ "x" }>
                    <Button
                      label={ props.contactId === "create" ? "Create" : "Update" }
                      size={ "slim" }
                      onClick={ async () => {
                        const result = await editor.commit();
                        result && props.onFinish?.();
                      } } />
                    <Button
                      label={ "Cancel" }
                      size={ "slim" }
                      variant={ "text" }
                      onClick={ async () => {
                        props.onFinish?.();
                      } } />
                  </div>
                  
                  {
                    (editor.data.id && !props.hideDelete) && 
                      <Button
                        label={ "Delete" }
                        size={ "slim" }
                        variant={ "dangerous" }
                        surface={ "surface-negative" }
                        isDangerous={ true }
                        onClick={ async () => {
                          const result = await editor.delete();
                          result && props.onFinish?.();
                        } } /> 
                  }

                </>
            }

            {
              props.editor && 
                <Button
                  icon={ <IconRightAccordian /> }
                  variant={ "inline" }
                  onClick={ () => props.onFinish?.() } />
            }

          </div>
      }
    
      <div 
        style={{ height: props.isInline ? "inherit" : "0" }}
        className={ cx(
          "y surface fill",
          { "scrollable space-blocks-frame space-blocks-children": !props.isInline },
          { "space-lines-children": props.isInline }
        ) }>

        <InputText
          type={ "text" }
          label={ "First Name" }
          className={ "fill-x" }
          value={ editor.data.nameGiven }
          onChange={ v => editor.edit.nameGiven(v as string) }
          error={ editor.errors.validation?.["nameGiven"] } />
        
        <InputText
          type={ "text" }
          label={ "Family Name" }
          className={ "fill-x" }
          value={ editor.data.nameFamily }
          onChange={ v => editor.edit.nameFamily(v as string) }
          error={ editor.errors.validation?.nameFamily } />
        
        <InputText
          type={ "text" }
          label={ "Email" }
          className={ "fill-x" }
          value={ editor.data.email }
          onChange={ v => editor.edit.email(v as string) }
          error={ editor.errors.validation?.email } />
        
        <div 
          className={ "y" }>
          
          <InputBase
            type={ "text" }
            label={ "Phone Number" } />
        
          <div 
            className={ "x align-y-baseline space-inline-children" }>
            
            <SelectCountryPhone
              selectedPhoneCountryCode={ editor.data.phoneCountryCode }
              onSelect={ (c) => editor.edit.phoneCountryCode(c as string) }
              error={ editor.errors.validation?.phoneCountryCode } />
            
            <InputText
              type={ "number" }
              value={ editor.data.phoneNumber + "" }
              onChange={ v => editor.edit.phoneNumber(v as number)}
              error={ editor.errors.validation?.phoneNumber } />

          </div>

        </div>
      
      </div>

    </div>
  );

}

export interface IContactEditor {
  contactId?: uuid | "create"
  editor?: IContactEditorAPI
  onFinish?: () => void 
  hideDelete?: boolean 
  hideToolbar?: boolean
  isInline?: boolean
}