import React from "react";
import { TransactionExpanded } from "@bookie/glossary";
import { sortTransactions } from "../fns/sort-transactions";

export const useTransactionSort = (transactions: TransactionExpanded[] = []): ITransactionSort => {

  // We'll watch for any changes to the 
  // full list of txs, and if it changes 
  // we will re-sort. 

  const sortedTransactions = React.useMemo(
    () => sortTransactions(transactions),
    [ transactions ]
  );

  return {
    sortedTransactions
  }

}

export interface ITransactionSort {
  sortedTransactions: TransactionExpanded[]
}
