import React from "react";
import { useCore } from "../../use-core";
import { uuid } from "@bookie/glossary";
import { OfferingEditor } from "../offering-editor";
import { Button, IconAdd, List, Panel, Empty } from "@bookie/components";

export const OfferingsEditor: React.FC<IOfferingsEditor> = (props) => {

  const { offerings } = useCore();

  const [ selectedOfferingId, setSelectedOfferingId ] = React.useState<uuid | "create">();

  React.useEffect(() => {
    if (!selectedOfferingId && offerings.all?.[0]) {
      setSelectedOfferingId(offerings.all[0].id);
    } 
  }, [ offerings.all, selectedOfferingId ]);

  const isEmpty = (
    (!offerings.all || offerings.all.length === 0) && 
    typeof selectedOfferingId === "undefined"
  );

  return(
    <div
      className={ "x fill-x surface-aside" }>
      
      <div 
        style={{ minWidth:"400px" }}
        className={ "y surface-aside border-r" }>
        
        <Panel
          label={ "Products & Services" }
          defaultOpen={ true }
          isEmpty={ !offerings.all || offerings.all.length === 0 }
          emptyMessage={ "Your Products and Services will appear here." }
          actions={
            () => (  
              (
                (selectedOfferingId !== "create" && typeof selectedOfferingId === "string") ||
                (!selectedOfferingId)
              ) && 
                <Button
                  size={ "slim" }
                  variant={ "inline" }
                  icon={ <IconAdd /> }
                  onClick={ () => setSelectedOfferingId("create") } />
            )
          }>
          <div 
            className={ "space-lines-frame fill-x" }>
            <List
              data={ offerings.all }
              renderLabel={ o => o.name }
              isSelected={ o => o.id === selectedOfferingId }
              onClick={ m => setSelectedOfferingId(m.id) } /> 
          </div>
        </Panel>

      </div>

      {
        selectedOfferingId && selectedOfferingId !== "create" && 
          <OfferingEditor
            offeringId={ selectedOfferingId }
            onFinish={ () => { setSelectedOfferingId(undefined) } } />
      }

      {
        selectedOfferingId && selectedOfferingId === "create" && 
          <OfferingEditor
            offeringId={ selectedOfferingId }
            onFinish={ () => { setSelectedOfferingId(undefined) } } />
      }

      {
        isEmpty && 
          <div
            style={{ maxWidth: "900px" }}
            className={ "y space-lines-frame" }>
            <Empty
              label={ "This is where you can find all of your Products and Services" }
              description={[
                "Welcome to the Products and Services page — your go-to spot for setting up everything you sell.",
                "Whether it’s physical products or services, you can configure them here, making it quick and easy to later add them to your estimates and invoices.",
                "It’s a simple way to keep everything consistent and organized."
              ]}
              callToActions={
                <div 
                  className={ "x space-lines-children space-lines-frame-t" }>

                  <Button
                    variant={ "inline" }
                    icon={ <IconAdd /> }
                    label={ "Create your first Product or Service" } 
                    onClick={
                      () => setSelectedOfferingId("create")
                    } />
                
                </div>
              } />
          </div>
      }

    </div>
  );

}

export interface IOfferingsEditor {

}