import React from "react";
import { IconDown, IconMore } from "../icon";
import css from "./index.module.css";
import cx from "classnames";

export const Button: React.FC<IButton> = (props) => {

  const _variant = props.variant || "fill";

  const _ref = React.useRef<HTMLDivElement>(null);

  const [ isInDangerZone, setIsInDangerZone ] = React.useState<boolean>(false);
  const [ showOptions, setShowOptions ] = React.useState<boolean>(false);

  const El = props.options ? "div" : "button";

  const handleDocumentClick = React.useCallback(
    (event: MouseEvent) => {
      if (_ref.current && !_ref.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    },
    []
  );

  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => { document.removeEventListener('click', handleDocumentClick); };
  }, [ handleDocumentClick ]);

  return (
    <div
      className={ cx(
        css.buttonWrap,
        "x space-inline-children",
        props.surface
      ) }
      ref={ _ref }>
      
      {
        isInDangerZone && 
          <Button
            variant={ "text" }
            size={ "slim" }
            onClick={ () => setIsInDangerZone(false) }
            label={ "No" } />
      }

      <El
        type={ props.type || "button" }
        style={ props.style || {} }
        className={ cx(
          "hny-button x center space-inline-children",
          css.button,
          css[`button_variant_${ _variant }`],
          { [css.button_variant_outline]: props.variant === "dangerous" && !isInDangerZone },
          { [css.button_variant_fill]: props.variant === "dangerous" && isInDangerZone },
          { "disabled": props.isWorking },
          `size_${ props.size }`,
          props.className
        ) }
        onClick={ (e) => {
          if (!showOptions) {
            if (props.variant === "dangerous" && !isInDangerZone) {
              setIsInDangerZone(true);
            } else {
              props.onClick?.(e);
            }
          }
          if (!props.onClick && props.options) {
            setShowOptions(!showOptions);
          }          
        } }>

          { 
            props.icon && 
              React.cloneElement(
                props.icon,
                { size: props.iconSize || 20 }
              ) 
          }

          {
            props.label && 
              <span>
                {
                  props.isWorking
                    ? "..."
                    : (
                      isInDangerZone
                        ? "Are you sure?"
                        : props.label
                    )
                }
              </span>
          }

          {
            props.options && 
              <span
                onClick={ (e) => {
                  e.stopPropagation();
                  setShowOptions(!showOptions);
                } }>
                
                {
                  (!props.optionsIconStyle || props.optionsIconStyle === "dots") &&
                    <IconMore size={ props.iconSize || 20 } />
                }

                {
                  props.optionsIconStyle === "chevron" &&
                    <IconDown />
                }
                
              </span>
          }
          
          <div
            style={{ display: props.options && showOptions ? "inherit" : "none" }}
            className={ cx(
              css.button_options,
              css[`button_options_${ props.optionsPosition || "left" }Aligned`]
            ) }>
            { props.options }
          </div>

      </El>
    </div>
  );
}

export interface IButton {
  type?: "submit" | "button" | "reset"
  variant?: ButtonVariant
  surface?: string
  label?: string
  isDangerous?: boolean
  size?: ButtonSize
  onClick?: (
    e?: (
      React.MouseEvent<HTMLDivElement, MouseEvent> |
      React.MouseEvent<HTMLButtonElement, MouseEvent>
    )
  ) => void
  isWorking?: boolean
  className?: string
  options?: React.ReactNode
  optionsPosition?: "left" | "right" | "center"
  optionsIconStyle?: "chevron" | "dots"
  icon?: React.ReactElement
  iconSize?: number
  style?: { [key:string]: string }
}

export type ButtonVariant = 
  "fill" |
  "outline" |
  "text" |
  "inline" |
  "dangerous" |
  "positive" |
  "negative";

export type ButtonSize = 
  "normal" |
  "slim" |
  "large";