import React from "react"
import css from "./index.module.css"
import cx from "classnames"
import { LogoFaceWhite } from "@bookie/images";
import { IconClose } from "../icon";

export const Bookie: React.FC<IBookie> = (props) => {
  return (
    <div 
      className={ "x space-inline-children" }>
    
      <div
        onClick={ props.onClick }
        className={ cx(
          css.bookie,
          "apply-bg apply-fg apply-border x",
          props.className,
          { [css.bookie_open]: props.isOpen },
          { "surface-primary": !props.isHelping },
          { "surface-primary-aside": props.isHelping }
        ) }>
        
        <img 
          src={ LogoFaceWhite }
          alt={ "Bookie Bookie" } />
        
        <p>
          { props.message || "Need some help?" }
        </p>

      </div>

      {
        props.isHelping && 
          <div 
            onClick={ props.onClose }
            className={ cx(
              css.bookie_close,
              "surface-primary-aside apply-bg apply-border x align-x-center align-y-center"
            ) }>
            <IconClose />
          </div>
      }

    </div>
  );
}

export interface IBookie {
  className?: string
  onClick?: () => void
  isOpen?: boolean
  message?: string
  isHelping?: boolean 
  onClose?: () => void
}