import { SupabaseClient } from "@supabase/supabase-js";
import { Entity, EntityOrphan, PaymentOrphan, Transaction, TransactionOffering, TransactionOfferingOrphan, TransactionOrphan, TransactionType, ErrorValidation, TransactionRepeatConfig } from "@bookie/glossary";
import { _createEntity, validateEntity } from "@bookie/module-core";
import { validateTransaction } from "../fns/validate-transaction";
import { validateTransactionOfferings } from "../fns/validate-transaction-offerings";
import { repeatTransaction } from "../fns/repeat-transaction";
import { _createTransactions } from "./_create-transactions";
import { repeatTransactionOfferings } from "../fns/repeat-transaction-offerings";
import { _createTransactionsOfferings } from "./_create-transactions-offerings";

export const _createTransactionExpandedRepeated = (sb: SupabaseClient): CreateTransactionExpandedRepeated => 
  async (
    tx,
    transactionType, 
    entity, 
    transactionOfferings, 
    payments,
    repeatConfig
  ) => {

    if (typeof repeatConfig === "undefined") {
      // We want to throw an Application Error here. TODO
      throw new Error();
    }

    let createdEntity: Entity | null = null;

    // Because this is a multi-layered API endpoint
    // that creates a number of related objects,
    // we want to essentially perform a dry run 
    // of the validation. 
    // We want to avoid a scenario where the first 
    // object is valid, but the second one isn't,
    // but we only find out after we've created the 
    // first. 

    // Each validation will still run again after 
    // this dry run, but that's fine. It's pretty safe to 
    // assume we won't have any issues. 

    if (entity) {
      const entityValidation = validateEntity(entity);
      if (!entityValidation.isValid) {
        throw new ErrorValidation({
          name: "entity",
          errors: entityValidation.errors
        });
      }
    }

    const txValidation = validateTransaction(tx, transactionType, { ignoreEntities: entity ? true : false });
    if (!txValidation.isValid) {
      throw new ErrorValidation({
        name: "transaction",
        errors: txValidation.errors
      });
    }

    if (transactionOfferings && transactionOfferings.length > 0) {
      const transactionOfferingsValidation = validateTransactionOfferings(transactionOfferings);
      if (!transactionOfferingsValidation.isValid) {
        throw new ErrorValidation({
          name: "transaction_offerings",
          errorsArray: transactionOfferingsValidation.errors
        });
      }
    }

    // TODO 
    // We need to figure out if the new entity 
    // to create already exists. 
    // HINT use supabase.upsert

    if (transactionType === "income" && !tx.sourceId && entity) {
      const { entity: _createdEntity } = await _createEntity(sb)(entity);
      _createdEntity && (createdEntity = _createdEntity);
      _createdEntity && (tx.sourceId = _createdEntity.id);
    }

    if (transactionType === "expense" && !tx.destinationId && entity) {
      const { entity: _createdEntity } = await _createEntity(sb)(entity);
      _createdEntity && (createdEntity = _createdEntity)
      _createdEntity && (tx.destinationId = _createdEntity.id);
    }

    // First we will repeat the transaction
    // and create it so that we can get the IDs.

    const repeatedTransactions = repeatTransaction(tx as Transaction, repeatConfig);
    const { transactions } = await _createTransactions(sb)(repeatedTransactions);

    // If we also have offerings defined
    // then we will need to repeated these too.

    // Once we have the IDs of all the new 
    // transactions, we can create a set of Offerings 
    // for each one. 

    let _transactionOfferings;

    if (transactionOfferings && transactionOfferings.length > 0) {
      const repeatedTransactionOfferings = repeatTransactionOfferings(transactions, transactionOfferings);
      const { transactionOfferings: _tos } = await _createTransactionsOfferings(sb)(repeatedTransactionOfferings);
      _transactionOfferings = _tos;
    }

    // TODO 
    // same for payments.

    return {
      data: {
        transactions,
        entity: createdEntity,
        transactionOfferings: _transactionOfferings
      }
    };

  }

export type CreateTransactionExpandedRepeated = (
  tx: TransactionOrphan,
  transactionType: TransactionType,
  entity?: EntityOrphan,
  transactionOfferings?: TransactionOfferingOrphan[],
  payments?: PaymentOrphan[],
  repeatConfig?: TransactionRepeatConfig
) => Promise<CreateTransactionExpandedRepeatedResponse>

export interface CreateTransactionExpandedRepeatedResponse {
  data: {
    transactions: Transaction[],
    entity?: Entity | null,
    transactionOfferings?: TransactionOffering[]
  }
}