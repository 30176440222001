import { TransactionExpanded, TransactionFilterPeriods } from "@bookie/glossary";
import { filterByPeriod } from "./filter-by-period";

export const isInPeriod = (
  tx: TransactionExpanded,
  period?: TransactionFilterPeriods
): boolean => {
  
  if (!tx.deferredDate || !period) {
    return true;
  }

  const [ _tx ] = filterByPeriod([tx], period, { invoiceDate: false, deferredDate: true });

  if (!_tx) {
    return false;
  }

  return true;

}