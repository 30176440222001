import { Entity, EntityOrphan, ErrorAPI } from "@bookie/glossary"
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel, objectToSnake, stripNullAndUndefinedFromObject } from "@bookie/utils";
import { _uploadFile } from "./_upload-file";
import { v4 } from "uuid";

export const _createEntity = (sb: SupabaseClient): CreateEntity => 
  async (entity, logo) => {


    if (logo) {
      const { filepath } = await _uploadFile(sb)(logo, `/entity/logos/${ v4() }`);
      entity.logoUrl = filepath;
    }

    const { data, error } = (
      await sb
        .from("entity")
        .insert([
          objectToSnake(stripNullAndUndefinedFromObject(entity))
        ])
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      entity: objectToCamel<Entity>(data[0])
    };

  }

export type CreateEntity = (
  entity: EntityOrphan,
  logo?: File
) => Promise<CreateEntityResponse>

export interface CreateEntityResponse {
  entity: Entity
}