import { Contact, ErrorAPI } from "@bookie/glossary";
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel, objectToSnake } from "@bookie/utils";

export const _updateContact = (sb: SupabaseClient): UpdateContact => 
  async (contact) => {

    const { data, error } = (
      await sb 
        .from("contact")
        .update(objectToSnake(contact))
        .eq("id", contact.id)
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      contact: objectToCamel<Contact>(data[0])
    };

  }

export type UpdateContact = (
  contact: Contact
) => Promise<UpdateContactResponse>

export interface UpdateContactResponse {
  contact: Contact
}