import { TransactionExpanded, TransactionType } from "@bookie/glossary";

// You want to try and be agnostic to whether 
// this is income or an expense as it is used for both. 

// We can refer to it as it's generic parent "transaction".

export const auditTransaction = (
  tx: TransactionExpanded,
  localCurrencyCode: string,
  transactionType: TransactionType
): string | undefined => {

  if (tx.offerings?.length === 0) {
    return "This transaction has no Products or Services"
  }

  if (tx.estimateState !== "n/a" && tx.estimateState !== "approved") {
    return "This transaction's estimate has not been approved or rejected yet."
  }

  if (tx.estimateState === "approved" && (tx.invoiceState !== "submitted" && tx.invoiceState !== "rejected") && transactionType === "income") {
    return "This transaction's invoice has not been submitted yet."
  }

  if (tx.invoiceState === "rejected") {
    return "This transaction was been rejected.";
  }

  if (
    tx.currencyCode !== localCurrencyCode &&
    !tx.summary?.localised
  ) {
    return "This transaction is not localised to your currency";
  }

  if (
    tx.payments?.length === 0 || !tx.payments
  ) {
    return "This transaction has not been paid in full yet";
  }

}