import { IconPeriod, InputSelect } from "@bookie/components";
import React from "react";
import { useBookkeeping } from "../../use-bookkeeping";

const FILTER_PERIODS = [
  { id: "this_month", label: "This Month" },
  { id: "this_year", label: "This Year" },
  { id: "last_year", label: "Last Year" },
  { id: "all_time", label: "All Time" }
];

export const FilterByPeriod: React.FC<IFilterByPeriod> = (props) => {

  const { filter } = useBookkeeping();

  return (
    <InputSelect
      size={ "small" }
      label={ "Filter by Period" }
      icon={ <IconPeriod /> }
      options={ FILTER_PERIODS }
      value={ FILTER_PERIODS.find(fp => fp.id === filter.period) }
      formatLabel={ fp => fp.label }
      matchKey={ "id" }
      onSelect={ fp => filter.byPeriod(fp.id)  } />
  );
}

export interface IFilterByPeriod {
  value?: string
}