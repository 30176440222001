import React from "react";
import { TransactionExpanded, uuid } from "@bookie/glossary";
import { useBookkeepingAPI } from "../use-bookkeeping-api";

export const useTransactions = (): IUseTransactions => {

  const {
    getTransactionsExpanded
  } = useBookkeepingAPI();

  const [ transactions, setTransactions ] = React.useState<TransactionExpanded[]>([]);
  const [ refreshTransactions, setRefreshTransactions ] = React.useState<boolean>(true);

  React.useEffect(() => {
    (async () => {
      if (refreshTransactions && typeof getTransactionsExpanded === "function") {
        const { transactions: _txs } = await getTransactionsExpanded();
        _txs && setTransactions(_txs);
        setRefreshTransactions(false);
      }
    })();
  }, [ refreshTransactions, getTransactionsExpanded ]);

  const sync = (t: TransactionExpanded) => {
    setTransactions((prevOfferings) => {
      const existingIndex = prevOfferings.findIndex(o => o.id === t.id);
      if (existingIndex !== -1) {
        const updatedTxs = [...prevOfferings];
        updatedTxs[existingIndex] = t;
        return updatedTxs;
      } else {
        return [...prevOfferings, t];
      }
    });
  }

  const remove = (txId: string) => {
    setTransactions((prevOfferings) => {
      return prevOfferings.filter(o => o.id !== txId);
    });
  }

  return {
    transactions,
    api: {
      sync,
      remove,
      refresh: () => setRefreshTransactions(true)
    }
  };
}

export interface IUseTransactions {
  transactions?: TransactionExpanded[]
  api: IUseTransactionsAPI
}

export interface IUseTransactionsAPI {
  sync: (t: TransactionExpanded) => void
  remove: (txId: uuid) => void
  refresh: () => void
}