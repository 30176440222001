import { Button, IconAccounting, IconDates, IconDelete, IconDescription, InputBase, InputDate, InputText, Panel } from "@bookie/components";
import React from "react";
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";
import { useTimezone } from "@hny_/timezone";

export const TransactionAccounting: React.FC<ITransactionAccounting> = (props) => {

  const tz = useTimezone();

  const [ addDeferredRevenue, setAddDeferredRevenue ] = React.useState<boolean>(false);

  if (props.isDisabled) {
    return null;
  }

  return (
    <Panel
      icon={ <IconAccounting /> }
      label={ "Accounting" }
      actions={
        (open) => (
          <Button
            size={ "slim" }
            variant={ "text" }
            options={
              <div
                className={ "y surface border radius" }>
                <Button
                  label={ "Defer Revenue" }
                  variant={ "text" }
                  onClick={ () => {
                    setAddDeferredRevenue(true)
                    open()
                  } } />
              </div>
            }
            optionsPosition={ "right" } />
        )
      }>

      <div 
        className={ "y fill-x" }>

        {
          (
            addDeferredRevenue ||
            typeof props.editor?.data.deferredDate !== "undefined"
          ) && 
            <div 
              className={ "y space-inlinez-children space-inlinez-frame border radius surface-aside" }>
              
              <div 
                className={ "x fill-x align-x-between align-y-center" }>
                
                <InputBase
                  label={ "Deferred Revenue" }
                  size={ "small" } />
                
                <Button
                  style={{ width: "50px" }}
                  variant={ "text" }
                  onClick={ e => {
                    e?.preventDefault();
                    props.editor?.edit.deferredDate(undefined);
                    props.editor?.edit.deferredReason(undefined);
                    setAddDeferredRevenue(false);
                  } }
                  icon={ <IconDelete /> }
                  size={ "slim" } />

              </div>
              
              <div 
                className={ "x align-y-center space-lines-children" }>
                
                <InputDate
                  label={ "Defer to" }
                  size={ "small" }
                  icon={ <IconDates /> }
                  toApplicationReadable={ tz.toApplicationReadable }
                  toDataReadable={ tz.toDataReadable }
                  toHumanReadable={ tz.toHumanReadable }
                  value={ props.editor?.data.deferredDate }
                  onChange={ v => props.editor?.edit.deferredDate(v as string) }
                  error={ props.editor?.errors.validation?.["deferredDate"] } />
                
                <InputText
                  style={{ "---hny-input-w": "100%" }}
                  icon={ <IconDescription /> }
                  type={ "text" }
                  size={ "small" }
                  label={ "Deferment Reason" }
                  className={ "fill-x" }
                  value={ props.editor?.data.deferredReason }
                  onChange={ v => props.editor?.edit.deferredReason(v as string) }
                  error={ props.editor?.errors.validation?.["deferredReason"] } />

              
              </div>

            </div>
        }

      </div>
      
    </Panel> 
  );
}

export interface ITransactionAccounting {
  editor?: ITransactionEditorAPI
  isDisabled?: boolean
}