import React from "react";
import { useBookkeeping } from "../../use-bookkeeping";
import { IconSegment, InputSelect } from "@bookie/components";
import { TransactionType } from "@bookie/glossary";

const SEGMENT_INCOME = [
  { id: "segment_by_month", label: "by Month" },
  { id: "segment_by_entity", label: "by Client" }
];

const SEGMENT_EXPENSE = [
  { id: "segment_by_month", label: "by Month" },
  { id: "segment_by_entity", label: "by Merchant" }
];

const SEGMENT = [
  { id: "segment_by_month", label: "by Month" },
  { id: "segment_by_entity", label: "by Client or Merchant" }
]

export const SegmentTransactions: React.FC<ISegmentTransactions> = (props) => {

  const { segment } = useBookkeeping();

  let options = SEGMENT;

  switch (props.type) {
    case "income": {
      options = SEGMENT_INCOME;
      break;
    }
    case "expense": {
      options = SEGMENT_EXPENSE;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <InputSelect
      size={ "small" }
      icon={ <IconSegment /> } 
      label={ "Segment" }
      name={ "segment-transactions" }
      options={ options }
      value={ options.find(s => s.id === segment.segmentation) }
      formatLabel={ s => s.label }
      matchKey={ "id" }
      onSelect={ s => segment.segmentBy(s.id) }
      />
  );

}

export interface ISegmentTransactions {
  type?: TransactionType
}