import React from "react";
import { useCoreAPI } from "../use-core-api";
import { useIdentity } from "@bookie/module-identity";
import { ErrorAPI, OrgTemplate, OrgTemplateOrphan } from "@bookie/glossary";
import { useValidator } from "@bookie/utils";
import { useNotification } from "@bookie/components";

export const useOrgTemplateEditor = (): IOrgTemplateEditorAPI => {

  const n = useNotification();
  const api = useCoreAPI();
  const { ownership } = useIdentity();

  const [ estimateFooter, setEstimateFooter ] = React.useState<string>(ownership.current?.template?.estimateFooter || "");
  const [ invoiceFooter, setInvoiceFooter ] = React.useState<string>(ownership.current?.template?.invoiceFooter || "");
  const [ receiptFooter, setReceiptFooter ] = React.useState<string>(ownership.current?.template?.receiptFooter || "");

  const [ isUpdatingOrgTemplate, setIsUpdatingOrgTemplate ] = React.useState<boolean>(false);

  const _orgTemplate = {
    ...ownership.current?.template,
    estimateFooter,
    invoiceFooter,
    receiptFooter
  };

  const {
    isVirgin,
  } = useValidator(
    () => true,
    {
      estimateFooter, invoiceFooter, receiptFooter
    },
    undefined,
    true
  );

  return ({

    data: _orgTemplate,

    edit: {
      estimateFooter: setEstimateFooter,
      invoiceFooter: setInvoiceFooter,
      receiptFooter: setReceiptFooter
    },
    
    commit: async () => {
      try {
        if (!isVirgin && ownership.current?.id) {
          setIsUpdatingOrgTemplate(true);
          const { orgTemplate } = (
            _orgTemplate.id 
              ? await api.updateOrgTemplate(_orgTemplate)
              : await api.createOrgTemplate(ownership.current.id, _orgTemplate)
          );
          ownership.syncOrgTemplate(orgTemplate);
          n.notify({ type: "success", message: "Successfully updated your templates." });
          return orgTemplate;
        }
      } catch (e) {
        setIsUpdatingOrgTemplate(false);
        if (e instanceof ErrorAPI) {
          n.notify({ type: "error", message: e.error });
        }
      }
      return false;
    },

    state: {
      isWorking: isUpdatingOrgTemplate,
      isUpdatingOrgTemplate
    }

  });

}

export interface IOrgTemplateEditorAPI {
  data: OrgTemplateOrphan,
  edit: {
    estimateFooter: (v: string) => void,
    invoiceFooter: (v: string) => void,
    receiptFooter: (v: string) => void
  },
  commit: () => Promise<OrgTemplate | false>
  state: IOrgTemplateEditorState
}

export interface IOrgTemplateEditorState {
  isWorking: boolean
  isUpdatingOrgTemplate: boolean
}