import React from "react";
import { UserProfile, UserConfiguration } from "@bookie/glossary";
import { useIdentityAPI } from "../use-identity-api";

export const useUserIdentity = (): IUserIdentity => {

  const {
    getUserProfile,
    getUserConfiguration
  } = useIdentityAPI();

  const [ profile, setProfile ] = React.useState<UserProfile>();
  const [ refreshProfile, setRefreshProfile ] = React.useState<boolean>(true);

  const [ configuration, setConfiguration ] = React.useState<UserConfiguration>();
  const [ refreshConfiguration, setRefreshConfiguration ] = React.useState<boolean>(true);
  
  const [ hasInitialisedIdentity, setHasInitialisedIdentity ] = React.useState<boolean>(false);

  // Fetches the user's public profile
  // Note: This is the user's personal 
  // profile and has nothing to do with 
  // a user's business.

  React.useEffect(() => {
    (async () => {
      if (refreshProfile && typeof getUserProfile === "function") {
        const { profile: _p } = await getUserProfile();
        _p && setProfile(_p);
        setRefreshProfile(false);
      }
    })();
  }, [ refreshProfile, getUserProfile ]);

  // Fetches the user's configuration data 
  // which holds application-level config
  // for the individual user.

  React.useEffect(() => {
    (async () => {
      if (refreshConfiguration && typeof getUserConfiguration === "function") {
        const { configuration: _c } = await getUserConfiguration();
        _c && setConfiguration(_c);
        setRefreshConfiguration(false);
      }
    })();
  }, [ refreshConfiguration, getUserConfiguration ]);
  

  // This effect is used to identify,
  // mind the pun, we we have fetched the user's
  // profile and configuration.

  React.useEffect(() => {
    if (
      profile?.id && 
      configuration?.id && 
      !hasInitialisedIdentity
    ) {
      setHasInitialisedIdentity(true);
    }
  }, [ profile, configuration, hasInitialisedIdentity ]);

  return {
    
    profile,
    refreshProfile: () => setRefreshProfile(true),

    configuration,
    refreshConfiguration: () => setRefreshConfiguration(true),

    hasInitialisedIdentity

  };

}

export interface IUserIdentity {
  profile?: UserProfile
  refreshProfile?: () => void
  configuration?: UserConfiguration
  refreshConfiguration?: () => void,
  hasInitialisedIdentity: boolean
}