import { SupabaseClient } from "@supabase/supabase-js";
import { PaymentOrphan, Transaction, TransactionOffering, TransactionOfferingOrphan, TransactionOrphan, TransactionType, ErrorValidation, Payment, uuid } from "@bookie/glossary";
// import { _createEntity, validateEntity } from "@bookie/module-core";
import { _createTransactionOfferings } from "./_create-transaction-offerings";
import { validateTransaction } from "../fns/validate-transaction";
import { validateTransactionOfferings } from "../fns/validate-transaction-offerings";
import { _createTransaction } from "./_create-transaction";
import { validateTransactionPayments } from "../fns/validate-transaction-payments";
import { _createTransactionPayments } from "./_create-transaction-payments";

export const _createTransactionExpanded = (sb: SupabaseClient): CreateTransactionExpanded => 
  async (
    tx,
    transactionType, 
    createdEntityId, 
    transactionOfferings, 
    payments
  ) => {

    // Because this is a multi-layered API endpoint
    // that creates a number of related objects,
    // we want to essentially perform a dry run 
    // of the validation. 
    // We want to avoid a scenario where the first 
    // object is valid, but the second one isn't,
    // but we only find out after we've created the 
    // first. 

    // Each validation will still run again after 
    // this dry run, but that's fine. It's pretty safe to 
    // assume we won't have any issues. 

    // if (entity) {
    //   const entityValidation = validateEntity(entity);
    //   if (!entityValidation.isValid) {
    //     throw new ErrorValidation({
    //       name: "entity",
    //       errors: entityValidation.errors
    //     });
    //   }
    // }

    const txValidation = validateTransaction(tx, transactionType, { ignoreEntities: createdEntityId ? true : false });
    if (!txValidation.isValid) {
      throw new ErrorValidation({
        name: "transaction",
        errors: txValidation.errors
      });
    }

    if (transactionOfferings && transactionOfferings.length > 0) {
      const transactionOfferingsValidation = validateTransactionOfferings(transactionOfferings);
      if (!transactionOfferingsValidation.isValid) {
        throw new ErrorValidation({
          name: "transaction_offerings",
          errorsArray: transactionOfferingsValidation.errors
        });
      }
    }

    if (payments && payments.length > 0) {
      const paymentsValidation = validateTransactionPayments(payments);
      if (!paymentsValidation.isValid) {
        throw new ErrorValidation({
          name: "payments",
          errorsArray: paymentsValidation.errors
        });
      }
    }

    // TODO 
    // We need to figure out if the new entity 
    // to create already exists. 
    // HINT use supabase.upsert

    if (transactionType === "income" && !tx.sourceId && createdEntityId) {
      tx.sourceId = createdEntityId;
    }

    if (transactionType === "expense" && !tx.destinationId && createdEntityId) {
      tx.destinationId = createdEntityId;
    }

    // TODO 
    // Explain?

    const { transaction } = await _createTransaction(sb)(tx, transactionType);

    // TODO 
    // Refactor this so maybe instead of
    // creating transaction in a conditional,
    // it will throw an error. 
    // This will help us avoid playing hot potato
    // with the returned data.

    let _transactionOfferings: TransactionOffering[] = [];
    if (transactionOfferings && transactionOfferings.length > 0) {
      const { transactionOfferings: _to } = await _createTransactionOfferings(sb)(
        transaction.id,
        transactionOfferings
      );
      _transactionOfferings = _to
    }

    let _payments: Payment[] = [];
    if (payments && payments.length > 0) {
      const { transactionPayments: _p } = await _createTransactionPayments(sb)(
        transaction.id,
        payments
      );
      _payments = _p;
    }

    return {
      data: {
        transaction,
        offerings: _transactionOfferings,
        payments: _payments
      }
    };

  }

export type CreateTransactionExpanded = (
  tx: TransactionOrphan,
  transactionType: TransactionType,
  createdEntityId?: uuid,
  transactionOfferings?: TransactionOfferingOrphan[],
  payments?: PaymentOrphan[]
) => Promise<CreateTransactionExpandedResponse>

export interface CreateTransactionExpandedResponse {
  data: {
    transaction: Transaction,
    offerings?: TransactionOffering[],
    payments?: Payment[]
  }
}