import React from "react"
import { ITransactionEditorAPI } from "../../../use/use-transaction-editor";
import { Button, IconApprove, IconReject } from "@bookie/components";
import { ITransactionEditorLifecycle } from "../../transaction-editor";

export const TransactionAssistEstimateApprove: React.FC<ITransactionAssistEstimateApprove> = (props) => {

  return (
    <div
      style={{ 
        maxWidth: "70%",
        width: "70%" 
      }}
      className={ "y space-cubes-children align-x-center" }>
      
      <div
        style={{ textAlign: "center", maxWidth: "70%" }}
        className={ "space-inline-children y" }>
        <h1>
          { "Yay or Nay?" }
        </h1>
        <h2>
          { "This Estimate is marked as sent." }
        </h2>
        <p
          className={ "space-lines-frame-t" }>
          { "When your Client gets back to you with their answer, approve or reject this Estimate to convert it into an Invoice." }
        </p>
      </div>

      <div 
        style={{ maxWidth: "60%" }}
        className={ "x fill-x space-inline-children align-x-center fill-btns" }>

        <Button
          icon={ <IconApprove /> }
          surface={ "surface-primary-positive" }
          label={ "Approve" }
          onClick={ async () => {
            await props.editor?.commit({
              estimateState: "approved",
              invoiceState: "draft"
            });
          } } />

        <Button
          icon={ <IconReject /> }
          surface={ "surface-primary-negative" }
          label={ "Reject" }
          onClick={ async () => {
            await props.editor?.commit({
              estimateState: "rejected",
              invoiceState: undefined
            });
          } } />

      </div>

      <Button 
        variant={ "inline" }
        label={ "Or do it later." }
        onClick={ props.onFinish } />

    </div>
  );
}

export interface ITransactionAssistEstimateApprove extends ITransactionEditorLifecycle {
  editor?: ITransactionEditorAPI
}