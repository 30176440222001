import { TransactionExpanded, TransactionExpandedSegment } from "@bookie/glossary";
import { getPaymentGrossLocalised } from "@bookie/module-bookkeeping";
import { useIdentity } from "@bookie/module-identity";

export const useTransactionCurrencyProfitAndLoss = (
  transactions: TransactionExpanded[]
): IUseTransactionCurrencyProfitAndLoss => {

  const { ownership } = useIdentity();

  let profit = 0;
  const profitTransactions: TransactionExpanded[] = [];

  let loss = 0;
  const lossTransactions: TransactionExpanded[] = [];

  for (const tx of transactions) {
    if (ownership.current?.entity?.currencyCode !== tx.currencyCode) {
      if (tx.paymentState === "paid_in_full" && tx.payments && tx.payments?.length > 0) {

        const invoicedGross = tx.summary?.localised?.gross || 0;

        let paidGross = 0;

        for (const payment of (tx.payments || [])) {
          const paid = getPaymentGrossLocalised(payment, ownership.current?.entity?.currencyCode || "");
          if (paid.currencyCode === ownership?.current?.entity?.currencyCode) {
            paidGross += paid.value;
          }
        }

        if (invoicedGross < paidGross) {
          profit = profit + (paidGross - invoicedGross);
          profitTransactions.push(tx);
        }
        else if (invoicedGross > paidGross) {
          loss = loss + (invoicedGross - paidGross);
          lossTransactions.push(tx);
        }
      }
    }
  }

  return {
    profit: {
      label: "Profits from Currency Exchange",
      summary: {
        all: {
          gross: profit,
          net: 0,
          cost: 0
        }
      },
      transactions: profitTransactions,
      type: "currency-exchange-diff"
    },
    loss: {
      label: "Losses from Currency Exchange",
      summary: {
        all: {
          gross: 0,
          net: 0,
          cost: loss
        }
      },
      transactions: lossTransactions,
      type: "currency-exchange-diff"
    }
  };

}

export interface IUseTransactionCurrencyProfitAndLoss {
  profit: TransactionExpandedSegment
  loss: TransactionExpandedSegment
}