import moment from "moment"

export const getDaysOfThisMonth = (today: moment.Moment): string[] => {

  const _thisMonth = today.daysInMonth();

  const _days: string[] = [];

  for (let day = 1; day <= _thisMonth; day++) {
    const date = today.clone().date(day);
    _days.push(date.format("DD"));
  }

  return _days;

}