import { ErrorAPI, TransactionOffering } from "@bookie/glossary"
import { objectToCamel } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js"

export const _deleteTransactionOfferings = (sb: SupabaseClient): DeleteTransactionOfferings => 
  async (
    transactionOfferings
  ) => {

    const { data, error } = (
      await sb
        .from("transaction_offerings")
        .delete()
        .in(
          "id", 
          transactionOfferings.map(to => to.id)
        )
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }    

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      transactionOfferings: objectToCamel<TransactionOffering[]>(data),
      error
    };

  }

export type DeleteTransactionOfferings = (
  transactionOfferings: TransactionOffering[]
) => Promise<DeleteTransactionOfferingsResponse>

export interface DeleteTransactionOfferingsResponse {
  transactionOfferings: TransactionOffering[]
}