import React from "react";
import { IUseTransactionsAPI, useTransactions } from "./_use/use-transactions";
import { useTransactionFilter, ITransactionFilters } from "./_use/use-transaction-filter";
import { useTransactionSort } from "./_use/use-transaction-sort";
import { useTransactionInference } from "./_use/use-transaction-inference";
import { useTransactionSummary } from "./_use/use-transaction-summary";
import { TransactionExpanded, TransactionSegmentGroup, uuid } from "@bookie/glossary";
import { useTransactionSegmentation, ITransactionSegmentationSegment } from "./_use/use-transaction-segmentation";
import { useGroupSegments } from "./_use/use-group-segments";
import { useIdentity } from "@bookie/module-identity";

const BookkeepingCtx = React.createContext<BookkeepingAPI | undefined>(undefined);

const BookkeepingCtxProvider: React.FC<IBookkeepingCtxProvider> = (props) => {

  const { ownership } = useIdentity();
  const { transactions, api } = useTransactions();
  
  const { filteredTransactions, filter } = useTransactionFilter(transactions);
  const { sortedTransactions } = useTransactionSort(filteredTransactions);
  const { summarisedTransactions } = useTransactionSummary(sortedTransactions, ownership.current?.entity);

  const { segmentedTransactions, segment } = useTransactionSegmentation(summarisedTransactions, filter);
  
  const { group: groupedSegments } = useGroupSegments(segmentedTransactions);

  // Not sure if we need this yet. 
  const raw = useTransactionInference(transactions);

  return (
    <BookkeepingCtx.Provider
      value={{
        transactions: {
          all: sortedTransactions,
          segmented: groupedSegments,
          meta: {
            totalExpenses: raw.expenses.length || 0,
            totalIncome: raw.income.length || 0
          },
          find: (id?: uuid) => sortedTransactions.find(t => t.id === id)
        },
        filter,
        segment: segment,
        api: api
      }}>
      { props.children }
    </BookkeepingCtx.Provider>
  );
}

export interface IBookkeepingCtxProvider {
  children?: React.ReactNode
}

const useBookkeeping = (
  prehook?: (ctx: BookkeepingAPI) => void
): BookkeepingAPI => {
  const value = React.useContext(BookkeepingCtx);
  if (value === undefined) {
    throw new Error("Missing BookkeepingCtxProvider");
  }
  prehook?.(value);
  return value;
}

export { BookkeepingCtxProvider, useBookkeeping }

interface BookkeepingAPI {
  transactions: {
    all?: TransactionExpanded[],
    segmented?: TransactionSegmentGroup,
    income?: TransactionSegmentGroup,
    expenses?: TransactionSegmentGroup,
    meta: {
      // Not a big fan of this. 
      // It currently only works because 
      // we are fetching all a user's transactions. 
      // In the long run, this is not very
      // performant. 
      totalIncome: number,
      totalExpenses: number
    },
    find: (id?: uuid) => TransactionExpanded | undefined
  },
  filter: ITransactionFilters,
  segment: ITransactionSegmentationSegment,
  api: IUseTransactionsAPI
}