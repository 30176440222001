import React from "react";
import { Entity, uuid } from "@bookie/glossary";
import { useCoreAPI } from "../use-core-api";

export const useEntities = (): IUseEntities => {

  const {
    getEntities
  } = useCoreAPI();

  const [ entities, setEntities ] = React.useState<Entity[]>([]);
  const [ refreshEntities, setRefreshEntities ] = React.useState<boolean>(true);

  React.useEffect(() => {
    (async () => {
      if (refreshEntities && typeof getEntities === "function") {
        const { entities: _e } = await getEntities();
        _e && setEntities(_e);
        setRefreshEntities(false);
      }
    })();
  }, [ refreshEntities, getEntities ]);

  const sync = (entity: Entity) => {
    setEntities((prevOfferings) => {
      const existingIndex = prevOfferings.findIndex(o => o.id === entity.id);

      if (existingIndex !== -1) {
        // Replace the existing entity
        const updatedEntities = [...prevOfferings];
        updatedEntities[existingIndex] = entity;
        return updatedEntities;
      } else {
        // Add the new entity
        return [...prevOfferings, entity];
      }
    });
  }

  const remove = (entityId: string) => {
    setEntities((prevOfferings) => {
      return prevOfferings.filter(o => o.id !== entityId);
    });
  }

  return {
    
    entities,
    clients: entities.filter(e => e.type === "client"),
    merchants: entities.filter(e => e.type === "merchant"),
    uncategorised: entities.filter(e => e.type !== "merchant" && e.type !== "client" && e.type !== "self"),
    
    api: {
      sync,
      remove
    }

  };
}

export interface IUseEntities {
  entities?: Entity[],
  clients?: Entity[],
  merchants?: Entity[],
  uncategorised?: Entity[]
  api: IUseEntitiesAPI
}

export interface IUseEntitiesAPI {
  sync: (e: Entity) => void
  remove: (eId: uuid) => void
}