import { EstimateState, InvoiceState, PaymentState, TransactionStage } from "@bookie/glossary";

export const getTransactionStage = (
  estimateState?: EstimateState,
  invoiceState?: InvoiceState,
  paymentState?: PaymentState
): TransactionStage => {

  if (
    !estimateState ||
    estimateState === "draft" ||
    estimateState === "submitted" ||
    estimateState === "rejected"
  ) {
    return "estimate";
  } else {
    
    if (
      invoiceState === "draft" ||
      invoiceState === "rejected"
    ) {
      return "invoice";
    } else {
      return "payment";
    }

  }
}