import React from "react";
import { Session } from "@supabase/supabase-js";
import { useSupabase } from "@bookie/supabase";
import { Login } from "../../views/login";
// import { jwtDecode } from "jwt-decode";

export const Authenticator: React.FC<IAuthenticator> = (props) => {

  const { supabase } = useSupabase();
  const [ session, setSession ] = React.useState<Session | null>(null);

  const [ hasInitialised, setHasInitialised ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {

      const _session = await supabase.auth.getSession();
      setSession(_session.data.session);

      setHasInitialised(true);

      // Use if you want to see inside the access token
      // if (_session.data.session?.access_token) {
      //   const decoded = jwtDecode(_session.data.session?.access_token);
      // }

      supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session);
      });
      
    })();
  }, [supabase]);

  if (!hasInitialised) {
    return (
      <div 
        className={ "surface-primary fill" }>
      </div>
    );
  }

  return (
    session 
      ? props.authenticatedApp 
      : <Login />
  );

};

export interface IAuthenticator {
  authenticatedApp: React.ReactNode;
  children?: React.ReactNode;
}
