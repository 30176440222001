import React from "react";
import { useCurrency } from "@hny_/currency";
import { Button, IconAdd, Empty, Workbench } from "@bookie/components";
import { Stat } from "@bookie/components";
import { useBookkeeping } from "../../use-bookkeeping";
import { TransactionFilter } from "../../components/transaction-filter";
import { TransactionEditor } from "../transaction-editor";
import { TransactionList } from "../../components/transaction-list";
import { uuid } from "@bookie/glossary";
// import { TransactionsChart } from "../../components/transactions-chart";

export const ExpenseDashboard: React.FC<IExpenseDashboard> = (props) => {

  const { transactions } = useBookkeeping();
  console.log(transactions);

  const { toHumanReadable } = useCurrency();

  const isEmpty = (
    transactions.meta.totalExpenses === 0
  );

  const [ transactionId, setTransactionId ] = React.useState<uuid | "create">();

  return (
    <div
      className={ "y fill-x" }>

      <div
        className={ "y border-r surface-alt border-b" }>
        
        <div 
          className={ "x space-lines-frame" }>
          <Stat
            label={ "Expenses" }
            value={ toHumanReadable(transactions.expenses?.summary.accrued?.gross || 0, undefined, undefined, true) } />
        </div>
        
        {/* <TransactionsChart
          labels={ summary.expenses.segmented?.segments || [] }
          values={ summary.expenses.segmented?.gross || [] } /> */}
        
      </div>

      <div 
        style={{ position: "relative" }}
        className={ "x fill-x fill-y" }>

        <div 
          className={ "y fill-x surface-aside" }>
          
          <TransactionFilter
            type={ "expense" }
            actions={[
              
                !isEmpty && 
                  <Button
                    label={ "Expense" }
                    icon={ <IconAdd /> }
                    onClick={ () => {
                      setTransactionId("create")
                    } } />
              
            ]} />
          
          <div
            style={{ height: "0", maxWidth: "1400px" }} // <- we need this to activate scroll overflow, since the height is variable with flex-grow
            className={ "y fill-y scrollable space-lines-frame-tb space-cubes-frame-lr space-inline-children border-r" }>
            
            <TransactionList
              transactionSegments={ transactions.expenses?.segments }
              selectedTransactionId={ transactionId }
              onSelectTransaction={ (id?: uuid) => setTransactionId(id) } />

            {
              isEmpty && 
                <Empty
                  label={ "This is where you can find all of your expenses" }
                  description={[
                    "Welcome to the Expenses page — where you keep track of every cost your business faces. Whether it's bills, supplies, or any other outgoing payments, this is where everything is recorded.",
                    "In Bookie, everything revolves around a transaction. When it comes to expenses, you are simply the payer of this transaction. "
                  ]}
                  callToActions={
                    <div 
                      className={ "x space-lines-children space-lines-frame-t" }>

                      <Button
                        className={ "surface-button-link" }
                        icon={ <IconAdd /> }
                        label={ "Create your first Expense" } 
                        onClick={ () => setTransactionId("create") } />
                      
                    </div>
                  } />
                }

          </div>

        </div>

        <Workbench
          isOpen={ typeof transactionId !== "undefined" }
          onClose={ () => setTransactionId(undefined) }>
          
          {
            transactionId &&
              <TransactionEditor 
                transactionType={ "expense" }
                txId={ transactionId }
                transactionStage={ "invoice" }
                onFinish={ () => setTransactionId(undefined) } /> 
          }

        </Workbench>

      </div>

    </div>
  );
}

export interface IExpenseDashboard {}