import { TransactionDocumentType, TransactionOrphan } from "@bookie/glossary";

export const getDocumentDates = (
  type: TransactionDocumentType, 
  tx?: TransactionOrphan
): DocumentDate[] => {
  switch (type) {

    case ("estimate"): {
      return [
        { label: "Date", date: tx?.estimateDate },
        { label: "Expires", date: tx?.estimateExpiration }
      ];
    }

    case ("invoice"): {
      return [
        { label: "Date", date: tx?.invoiceDate },
        { label: "Due", date: tx?.invoiceDue }
      ];
    }

    case ("receipt"): {
      return []
    }

  }
}

export interface DocumentDate {
  label: string 
  date?: string
}