import { Entity, TransactionExpanded, TransactionExpandedSegment, TransactionFilterPeriods, TransactionSegments, TransactionType } from "@bookie/glossary";
import { segmentByMonth } from "./segment-by-month";
import { segmentByEntity } from "./segment-by-entity";

export const segmentTransactions = (
  txs: TransactionExpanded[], 
  segmentation: TransactionSegments,
  type: TransactionType,
  orgEntity?: Entity,
  period?: TransactionFilterPeriods,
  entities?: Entity[]
): TransactionExpandedSegment[] => {

  switch (segmentation) {

    case "segment_by_month": {
      return segmentByMonth(txs, type, orgEntity, period);
    }

    case "segment_by_entity": {
      return segmentByEntity(txs, type, orgEntity, period, entities);
    }

    default: {
      return [{
        transactions: txs
      }];
    }

  }

}