import { ErrorAPI, uuid } from "@bookie/glossary";
import { SupabaseClient } from "@supabase/supabase-js";

export const _uploadDocument = (sb: SupabaseClient): UploadDocument => 
  async (orgId, filename, file) => {

    const { data, error } = (
      await sb
        .storage 
        .from("org_documents")
        .upload(
          `${ orgId }/${ filename }`,
          file
        )
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }
    
    // TODO 
    // change this to data.fullPath 
    // (need to fix Type errors)

    return {
      filepath: data.path
    };

  }


export type UploadDocument = (
  orgId: uuid,
  filename: string,
  file: File
) => Promise<UploadDocumentResponse>

export interface UploadDocumentResponse {
  filepath: string
}