import React from "react"
import { ITransactionEditorAPI } from "../../../use/use-transaction-editor";
import { Button, IconInvoice } from "@bookie/components";
import { SendInvoice } from "../../../components/send-invoice";
import { ITransactionEditorLifecycle } from "../../transaction-editor";

export const TransactionAssistInvoiceSend: React.FC<ITransactionAssistInvoiceSend> = (props) => {
  return (
    <div
      style={{ 
        maxWidth: "70%",
        width: "70%" 
      }}
      className={ "y space-lines-children align-x-center" }>

        <div
          className={ "" }>
          <IconInvoice
            size={ 40 } />
        </div>

        <div
          style={{ textAlign: "center" }}
          className={ "space-lines-frame-b space-inline-children y" }>
          <h1>
            { "Your Invoice is almost ready" }
          </h1>
          <h2>
            { "The next step is to review the PDF and send it to your Client" }
          </h2>
        </div>

        <div
          className={ "y fill-x align-x-center space-cubes-children" }>
          
          <div 
            className={ "y align-x-center" }>
            <SendInvoice
              size={ "normal" }
              label={ "Review and Send" }
              editor={ props.editor }
              saveOnFinish={ true }
              onComplete={ props.onCompleteStep } />
            <Button 
              size={ "slim" }
              variant={ "text" }
              label={ "Mark as Sent" }
              onClick={ async () => {
                await props.editor?.commit({ invoiceState: "submitted" });
                props.onCompleteStep?.();
              } } />
          </div>
          
          <div 
            className={ "y align-x-center" }>
            <p>{ "Alternatively, you can" }</p>
            <div 
              className={ "x align-y-center space-inline-children" }>
              <Button 
                variant={ "inline" }
                label={ "See the Invoice" }
                onClick={ () => {
                  props.onFinishAssist?.();
                } } />
              <p>{ ", " }</p>
              <Button 
                variant={ "text" }
                label={ "Create another" }
                onClick={ () => {
                  props.onAnother?.();
                } } />
              <p>{ "or" }</p>
              <Button 
                variant={ "text" }
                label={ "Finish" }
                onClick={ props.onFinish } />
            </div>
          </div>
        
        </div>
    
    </div>
  );
}

export interface ITransactionAssistInvoiceSend extends ITransactionEditorLifecycle {
  editor?: ITransactionEditorAPI
}