import { ObjectFieldValidation, ObjectValidation, ContactOrphan } from "@bookie/glossary";

export const validateContact = 
  (
    contact: ContactOrphan
  ): ObjectValidation => {
  
  let errors: ObjectFieldValidation = {};
  
  if (
    typeof contact.nameGiven === "undefined"
  ) {
    errors = {
      ...errors,
      nameGiven: "What is the first name of this contact?"
    };
  }

  if (
    typeof contact.nameFamily === "undefined"
  ) {
    errors = {
      ...errors,
      nameFamily: "What is the last name of this contact?"
    };
  }

  return {
    isValid: Object.keys(errors).length > 0 ? false : true,
    errors
  };

}