import { SupabaseClient } from "@supabase/supabase-js";
import { ErrorAPI, Transaction } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";

export const _getTransactions = (sb: SupabaseClient): GetTransactions => 
  async () => {

    const { data, error } = (
      await sb
        .from("transaction")
        .select("*")
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      transactions: objectToCamel<Transaction[]>(data as Transaction[])
    };

  };

export type GetTransactions = () => Promise<GetTransactionsResponse>

export interface GetTransactionsResponse {
  transactions: Transaction[];
}