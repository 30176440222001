import { ErrorAPI, Offering } from "@bookie/glossary";
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel, objectToSnake } from "@bookie/utils";

export const _updateOffering = (sb: SupabaseClient): UpdateOffering => 
  async (offering) => {

    const { data, error } = (
      await sb 
        .from("offering")
        .update(objectToSnake(offering))
        .eq("id", offering.id)
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      offering: objectToCamel<Offering>(data[0])
    };

  }

export type UpdateOffering = (
  offering: Offering
) => Promise<UpdateOfferingResponse>

export interface UpdateOfferingResponse {
  offering: Offering
}