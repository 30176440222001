import React from "react";
import { Button } from "@bookie/components";
import { useSupabase } from "@bookie/supabase";

export const RemoteApp: React.FC<IRemoteApp> = (props) => {

  const [ a, setA ] = React.useState();
  const { supabase } = useSupabase();

  return (
    <div className={ "surface-primary x fill-x fill-y center" }>
      <Button
        label={ "Ask GPT4o" }
        onClick={ async () => {

          const { data } = await supabase.functions.invoke("recognise-expense-openai", {
            body: { query: "hello" }
          });

          console.log(data);

        } } />
      { a && a }
    </div>
  );
}

export interface IRemoteApp {
}