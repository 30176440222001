import { EntityOrphan, ObjectFieldValidation, ObjectValidation } from "@bookie/glossary";

export const validateEntity = 
  (
    entity: EntityOrphan
  ): ObjectValidation => {
  
  let errors: ObjectFieldValidation = {};
  
  if (
    typeof entity.name === "undefined" ||
    !entity.name
  ) {
    errors = {
      ...errors,
      name: "What is the name of this company or individual?"
    };
  }

  if (
    typeof entity.countryCode === "undefined" ||
    !entity.countryCode
  ) {
    errors = {
      ...errors,
      countryCode: "In which country do they operate in?"
    };
  }

  if (
    typeof entity.currencyCode === "undefined" ||
    !entity.currencyCode
  ) {
    errors = {
      ...errors,
      currencyCode: "What currency do they use?"
    };
  }

  return {
    isValid: Object.keys(errors).length > 0 ? false : true,
    errors
  };

}