import { Address, AddressOrphan, ErrorAPI } from "@bookie/glossary"
import { SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel, objectToSnake, stripNullAndUndefinedFromObject } from "@bookie/utils";

export const _createAddress = (sb: SupabaseClient): CreateAddress => 
  async (address) => {

    const { data, error } = (
      await sb
        .from("address")
        .insert([
          stripNullAndUndefinedFromObject(objectToSnake<AddressOrphan>(address))
        ])
        .select()
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }

    return {
      address: objectToCamel<Address>(data[0])
    };

  }

export type CreateAddress = (
  address: AddressOrphan
) => Promise<CreateAddressResponse>

export interface CreateAddressResponse {
  address: Address
}