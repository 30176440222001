import { uuid } from "@bookie/glossary";
import { InputText, InputBase, InputNumber } from "@bookie/components";
import React from "react";
import cx from "classnames";
import { IEntityEditorAPI, useEntityEditor } from "../../use/use-entity-editor";
import { SelectCountryPhone } from "../../components/select-country-phone";

export const EntityEditorContact: React.FC<IEntityEditorContact> = (props) => {

  const editor = useEntityEditor(
    props.entityId,
    props.editor
  );

  return (
    <div
      className={ cx(
        "y fill-x space-lines-children"
      ) }>

        <InputText
          type={ "text" }
          label={ "Email" }
          value={ editor.data.email }
          className={ "fill-inputs" }
          onChange={ v => editor.edit.email(v as string) }
          error={ editor.errors.validation?.["email"] } />
        
        <div 
          className={ "y" }>
          
          <InputBase
            type={ "text" }
            label={ "Phone Number" } />
        
          <div 
            className={ "x align-y-baseline space-inline-children" }>
            
            <SelectCountryPhone
              selectedPhoneCountryCode={ editor.data.phoneCountryCode }
              onSelect={ (c) => editor.edit.phoneCountryCode(c as string) }
              error={ editor.errors.validation?.phoneCountryCode } />
            
            <InputNumber
              type={ "number" }
              value={ editor.data.phoneNumber }
              placeholder={ "Your phone number" }
              onChange={ v => editor.edit.phoneNumber(v as number)}
              error={ editor.errors.validation?.phoneNumber } />

          </div>
      
        </div>
    
    </div>
  );
}

export interface IEntityEditorContact {
  entityId?: uuid | "create"
  editor?: IEntityEditorAPI
}