import { EntityExpanded, EntityOrphan } from "@bookie/glossary"
import { PostgrestError, SupabaseClient } from "@supabase/supabase-js";
import { objectToCamel, objectToSnake } from "@bookie/utils";

export const _createEntityExpanded = (sb: SupabaseClient): CreateEntityExpanded => 
  async (entity) => {

    const { data: _data, error } = (
      await sb
        .from("entity")
        .insert([
          objectToSnake<EntityOrphan>(entity)
        ])
        .select()
    );

    return {
      data: objectToCamel<EntityExpanded>(_data?.[0]) || null,
      error
    };

  }

export type CreateEntityExpanded = (
  entity: EntityExpanded
) => Promise<CreateEntityExpandedResponse>

export interface CreateEntityExpandedResponse {
  data: EntityExpanded | null;
  error?: PostgrestError | null;
}