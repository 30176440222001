import React from "react";
import { IconOfferingType, InputSelect } from "@bookie/components";
import { OfferingType, OFFERING_TYPES } from "@bookie/glossary";

export const SelectOfferingType: React.FC<ISelectOfferingType> = (props) => {
  return (
    <InputSelect
      className={ "fill-x" }
      icon={ <IconOfferingType /> }
      name={ "offering-types" }
      description={ props.description }
      label={ props.label }
      type={ "text" }
      size={ props.size }
      options={ OFFERING_TYPES }
      value={ OFFERING_TYPES.find(ot => ot.id === props.selectedOfferingType) }
      actions={ props.actions }
      formatLabel={ (ot) => ot.label }
      matchKey={ "id" }
      onSelect={ (ot) => props.onSelect?.(ot.id) }
      error={ props.error } />
  );
}

export interface ISelectOfferingType {
  label?: string
  description?: string
  selectedOfferingType?: OfferingType
  onSelect?: (type: OfferingType) => void
  actions?: { label: string, onClick: () => void }[]
  size?: "small"
  error?: string
}