import { LogoWhite, LogoBranded } from "@bookie/images";
import React from "react";
import css from "./index.module.css";
import cx from "classnames";
import { navigation } from "./nav";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useIdentity } from "@bookie/module-identity";
import { getLogoUrl } from "@bookie/module-core";
import { useSupabase } from "@bookie/supabase";
import { IconLogo, Tooltip } from "@bookie/components";

export const Menu: React.FC<IMenu> = (props) => {

  const { ownership } = useIdentity();
  const { pathname } = useLocation();
  const { supabase } = useSupabase();
  const navigate = useNavigate();

  const [ isExpanded, setIsExpanded ] = React.useState<boolean>(false);
  const [ breadcrumb, setBreadcrumb ] = React.useState<string[]>([]);

  React.useEffect(() => {
    const _breadcrumb = (
      pathname
        .split("/")
        .filter(i => i.trim() !== "")
    );
    setBreadcrumb(_breadcrumb);
  }, [ pathname ]);

  return (
    <>
    
      <div 
        className={ cx(
          css.menu,
          "y"
        ) }>
      
        <div
          onClick={ () => setIsExpanded(!isExpanded) }
          className={ cx(
            css.menu_bar,
            "x fill-x space-lines-frame-lr space-lines-frame-tb align-y-center align-x-between border-b apply-bg apply-fg",
            isExpanded ? "surface-primary" : "surface"
          ) }>

          <div
            className={ cx(
              css.menu_segment,
              css.segment_entity,
              "x radius space-lines-frame-r space-lines-children align-y-center"
            ) }>
              
              {
                ownership.current?.entity?.logoUrl &&
                  <img 
                    className={ css.menu_logo }
                    src={ getLogoUrl(ownership.current.id) }
                    alt={ "Company Logo" } /> 
              }

              {
                !ownership.current?.entity?.logoUrl &&
                  <div
                    style={{ background: "black" }}
                    onClick={ (e) => {
                      e.stopPropagation();
                      navigate("/business/profile");
                      setIsExpanded(false);
                    } }
                    className={ cx(
                      css.menu_logo_bookie,
                      "x align-x-center align-y-center surface-primary"
                    ) }>
                      <Tooltip
                        message={ "Upload your own logo" }
                        position={ "right" }
                        base={ <IconLogo size={ 32 } /> } />
                  </div>  
              }

              <div
                className={ "y" }>
                <p
                  className={ cx(
                    css.menu_breadcrumb,
                    "x space-inline-children" 
                  )}>
                  <span>
                    <b>{ ownership.current?.entity?.name }</b>
                  </span>
                  {
                    !isExpanded && breadcrumb.map(b => (
                      <>
                        <span>{ "/" }</span>
                        <span>{ b.replaceAll("-", " ") }</span>
                      </>
                    ))
                  }
                </p>

                {
                  ownership.current?.entity?.tin && 
                    <small
                      style={{ marginTop: "3px" }}>
                      { ownership.current.entity?.tin }
                    </small>
                }

              </div>

          </div>

          <div
            className={ cx(
              css.menu_segment,
              css.segment_bookie,
              "x radius align-y-center"
            ) }>
            <img 
              style={{ height: "34px", marginRight: "20px" }}
              src={ isExpanded ? LogoWhite : LogoBranded }
              alt={ "Bookie" } />
          </div>

        </div>

        <div
          className={cx(
            css.menu_nav,
            "surface-primary apply-bg apply-fg x fill-x space-blocks-frame-lr space-blocks-frame-tb",
            isExpanded ? css.menu_nav_expanded : ""
          )}>

          {
            navigation.map(n => (
              <ul
                key={ n.category }
                className={ "y space-inline-children" }>
                <span>{ n.category }</span>
                {
                  n.links?.map(l => (
                    <li 
                      key={ l.label }
                      className={ cx(
                        l.subPlan === "paid" ? `${css.paidFeature}` : "hvr-brighten"
                      ) }>
                      <NavLink 
                        to={ l.path }
                        className={ applySelectedClass }
                        onClick={ () => setIsExpanded(false) }>
                        { l.label }
                      </NavLink>
                    </li>
                  ))
                }
              </ul>
            ))
          }

          <ul 
            className={ "y fill-x align-x-end" }>
            <span>{ "APP" }</span>
            {/* <li><NavLink to={ "" }>{ "Account" }</NavLink></li>
            <li><NavLink to={ "" }>{ "Appearance" }</NavLink></li>
            <li><NavLink to={ "" }>{ "Billing and Plans" }</NavLink></li> */}
            <li
              onClick={ async () =>  {
                await supabase.auth.signOut();
              } }>
              { "Sign Out" }
            </li>
          </ul>

        </div>

      </div>
      
      {
        isExpanded && 
          <div 
            className={ css.menu_blurScreen }
            onClick={ () => setIsExpanded(false) }>
          </div>
      }

    </>
  );
}

export interface IMenu {

}

const applySelectedClass = ({ isActive }: { isActive: boolean }) => {
  return (
    isActive 
      ? css.isSelected
      : ""
  );
}