import { Entity, TransactionExpanded, TransactionExpandedSegment, TransactionFilterPeriods, TransactionType } from "@bookie/glossary";
import { summariseTransactions } from "./summarise-transactions";
import { getEntity } from "@bookie/module-core";
import { auditTransactions } from "./audit-transactions";

export const segmentByEntity = (
  txs: TransactionExpanded[], 
  type: TransactionType,
  orgEntity?: Entity,
  period?: TransactionFilterPeriods,
  entities?: Entity[]
): TransactionExpandedSegment[] => {

  const segments: { [key: string]: TransactionExpanded[] } = {};

  txs.forEach((transaction) => {

    const entityId = (
      type === "income"
        ? transaction.sourceId
        : (
          type === "expense"
            ? transaction.destinationId
            : "Unknown"
        )
    );

    if (!segments[entityId]) {
      segments[entityId] = [];
    }

    segments[entityId].push(transaction);
  
  });

  return Object.entries(segments).map(([entityId, transactions]) => {

    const summary = summariseTransactions(transactions, orgEntity, period);
    const audit = auditTransactions(transactions, type, orgEntity?.currencyCode || "");

    return ({
      label: getEntity(entityId, entities)?.name || entityId,
      transactions,
      summary,
      issues: audit,
      type: "transaction"
    });

  });

}