export class ErrorAPI extends Error {

  override name: string
  error: string

  constructor(
    {
      name = "server",
      error = "It looks like something unexpected went wrong. Please try again or contact support."
    }: 
    {
      name?: string 
      error?: string
    }
  ) {
    super();
    this.name = name;
    this.error = error;
  }

}