import React from "react";
import css from "./index.module.css";
import cx from "classnames";
import { IconDown, IconRight } from "../icon";

export const TableX = <T,P>(props: ITableX<T, P>): React.ReactElement => {

  const [expandedRows, setExpandedRows] = React.useState<Set<number>>(new Set());

  const toggleRow = (index: number) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  return (
    <table 
      className={cx("hny-table", css.table)}>
      
      {
        !props.hideHeader &&
          <thead key={"thead"}>
            <tr key={"header-row"}>

              {
                props.columns?.map((c, i) => (
                  <th
                    key={`header-${i}`}
                    style={
                      c.width 
                        ? { width: c.width }
                        : {} 
                    }
                    className={css[`cell_align_${ c.align || "left" }`]}
                  >
                    { c.header }
                  </th>
                ))
              }

            </tr>
          </thead> 
      }

      <tbody key={"tbody"}>

        {
          props.data?.map((d, i) => (
            <>

              <tr 
                style={
                  expandedRows.has(i)
                    ? ({
                      borderTop: "2px solid #bfbfbf", 
                      borderLeft: "2px solid #bfbfbf",
                      borderRight: "2px solid #bfbfbf"
                    })
                    : {} 
                }
                className={cx(
                  props.onHighlightRowClassName?.(d),
                  { "clickable": props.groupData?.(d) && props.groupData?.(d).length > 0 }
                )}
                onClick={() => {
                  if (props.groupData?.(d) && props.groupData?.(d).length > 0) {
                    toggleRow(i);
                  } else {
                    props.onClickRow?.(d);
                  }
                }}
                key={`row-${i}`}>
                {
                  props.columns?.map((column, j) => (
                    <td 
                      key={`cell-${j}`}
                      style={
                        column.width 
                          ? { width: column.width }
                          : {} 
                      }
                      className={ css[`cell_align_${column.align || "left"}`] }>
                      <div 
                        className={ cx(
                          "x space-inline-children",
                          { "align-x-end": column.align === "right" },
                          { "align-x-start": column.align === "left" || !column.align }
                        ) }>
                        
                        {
                          (j === 0 && props.groupData?.(d) && props.groupData?.(d).length > 0 && expandedRows.has(i)) && 
                            <IconDown />
                        }

                        {
                          (j === 0 && props.groupData?.(d) && props.groupData?.(d).length > 0 && !expandedRows.has(i)) && 
                            <IconRight />
                        }

                        <p>{ column.renderCell(d) }</p>

                      </div>
                    </td>
                  ))
                }
              </tr>

              {
                (props.groupData && props.groupData.length > 0 && expandedRows.has(i)) && 
                  <tr
                    style={{ 
                      borderBottom: "2px solid #bfbfbf", 
                      borderLeft: "2px solid #bfbfbf",
                      borderRight: "2px solid #bfbfbf"
                    }}
                    key={ `nested-data-${ i }` }>
                    <td 
                      colSpan={ props.columns?.length }
                      className={ css.nestedRow }>
                      <TableX
                        columns={ props.groupColumns?.(d).map(gc => ({
                          ...gc,
                          renderCell: (xx) => gc.renderCell(xx, d)
                        })) }
                        data={ props.groupData(d) }
                        onHighlightRowClassName={ (e) => {
                          return props.onHighlightGroupedRowClassName?.(d, e) || "";
                        } }
                        onClickRow={ (e) => {
                          props.onClickGroupedRow?.(d, e);
                        } } />
                    </td>
                  </tr>
              }

            </>
          ))
        }

      </tbody>
    
    </table>
  );
}

export interface ITableX<L1, L2> {
  
  columns?: ITableXColumn[]
  data?: L1[]
  onHighlightRowClassName?: (a: L1) => string;
  onClickRow?: (a: L1) => void;

  hideHeader?: boolean;

  groupColumns?: (a: L1) => ITableXColumn[]
  groupData?: (a: L1) => L2[]
  onHighlightGroupedRowClassName?: (a: L1, b: L2) => string;
  onClickGroupedRow?: (a: L1, b: L2) => void;

}

export interface ITableXColumn {
  header: string
  align?: "left" | "right"
  width?: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCell: (d: any, x?: any) => React.ReactNode
}