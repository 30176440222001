export const navigation = [
  {
    category: "Bookkeeping",
    links: [
      // {
      //   label: "Mission Control",
      //   path: "/",
      //   subPlan: "free"
      // },
      {
        label: "Income & Expenses",
        path: "/bookkeeping/income-&-expenses",
        subPlan: "free"
      },
      // {
      //   label: "Expenses",
      //   path: "/bookkeeping/expenses",
      //   subPlan: "free"
      // }
    ]
  },
  {
    category: "Taxes",
    links: [
      {
        label: "VAT",
        path: "/taxes/vat",
        subPlan: "paid"
      },
      {
        label: "Income Tax",
        path: "/taxes/income",
        subPlan: "paid"
      },
      {
        label: "Social Security",
        path: "/taxes/ss",
        subPlan: "paid"
      }
    ]
  },
  {
    category: "Financials & Reporting",
    links: [
      {
        label: "Profit & Loss",
        path: "/financials/profit-&-loss",
        subPlan: "free"
      },
      {
        label: "Balance Sheet",
        path: "/financials/balance-sheet",
        subPlan: "paid"
      },
      {
        label: "Cash-Flow Statement",
        path: "/financials/cash-flow",
        subPlan: "paid"
      }
    ]
  },
  {
    category: "Manage your",
    links: [
      {
        label: "Business Profile",
        path: "/business/profile",
        subPlan: "free"
      },
      {
        label: "Clients & Merchants",
        path: "/business/clients-&-merchants",
        subPlan: "free"
      },
      {
        label: "Products & Services",
        path: "/business/products-&-services",
        subPlan: "free"
      },
      {
        label: "Document Templates",
        path: "/business/document-templates",
        subPlan: "free"
      }
    ]
  },
  {
    category: "Accounting",
    links: [
      {
        label: "Chart of Accounts",
        path: "/business/accounts",
        subPlan: "paid"
      },
      {
        label: "Accountants",
        path: "/business/accountants",
        subPlan: "paid"
      }
    ]
  }
];

export interface INavigation {
  category: string 
  links: INavigationLink[]
}

export interface INavigationLink {
  label: string 
  path: string 
  subPlan: "free" | "paid"
}